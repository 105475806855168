import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { Auth0Provider } from "@auth0/auth0-react";

import navbar_es from "./translations/es/navbar.json";
import navbar_en from "./translations/en/navbar.json";
import information_es from "./translations/es/home/information.json";
import information_en from "./translations/en/home/information.json";
import national_es from "./translations/es/home/national.json";
import national_en from "./translations/en/home/national.json";
import international_es from "./translations/es/home/international.json";
import international_en from "./translations/en/home/international.json";
import homePage_es from "./translations/es/home/home-page.json";
import homePage_en from "./translations/en/home/home-page.json";
import goldPage_es from "./translations/es/products/nationals/gold.json";
import goldPage_en from "./translations/en/products/nationals/gold.json";
import standarPage_es from "./translations/es/products/nationals/standar.json";
import standarPage_en from "./translations/en/products/nationals/standar.json";
import courierPage_es from "./translations/es/products/nationals/courier.json";
import courierPage_en from "./translations/en/products/nationals/courier.json";
import perishableGood_es from "./translations/es/products/nationals/perishable-goods.json";
import perishableGood_en from "./translations/en/products/nationals/perishable-goods.json";
import highValue_es from "./translations/es/products/nationals/high-value.json";
import highValue_en from "./translations/en/products/nationals/high-value.json";
import highTechnology_es from "./translations/es/products/nationals/high-technology.json";
import highTechnology_en from "./translations/en/products/nationals/high-technology.json";
import liveAnimals_es from "./translations/es/products/nationals/live-animals.json";
import liveAnimals_en from "./translations/en/products/nationals/live-animals.json";
import humanRemains_es from "./translations/es/products/nationals/human-remains.json";
import humanRemains_en from "./translations/en/products/nationals/human-remains.json";
import pharmaXPS_es from "./translations/es/products/nationals/pharma-xps.json";
import pharmaXPS_en from "./translations/en/products/nationals/pharma-xps.json";
import pharmaSTD_es from "./translations/es/products/nationals/pharma-std.json";
import pharmaSTD_en from "./translations/en/products/nationals/pharma-std.json";
import pouch_es from "./translations/es/products/nationals/pouch.json";
import pouch_en from "./translations/en/products/nationals/pouch.json";
import hazardousGoods_es from "./translations/es/products/nationals/hazardous-goods.json";
import hazardousGoods_en from "./translations/en/products/nationals/hazardous-goods.json";
import industryServices_es from "./translations/es/products/nationals/industry-services.json";
import industryServices_en from "./translations/en/products/nationals/industry-services.json";
import footer_es from "./translations/es/footer.json";
import footer_en from "./translations/en/footer.json";
import express_es from "./translations/es/products/internationals/express.json";
import express_en from "./translations/en/products/internationals/express.json";
import generalPage_es from "./translations/es/products/internationals/general.json";
import generalPage_en from "./translations/en/products/internationals/general.json";
import customizedPage_es from "./translations/es/products/internationals/customized.json";
import customizedPage_en from "./translations/en/products/internationals/customized.json";
import specializedPage_es from "./translations/es/products/internationals/specialized.json";
import specializedPage_en from "./translations/en/products/internationals/specialized.json";
import specializedArtPage_es from "./translations/es/products/internationals/specialized-art.json";
import specializedArtPage_en from "./translations/en/products/internationals/specialized-art.json";
import specializedCarePage_es from "./translations/es/products/internationals/specialized-care.json";
import specializedCarePage_en from "./translations/en/products/internationals/specialized-care.json";
import specializedDRGPage_es from "./translations/es/products/internationals/specialized-drg.json";
import specializedDRGPage_en from "./translations/en/products/internationals/specialized-drg.json";
import specializedFreshPage_es from "./translations/es/products/internationals/specialized-fresh.json";
import specializedFreshPage_en from "./translations/en/products/internationals/specialized-fresh.json";
import specializedLivePage_es from "./translations/es/products/internationals/specialized-live.json";
import specializedLivePage_en from "./translations/en/products/internationals/specialized-live.json";
import specializedPharmaPage_es from "./translations/es/products/internationals/specialized-pharma.json";
import specializedPharmaPage_en from "./translations/en/products/internationals/specialized-pharma.json";
import specializedSafePage_es from "./translations/es/products/internationals/specialized-safe.json";
import specializedSafePage_en from "./translations/en/products/internationals/specialized-safe.json";
import additionalServPage_es from "./translations/es/products/additional-services/additional-services.json";
import additionalServPage_en from "./translations/en/products/additional-services/additional-services.json";
import genCargoPage_es from "./translations/es/prepare-shipment/general-cargo/general-cargo.json";
import genCargoPage_en from "./translations/en/prepare-shipment/general-cargo/general-cargo.json";
import stationsNatioanls_es from "./translations/es/stations/nationals.json";
import stationsNatioanls_en from "./translations/en/stations/nationals.json";
import stationsInternationals_es from "./translations/es/stations/internationals.json";
import stationsInternationals_en from "./translations/en/stations/internationals.json";
import trackPage_es from "./translations/es/track/track.json";
import trackPage_en from "./translations/en/track/track.json";
import restrictions_es from "./translations/es/prepare-shipment/restrictions/restrictions.json";
import restrictions_en from "./translations/en/prepare-shipment/restrictions/restrictions.json";
import contracts_en from "./translations/en/contracts/contract.json";
import cont_nat_en from "./translations/en/contract-national/cont_nat.json";
import cont_inter_en from "./translations/en/contract-international/cont_inter.json";
import privacy_en from "./translations/en/privacy/privacy.json";
import compliance_en from "./translations/en/compliance/compliance.json";
import modal_en from "./translations/en/modal/modal.json";
import freq_questions_en from "./translations/en/freq-questions/freq-questions.json";
import freq_questions_es from "./translations/es/freq-questions/freq-questions.json";

import bondedWarehouse_es from "./translations/es/stations/bonded-warehouse.json";
import bondedWarehouse_en from "./translations/en/stations/bonded-warehouse.json";
import getQuoteStepOne_en from "./translations/en/get-quote/step-one.json";
import getQuoteStepOne_es from "./translations/es/get-quote/step-one.json";
import getQuoteStepTwo_en from "./translations/en/get-quote/step-two.json";
import getQuoteStepTwo_es from "./translations/es/get-quote/step-two.json";
import aboutUs_en from "./translations/en/about-us/about-us.json";
import aboutUs_es from "./translations/es/about-us/about-us.json";
import fleet_en from "./translations/en/about-us/fleet.json";
import fleet_es from "./translations/es/about-us/fleet.json";
import contact_en from "./translations/en/contact/contact.json";
import contact_es from "./translations/es/contact/contact.json";
import skyTeam_en from "./translations/en/about-us/sky-team.json";
import skyTeam_es from "./translations/es/about-us/sky-team.json";
import contracts_es from "./translations/es/contracts/contract.json";
import alerts_en from "./translations/en/alerts/alerts.json";
import alerts_es from "./translations/es/alerts/alerts.json";
import getQuoteStepThree_en from "./translations/en/get-quote/step-three.json";
import getQuoteStepThree_es from "./translations/es/get-quote/step-three.json";
import getQuoteStepFour_en from "./translations/en/get-quote/step-four.json";
import getQuoteStepFour_es from "./translations/es/get-quote/step-four.json";
import getQuoteStepSumary_en from "./translations/en/get-quote/sumary.json";
import getQuoteStepSumary_es from "./translations/es/get-quote/sumary.json";
import getQuoteStepShipping_en from "./translations/en/get-quote/shipping-information.json";
import getQuoteStepShipping_es from "./translations/es/get-quote/shipping-information.json";
import cont_nat_es from "./translations/es/contract-national/cont_nat.json";
import cont_inter_es from "./translations/es/contract-international/cont_inter.json";
import privacy_es from "./translations/es/privacy/privacy.json";
import compliance_es from "./translations/es/compliance/compliance.json";
import modal_es from "./translations/es/modal/modal.json";
import metaData_en from "./translations/en/metadata.json";
import metaData_es from "./translations/es/metadata.json";
import error404_en from "./translations/en/error404.json";
import error404_es from "./translations/es/error404.json";
import errorNoDisponible404_en from "./translations/en/errorNoDisponible.json";
import errorNoDisponible404_es from "./translations/es/errorNoDisponible.json";
import errorCotizador_es from "./translations/es/errorCotizador.json";
import errorCotizador_en from "./translations/en/errorCotizador.json";
import landing_es from "./translations/es/landing.json"
import landing_en from "./translations/en/landing.json"
import { from } from "rxjs";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem("lng") || "es",
  resources: {
    es: {
      navbar: navbar_es,
      footer: footer_es,
      information: information_es,
      national: national_es,
      international: international_es,
      homePage: homePage_es,
      goldPage: goldPage_es,
      standarPage: standarPage_es,
      courierPage: courierPage_es,
      perishableGood: perishableGood_es,
      highValue: highValue_es,
      highTechnology: highTechnology_es,
      liveAnimals: liveAnimals_es,
      perishableGoodPage: perishableGood_es,
      highValuePage: highValue_es,
      highTechnologyPage: highTechnology_es,
      liveAnimalsPage: liveAnimals_es,
      humanRemainsPage: humanRemains_es,
      pharmaXpsPage: pharmaXPS_es,
      pharmaStdPage: pharmaSTD_es,
      pouchPage: pouch_es,
      hazardousGoodsPage: hazardousGoods_es,
      industryServicesPage: industryServices_es,
      expresPage: express_es,
      generalPage: generalPage_es,
      customizedPage: customizedPage_es,
      specializedPage: specializedPage_es,
      specializedArtPage: specializedArtPage_es,
      specializedCarePage: specializedCarePage_es,
      specializedDRGPage: specializedDRGPage_es,
      specializedFreshPage: specializedFreshPage_es,
      specializedLivePage: specializedLivePage_es,
      specializedPharmPage: specializedPharmaPage_es,
      specializedSafePage: specializedSafePage_es,
      additionalServPage: additionalServPage_es,
      genCargoPage: genCargoPage_es,
      stationsNationals: stationsNatioanls_es,
      stationsInternationals: stationsInternationals_es,
      trackPage: trackPage_es,
      restrictions: restrictions_es,
      bondedWarehouse: bondedWarehouse_es,
      getQuoteStepOne: getQuoteStepOne_es,
      getQuoteStepTwo: getQuoteStepTwo_es,
      aboutUs: aboutUs_es,
      fleet: fleet_es,
      contact: contact_es,
      contracts: contracts_es,
      skyTeam: skyTeam_es,
      alerts: alerts_es,
      getQuoteStepThree: getQuoteStepThree_es,
      getQuoteStepFour: getQuoteStepFour_es,
      getQuoteStepSumary: getQuoteStepSumary_es,
      getQuoteStepShipping: getQuoteStepShipping_es,
      cont_nat: cont_nat_es,
      cont_inter: cont_inter_es,
      privacy: privacy_es,
      compliance: compliance_es,
      modal: modal_es,
      metaData: metaData_es,
      error404: error404_es,
      errorNoDisponible404: errorNoDisponible404_es,
      errorCotizador: errorCotizador_es,
      landing: landing_es,
      freq_questions: freq_questions_es
    },
    us: {
      navbar: navbar_en,
      footer: footer_en,
      information: information_en,
      national: national_en,
      international: international_en,
      homePage: homePage_en,
      goldPage: goldPage_en,
      standarPage: standarPage_en,
      courierPage: courierPage_en,
      perishableGood: perishableGood_en,
      highValue: highValue_en,
      highTechnology: highTechnology_en,
      liveAnimals: liveAnimals_en,
      perishableGoodPage: perishableGood_en,
      highValuePage: highValue_en,
      highTechnologyPage: highTechnology_en,
      liveAnimalsPage: liveAnimals_en,
      humanRemainsPage: humanRemains_en,
      pharmaXpsPage: pharmaXPS_en,
      pharmaStdPage: pharmaSTD_en,
      pouchPage: pouch_en,
      hazardousGoodsPage: hazardousGoods_en,
      industryServicesPage: industryServices_en,
      expresPage: express_en,
      generalPage: generalPage_en,
      customizedPage: customizedPage_en,
      specializedPage: specializedPage_en,
      specializedArtPage: specializedArtPage_en,
      specializedCarePage: specializedCarePage_en,
      specializedDRGPage: specializedDRGPage_en,
      specializedFreshPage: specializedFreshPage_en,
      specializedLivePage: specializedLivePage_en,
      specializedPharmPage: specializedPharmaPage_en,
      specializedSafePage: specializedSafePage_en,
      additionalServPage: additionalServPage_en,
      genCargoPage: genCargoPage_en,
      stationsNationals: stationsNatioanls_en,
      stationsInternationals: stationsInternationals_en,
      trackPage: trackPage_en,
      restrictions: restrictions_en,
      bondedWarehouse: bondedWarehouse_en,
      getQuoteStepOne: getQuoteStepOne_en,
      getQuoteStepTwo: getQuoteStepTwo_en,
      aboutUs: aboutUs_en,
      fleet: fleet_en,
      contact: contact_en,
      contracts: contracts_en,
      skyTeam: skyTeam_en,
      alerts: alerts_en,
      getQuoteStepThree: getQuoteStepThree_en,
      getQuoteStepFour: getQuoteStepFour_en,
      getQuoteStepSumary: getQuoteStepSumary_en,
      getQuoteStepShipping: getQuoteStepShipping_en,
      cont_nat: cont_nat_en,
      cont_inter: cont_inter_en,
      privacy: privacy_en,
      compliance: compliance_en,
      modal: modal_en,
      metaData: metaData_en,
      error404: error404_en,
      errorNoDisponible404: errorNoDisponible404_en,
      errorCotizador: errorCotizador_en,
      landing: landing_en,
      freq_questions: freq_questions_en
    },
  },
});

if (process.env.REACT_APP_TAG_MANAGER_STATUS === 'production') {
  // Create and insert the Google Tag Manager script
  const gtmScript = document.createElement('script');
  gtmScript.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-NR8X29H');
  `;
  document.head.appendChild(gtmScript);

  // Create and insert the noscript iframe
  const noscriptIframe = document.createElement('noscript');
  noscriptIframe.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NR8X29H"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.appendChild(noscriptIframe);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: `${process.env.REACT_APP_LOGIN_URL}`,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
    >
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </Auth0Provider>
  </>
);
