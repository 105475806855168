import React, { useState } from "react";
import "./style.css";

import Header from "../components/header/CollectionHeader";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";

const Form = ({ register, errors }) => {
  return (
    <>
      <div className="amc-login-form-title">
        <h1>¡Bienvenido!</h1>
        <span>Ingresa tu usuario y contraseña</span>
      </div>
      <div className="amc-login-form-inputs">
        {/* Usuario */}
        <div className="formGroup">
          <input
            className="formInput"
            type="text"
            name="email"
            id="email"
            placeholder=""
            {...register("email", {
              required: "Este campo es requerido",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Formato de correo inválido",
              },
            })}
          />
          <label htmlFor="email" className="formLabel">
            Usuario
          </label>
          {errors.email && (
            <span className="alert-text">{errors.email.message}</span>
          )}
        </div>
        {/* Contraseña */}
        <div className="formGroup">
          <input
            className="formInput"
            type="password"
            autoComplete="current-password"
            name="password"
            id="password"
            placeholder=""
            {...register("password", {
              required: "Este campo es requerido",
            })}
          />
          <label htmlFor="password" className="formLabel">
            Password
          </label>
          {errors.password && (
            <span className="alert-text">{errors.password.message}</span>
          )}
        </div>
      </div>
      <div className="amc-login-buton">
        <button type="submit" className="amc-login-buton-next">
          {" "}
          Siguiente &#x276f;
        </button>
      </div>
    </>
  );
};

const Profile = ({
  permissos,
  profile,
  handleProfile,
  showError,
  navigateToProfile,
}) => {
  return (
    <>
      <div className="amc-login-profile">
        <div className="amc-login-profile-title">
          <h1>¡Bienvenido!</h1>
          <span>Selecciona el perfil que te corresponda</span>
        </div>
        <div className="amc-login-profile-type-profile">
          {/* Cliente */}
          {permissos.includes("29") && (
            <div className="profile-container">
              <input
                type="radio"
                id="cliente"
                name="profile"
                value="cliente"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="cliente"
                className="amc-login-profile-circle"
                style={
                  profile === "cliente" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3029 12.5326C13.3029 19.3227 19.224 24.8484 26.4999 24.8484C33.7758 24.8484 39.6969 19.3227 39.6969 12.5326C39.6969 5.74248 33.7758 0.216797 26.4999 0.216797C19.224 0.216797 13.3029 5.74248 13.3029 12.5326ZM49.9612 52.2168H52.8938V49.48C52.8938 38.9185 43.6824 30.3221 32.3652 30.3221H20.6346C9.31451 30.3221 0.105957 38.9185 0.105957 49.48V52.2168H49.9612Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Cliente</span>
              </div>
            </div>
          )}

          {/* Documentador */}
          {permissos.includes("30") && (
            <div className="profile-container">
              <input
                type="radio"
                id="documentador"
                name="profile"
                value="documentador"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="documentador"
                className="amc-login-profile-circle"
                style={
                  profile === "documentador"
                    ? { backgroundColor: "#035cf7" }
                    : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.83547 52.2168H43.1643C49.6614 52.2168 52.8938 49.6701 52.8938 44.6253V22.6032H30.1716C26.1544 22.6032 24.2717 21.1234 24.2717 18.0189V0.216797H9.83547C3.37055 0.216797 0.105957 2.7873 0.105957 7.83312V44.6253C0.105957 49.6939 3.37055 52.2168 9.83547 52.2168ZM30.2666 19.2804H52.5484C52.3288 18.2859 51.4182 17.3152 49.9439 16.1272L32.6193 2.52135C31.1758 1.35718 29.8582 0.653493 28.5393 0.482747V17.9713C28.5393 18.8437 29.1365 19.2804 30.2666 19.2804Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Documentador</span>
              </div>
            </div>
          )}

          {/* Buscador */}
          {permissos.includes("31") && (
            <div className="profile-container">
              <input
                type="radio"
                id="buscador"
                value="buscador"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="buscador"
                className="amc-login-profile-circle"
                style={
                  profile === "buscador" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.5861 0.216797C27.9951 0.216505 32.3152 1.43894 36.0518 3.74419C39.7885 6.04945 42.7908 9.3444 44.7152 13.2519C46.6395 17.1595 47.4082 21.5218 46.9332 25.8395C46.4582 30.1573 44.7587 34.2561 42.0293 37.6669L52.1183 47.605C52.6216 48.1183 52.9 48.8057 52.8937 49.5192C52.8874 50.2327 52.5969 50.9152 52.0847 51.4198C51.5725 51.9243 50.8796 52.2105 50.1552 52.2167C49.4309 52.2229 48.733 51.9486 48.212 51.4529L38.123 41.5148C35.182 43.7979 31.7295 45.355 28.0533 46.0564C24.3772 46.7578 20.5841 46.583 16.9904 45.5467C13.3968 44.5103 10.1069 42.6425 7.39514 40.099C4.68339 37.5555 2.62845 34.4101 1.40177 30.9252C0.175095 27.4403 -0.187753 23.717 0.3435 20.0659C0.874754 16.4148 2.2847 12.9417 4.45572 9.93651C6.62673 6.93131 9.49586 4.48109 12.8237 2.79029C16.1516 1.09948 19.8417 0.217111 23.5861 0.216797ZM23.5861 5.65934C18.8236 5.65934 14.2562 7.52292 10.8886 10.8401C7.52106 14.1573 5.62918 18.6564 5.62918 23.3476C5.62918 28.0388 7.52106 32.5379 10.8886 35.8551C14.2562 39.1723 18.8236 41.0359 23.5861 41.0359C28.3486 41.0359 32.916 39.1723 36.2836 35.8551C39.6512 32.5379 41.5431 28.0388 41.5431 23.3476C41.5431 18.6564 39.6512 14.1573 36.2836 10.8401C32.916 7.52292 28.3486 5.65934 23.5861 5.65934ZM23.5861 8.38061C27.6159 8.38061 31.4807 9.95749 34.3302 12.7643C37.1796 15.5712 38.7805 19.3781 38.7805 23.3476C38.7805 27.3171 37.1796 31.124 34.3302 33.9309C31.4807 36.7377 27.6159 38.3146 23.5861 38.3146C19.5563 38.3146 15.6916 36.7377 12.8421 33.9309C9.99261 31.124 8.39178 27.3171 8.39178 23.3476C8.39178 19.3781 9.99261 15.5712 12.8421 12.7643C15.6916 9.95749 19.5563 8.38061 23.5861 8.38061Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Buscador</span>
              </div>
            </div>
          )}

          {/* Salidas */}
          {permissos.includes("32") && (
            <div className="profile-container">
              <input
                type="radio"
                id="salidas"
                value="salidas"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="salidas"
                className="amc-login-profile-circle"
                style={
                  profile === "salidas" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.9944 26.2177C36.9944 25.7006 36.7934 25.2046 36.4356 24.8389C36.0778 24.4732 35.5925 24.2677 35.0865 24.2677H7.17188L12.1605 19.8997C12.351 19.7331 12.5074 19.5298 12.6209 19.3014C12.7345 19.073 12.8029 18.8239 12.8223 18.5685C12.8416 18.3131 12.8116 18.0562 12.7338 17.8127C12.6561 17.5691 12.5322 17.3436 12.3691 17.1489C12.2061 16.9543 12.0072 16.7944 11.7837 16.6784C11.5602 16.5623 11.3165 16.4924 11.0666 16.4726C10.8167 16.4528 10.5654 16.4835 10.3271 16.563C10.0887 16.6425 9.86808 16.7691 9.67766 16.9357L0.773886 24.7357C0.564433 24.9188 0.396269 25.1461 0.280966 25.4019C0.165662 25.6577 0.105957 25.9361 0.105957 26.2177C0.105957 26.4994 0.165662 26.7777 0.280966 27.0336C0.396269 27.2894 0.564433 27.5167 0.773886 27.6997L9.67766 35.4997C10.0622 35.8362 10.5618 36.0028 11.0666 35.9629C11.5714 35.9229 12.0399 35.6796 12.3691 35.2865C12.6984 34.8935 12.8614 34.3829 12.8223 33.867C12.7831 33.3511 12.5451 32.8722 12.1605 32.5357L7.17442 28.1677H35.0865C35.5925 28.1677 36.0778 27.9623 36.4356 27.5966C36.7934 27.2309 36.9944 26.7349 36.9944 26.2177Z"
                    fill="white"
                  />
                  <path
                    d="M19.8228 15.8168C19.8228 17.642 19.8228 18.5546 20.2527 19.2124C20.4378 19.4952 20.6755 19.7382 20.9523 19.9274C21.5959 20.3668 22.4888 20.3668 24.2746 20.3668H35.0864C36.6044 20.3668 38.0603 20.9831 39.1337 22.0802C40.2072 23.1773 40.8102 24.6653 40.8102 26.2168C40.8102 27.7683 40.2072 29.2563 39.1337 30.3534C38.0603 31.4505 36.6044 32.0668 35.0864 32.0668H24.2746C22.4888 32.0668 21.5959 32.0668 20.9523 32.5036C20.6753 32.6936 20.4376 32.9374 20.2527 33.2212C19.8228 33.879 19.8228 34.7916 19.8228 36.6168C19.8228 43.9696 19.8228 47.6486 22.0589 49.9314C24.2924 52.2168 27.8896 52.2168 35.0838 52.2168H37.6278C44.8271 52.2168 48.4217 52.2168 50.6578 49.9314C52.8939 47.6486 52.8939 43.9696 52.8939 36.6168V15.8168C52.8939 8.464 52.8939 4.785 50.6578 2.5022C48.4217 0.219397 44.8245 0.216797 37.6303 0.216797H35.0864C27.8896 0.216797 24.2924 0.216797 22.0589 2.5022C19.8228 4.785 19.8228 8.464 19.8228 15.8168Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Salidas</span>
              </div>
            </div>
          )}

          {/* Turnos */}
          {permissos.includes("33") && (
            <div className="profile-container">
              <input
                type="radio"
                id="turnos"
                value="turnos"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="turnos"
                className="amc-login-profile-circle"
                style={
                  profile === "turnos" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.4999 0.216797L0.105957 9.67134V23.8532C0.105957 36.9713 11.3674 49.2386 26.4999 52.2168C41.6324 49.2386 52.8938 36.9713 52.8938 23.8532V9.67134L26.4999 0.216797ZM26.4999 9.43498C28.24 9.43498 29.941 9.85085 31.3878 10.63C32.8346 11.4092 33.9623 12.5166 34.6282 13.8123C35.2941 15.108 35.4683 16.5338 35.1288 17.9093C34.7894 19.2848 33.9514 20.5482 32.721 21.5399C31.4906 22.5316 29.9229 23.2069 28.2163 23.4805C26.5097 23.7541 24.7407 23.6137 23.1331 23.077C21.5254 22.5403 20.1514 21.6315 19.1846 20.4654C18.2179 19.2993 17.7019 17.9283 17.7019 16.5259C17.7019 14.6453 18.6288 12.8417 20.2788 11.5119C21.9287 10.1821 24.1665 9.43498 26.4999 9.43498ZM26.4999 28.1077C32.3652 28.1077 44.0959 30.6841 44.0959 35.3877C42.1698 37.728 39.555 39.6477 36.4848 40.9757C33.4146 42.3037 29.9843 42.9987 26.4999 42.9987C23.0155 42.9987 19.5852 42.3037 16.515 40.9757C13.4448 39.6477 10.83 37.728 8.90394 35.3877C8.90394 30.6841 20.6346 28.1077 26.4999 28.1077Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Turnos</span>
              </div>
            </div>
          )}

          {/* Turnos Secundary */}
          {permissos.includes("33") && (
            <div className="profile-container">
              <input
                type="radio"
                id="turnos_secundary"
                value="turnos_secundary"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="turnos_secundary"
                className="amc-login-profile-circle"
                style={
                  profile === "turnos_secundary"
                    ? { backgroundColor: "#035cf7" }
                    : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.4999 0.216797L0.105957 9.67134V23.8532C0.105957 36.9713 11.3674 49.2386 26.4999 52.2168C41.6324 49.2386 52.8938 36.9713 52.8938 23.8532V9.67134L26.4999 0.216797ZM26.4999 9.43498C28.24 9.43498 29.941 9.85085 31.3878 10.63C32.8346 11.4092 33.9623 12.5166 34.6282 13.8123C35.2941 15.108 35.4683 16.5338 35.1288 17.9093C34.7894 19.2848 33.9514 20.5482 32.721 21.5399C31.4906 22.5316 29.9229 23.2069 28.2163 23.4805C26.5097 23.7541 24.7407 23.6137 23.1331 23.077C21.5254 22.5403 20.1514 21.6315 19.1846 20.4654C18.2179 19.2993 17.7019 17.9283 17.7019 16.5259C17.7019 14.6453 18.6288 12.8417 20.2788 11.5119C21.9287 10.1821 24.1665 9.43498 26.4999 9.43498ZM26.4999 28.1077C32.3652 28.1077 44.0959 30.6841 44.0959 35.3877C42.1698 37.728 39.555 39.6477 36.4848 40.9757C33.4146 42.3037 29.9843 42.9987 26.4999 42.9987C23.0155 42.9987 19.5852 42.3037 16.515 40.9757C13.4448 39.6477 10.83 37.728 8.90394 35.3877C8.90394 30.6841 20.6346 28.1077 26.4999 28.1077Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Turnos Secundaria</span>
              </div>
            </div>
          )}

          {/* Inspector */}
          {permissos.includes("34") && (
            <div className="profile-container">
              <input
                type="radio"
                id="inspector"
                value="inspector"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="inspector"
                className="amc-login-profile-circle"
                style={
                  profile === "inspector" ? { backgroundColor: "#035cf7" } : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6635 11.1642C10.6635 5.11574 15.388 0.216797 21.2211 0.216797C27.0542 0.216797 31.7787 5.11574 31.7787 11.1642C31.7787 17.2126 27.0542 22.1115 21.2211 22.1115C15.388 22.1115 10.6635 17.2126 10.6635 11.1642ZM26.4999 39.08C26.4999 36.4526 27.8196 33.9894 29.6672 32.3473V31.6905C29.6672 30.6231 29.8519 29.6105 30.1423 28.6252C27.4237 27.9684 24.4148 27.5852 21.2211 27.5852C9.55499 27.5852 0.105957 32.4842 0.105957 38.5326V44.0063H26.4999V39.08ZM52.8938 39.3536V48.9326C52.8938 50.5747 51.3102 52.2168 49.4626 52.2168H34.946C33.3623 52.2168 31.7787 50.5747 31.7787 48.6589V39.08C31.7787 37.4379 33.3623 35.7957 34.946 35.7957V31.6905C34.946 27.8589 38.6411 24.8484 42.3363 24.8484C46.0314 24.8484 49.7266 27.8589 49.7266 31.6905V35.7957C51.3102 35.7957 52.8938 37.4378 52.8938 39.3536ZM46.2954 31.6905C46.2954 29.501 44.4478 28.1326 42.3363 28.1326C40.2247 28.1326 38.3772 29.501 38.3772 31.6905V35.7957H46.2954V31.6905Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Inspector</span>
              </div>
            </div>
          )}

          {/* Coordinador */}
          {permissos.includes("34") && (
            <div className="profile-container">
              <input
                type="radio"
                id="coordinador"
                value="coordinador"
                name="profile"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
              <label
                htmlFor="coordinador"
                className="amc-login-profile-circle"
                style={
                  profile === "coordinador"
                    ? { backgroundColor: "#035cf7" }
                    : {}
                }
              >
                <svg
                  width="53"
                  height="53"
                  viewBox="0 0 53 53"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6635 11.1642C10.6635 5.11574 15.388 0.216797 21.2211 0.216797C27.0542 0.216797 31.7787 5.11574 31.7787 11.1642C31.7787 17.2126 27.0542 22.1115 21.2211 22.1115C15.388 22.1115 10.6635 17.2126 10.6635 11.1642ZM26.4999 39.08C26.4999 36.4526 27.8196 33.9894 29.6672 32.3473V31.6905C29.6672 30.6231 29.8519 29.6105 30.1423 28.6252C27.4237 27.9684 24.4148 27.5852 21.2211 27.5852C9.55499 27.5852 0.105957 32.4842 0.105957 38.5326V44.0063H26.4999V39.08ZM52.8938 39.3536V48.9326C52.8938 50.5747 51.3102 52.2168 49.4626 52.2168H34.946C33.3623 52.2168 31.7787 50.5747 31.7787 48.6589V39.08C31.7787 37.4379 33.3623 35.7957 34.946 35.7957V31.6905C34.946 27.8589 38.6411 24.8484 42.3363 24.8484C46.0314 24.8484 49.7266 27.8589 49.7266 31.6905V35.7957C51.3102 35.7957 52.8938 37.4378 52.8938 39.3536ZM46.2954 31.6905C46.2954 29.501 44.4478 28.1326 42.3363 28.1326C40.2247 28.1326 38.3772 29.501 38.3772 31.6905V35.7957H46.2954V31.6905Z"
                    fill="white"
                  />
                </svg>
              </label>
              <div className="amc-login-profile-label">
                <span>Coordinador</span>
              </div>
            </div>
          )}
        </div>
        {showError && (
          <span className="alert-text">Debe selecionar un perfil</span>
        )}

        <div className="amc-login-buton">
          <button
            type="button"
            className="amc-login-buton-next"
            onClick={navigateToProfile}
          >
            {" "}
            Iniciar Sesión &#x276f;
          </button>
        </div>
      </div>
    </>
  );
};

const CollectionLogin = () => {
  const [step, setStep] = useState(1);
  const [permissions, setPermissions] = useState([]);
  const [profile, setProfile] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const login = async (data) => {
    setIsLoading(true);
    const body = { email: data.email, password: data.password };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/user/login`,
        body
      );

      if (res.data.code === 1) {
        const user = res.data.data;
        localStorage.setItem("collection-user", user.name);
        localStorage.setItem("collection-email", user.email);
        localStorage.setItem("collection-token", user.token);
        localStorage.setItem("collection-permissos", user.permissions);
        localStorage.setItem("collection-kpi_id", user.kpi_id);
        if (user.counter_checkout.id !== null) {
          localStorage.setItem(
            "collection-counterId",
            user.counter_checkout.id
          );
        }
        setPermissions(user?.permissions);
        setStep(2);
        setIsLoading(false);
      } else {
        throw new Error(res);
      }
    } catch (error) {
      console.error(error);
      if (
        error.response.data.code === -1 ||
        error.response.data.message === "Correo o contraseña incorrectos"
      ) {
        Swal.fire("Error", "Correo o contraseña incorrectos", "error");
        setIsLoading(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfile = (e) => {
    setProfile(e.target.value);
  };

  const getProfileNumber = (profile) => {
    switch (profile) {
      case "cliente":
        return "1";
      case "documentador":
        return "3";
      case "buscador":
        return "6";
      case "salidas":
        return "7";
      case "turnos":
        return "4";
      case "turnos_secundary":
        return "4";
      case "inspector":
        return "5";
      case "coordinador":
        return "8";
      default:
        return "";
    }
  };

  const navigateToProfile = async () => {
    if (profile) {
      try {
        const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/user/operation`;
        const body = {
          kpi_id: localStorage.getItem("collection-kpi_id"),
          profile: getProfileNumber(profile),
        };
        const response = await axios.put(url, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        });

        const profileRoutes = {
          cliente: "/amc-domestico/collection/clients",
          documentador: "/amc-domestico/collection/documenter",
          buscador: "/amc-domestico/collection/seeker",
          salidas: "/amc-domestico/collection/departures",
          turnos: "/amc-domestico/collection/reception",
          turnos_secundary: "/amc-domestico/collection/reception-nv",
          inspector: "/amc-domestico/collection/inspector",
          coordinador: "/amc-domestico/collection/coordinator",
        };

        navigate(profileRoutes[profile], {
          state: { from: "/amc-domestico/collection" },
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <div className="amc-login">
        <Header />
        <form onSubmit={handleSubmit(login)} autoComplete="off">
          <div className="amc-login-form">
            {step === 1 ? (
              <Form register={register} errors={errors} />
            ) : (
              <Profile
                permissos={permissions}
                profile={profile}
                handleProfile={handleProfile}
                showError={showError}
                navigateToProfile={navigateToProfile}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default CollectionLogin;
