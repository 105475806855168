import { useEffect, useRef, useState } from "react";
import "./shift-light-load.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { HiOutlineX } from "react-icons/hi";

function ShiftsLightLoad() {
  const [shiftData, setShiftData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [stations, setStations] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: "",
      pieces: "",
      destination_id: "",
      awb: "",
      quantity_id: "",
      merchandise_description: "",
      id: "",
    },
  });

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 1 && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  const getStations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/stations?lng=es`,
        {
          headers: {
            authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );
      const codeStations = response.data.stations.map((item) => ({
        code: item.code,
        code_text: item.code_text,
      }));

      setStations(codeStations);
    } catch (error) {
      console.error(error);
    }
  };

  const getShifts = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/shipment?page=${page}&limit=10`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(response.data.data);

      if(response.data.data.shipments.length > 0) {
        setShiftData((prevData) => [...prevData, ...response.data.data.shipments]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al consultar, por favor intentalo de nuevo",
        icon: "error",
      });
    }
  };

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const handleOpenForm = async (id) => {
    reset({
      name: "",
      pieces: "",
      destination_id: "",
      awb: "",
      quantity_id: "",
      merchandise_description: "",
      id: "",
    });
    try {
      setShowModal(true);
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/shipment/${id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      reset({
        name: response.data.data.shipment.name,
        pieces: response.data.data.shipment.pieces,
        destination_id: response.data.data.shipment.destination,
        awb: response.data.data.shipment.awb,
        quantity_id: response.data.data.shipment.quantity_id,
        merchandise_description:
          response.data.data.shipment.merchandise_description,
        id: id,
      });
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al mostrarla información",
        icon: "error",
      });
    }
  };

  const handleUpdateShiftForm = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/shipment/${data.id}`;
      const body = {
        name: data.name,
        pieces: data.pieces,
        destination_id: data.destination_id,
        awb: data.awb,
        quantity_id: data.quantity_id,
        merchandise_description: data.merchandise_description,
      };

      const response = await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setShowModal(false);
      Swal.fire({
        title: "Exito",
        text: "Datos actualizados correctamente",
        icon: "success",
      });
      await getShifts();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al actualizar los datos, por favor verifique los campos",
        icon: "error",
      });
      setShowModal(false);
    }
  };

  const getStatus = (id) => {
    switch (id) {
      case 1:
        return "Etiqueta emitida";
        break;
      case 2:
        return "Pesaje";
        break;
      case 3:
        return "Documentado";
        break;
      case 4:
        return "Finalizado";
        break;
      case 5:
        return "Cancelado en Pesador";
        break;
      case 6:
        return "Cancelado en Mostrador"
      default:
        return "";
        break;
    }
  };

  const renderModalForm = () => (
    <>
      <form
        className="form-shift-update"
        onSubmit={handleSubmit(handleUpdateShiftForm)}
      >
        <div className="input-container-shift">
          <label>nombre:</label>
          <input
            id="name"
            type="text"
            placeholder=""
            {...register("name")}
            autoComplete="off"
          />
        </div>
        <div className="input-container-shift">
          <label>piezas:</label>
          <input
            id="pieces"
            type="text"
            placeholder=""
            {...register("pieces")}
            autoComplete="off"
          />
        </div>
        <div className="input-container-shift">
          <label>id destino:</label>
          <select id="destination_id" {...register("destination_id")}>
            {stations.map((station, index) => (
              <option key={index} value={station.code}>
                {station.code_text}
              </option>
            ))}
          </select>
        </div>
        <div className="input-container-shift">
          <label>awb:</label>
          <input
            id="awb"
            type="text"
            placeholder=""
            {...register("awb")}
            autoComplete="off"
          />
        </div>
        <div className="input-container-shift">
          <label>id cantidad:</label>
          <input
            id="quantity_id"
            type="text"
            placeholder=""
            {...register("quantity_id")}
            autoComplete="off"
          />
        </div>
        <div className="input-container-shift">
          <label>descripcion de mercancia:</label>
          <input
            id="merchandise_description"
            type="text"
            placeholder=""
            {...register("merchandise_description")}
            autoComplete="off"
          />
        </div>
        <input
          id="id"
          type="text"
          placeholder=""
          {...register("id")}
          autoComplete="off"
          style={{ display: "none" }}
        />

        <div className="btns-container">
          <button
            className="cancel-btn"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </button>
          <button className="send-btn" type="submit">
            Enviar
          </button>
        </div>
      </form>
    </>
  );

  useEffect(() => {
    const fetchDashboardAndLogDates = async () => {
      await getStations();
      await getShifts();
    };

    fetchDashboardAndLogDates(); 
  }, [page]);
  
  return (
    <>
      <div className="table-responsive table-content-shift" ref={containerRef}>
        <table className="table table-preve" id="tableToSearch">
          <thead>
            <tr>
              <th># Turno</th>
              <th># Embarque</th>
              <th>Piezas</th>
              <th>Descripción</th>
              <th>Destino</th>
              <th>usuario</th>
              <th>Fecha creacion</th>
              <th>id cantidad</th>
              <th>user_quantity</th>
              <th>date_quantity</th>
              <th># Guía</th>
              <th>usuario Guía</th>
              <th>Fecha Guía</th>
              <th>Status</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {shiftData.map((shift, index) => (
              <tr key={index}>
                <td style={{ minWidth: "100px" }}>{shift.turn_number}</td>
                <td style={{ minWidth: "130px" }}>{shift.tag_number}</td>
                <td style={{ minWidth: "100px" }}>{shift.pieces}</td>
                <td style={{ minWidth: "150px" }}>
                  {shift.merchandise_description}
                </td>
                <td style={{ minWidth: "100px" }}>{shift.destination}</td>
                <td style={{ minWidth: "150px" }}>{shift.user_create}</td>
                <td style={{ minWidth: "180px" }}>{shift.date_create}</td>
                <td style={{ minWidth: "150px", textAlign:  shift.quantity_id ? "" : "center" }}>{shift.quantity_id ? shift.quantity_id : "-----"}</td>
                <td style={{ minWidth: "150px", textAlign:  shift.user_quantity ? "" : "center" }}>{shift.user_quantity ? shift.user_quantity :  "-----"}</td>
                <td style={{ minWidth: "180px", textAlign:  shift.date_quantity ? "" : "center" }}>{shift.date_quantity ? shift.date_quantity :  "-----"}</td>
                <td style={{ minWidth: "150px", textAlign:  shift.awb ? "" : "center" }}>{shift.awb ? shift.awb :  "-----"}</td>
                <td style={{ minWidth: "150px", textAlign:  shift.user_awb ? "" : "center" }}>{shift.user_awb ? shift.user_awb :  "-----"}</td>
                <td style={{ minWidth: "200px", textAlign:  shift.date_awb ? "" : "center" }}>{shift.date_awb ? shift.date_awb :  "-----"}</td>
                <td style={{ minWidth: "150px" }}>
                  {shift.status ? getStatus(shift.status) : ""}
                </td>
                  {shift.status !== 5 && shift.status !== 6 ? (
                      <td className="btns-container-actions">
                      <button
                        className="btn-action-shift"
                        onClick={() => {
                          handleOpenForm(shift.id);
                        }}
                      >
                        Editar
                      </button>
                    </td>
                  ) : (
                    <td className="btns-container-actions">
                        <span></span>
                      </td>
                  )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div
          className="modal-window-content"
          onClick={() => setShowModal(false)}
        >
          <div className="modal-window" onClick={handleModalClick}>
            <div className="header-modal">
              <div></div>
              <div className="title">{"Actualizar"}</div>
              <div className="close-btn" onClick={() => setShowModal(false)}>
                <HiOutlineX />
              </div>
            </div>
            {showModal && renderModalForm()}
          </div>
        </div>
      )}
    </>
  )
}

export default ShiftsLightLoad;