import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Home from "../views/home/main/main-home";
import NationalsHome from "../views/products/nationals/nationals-home/nationals-home";
import NationalsMain from "../views/products/nationals/nationals-main/nationals-main";
import Gold from "../views/products/nationals/gold/gold";
import Standar from "../views/products/nationals/standar/standar";
import PerishableGoods from "../views/products/nationals/perishable-goods/perishable-goods";
import Courier from "../views/products/nationals/courier/courier";
import HighValue from "../views/products/nationals/high-value/high-value";
import HighTechnology from "../views/products/nationals/high-technology/high-technology";
import LiveAnimals from "../views/products/nationals/live-animals/live-animals";
import HumanRemains from "../views/products/nationals/human-remains/human-remains";
import PharmaXPS from "../views/products/nationals/pharma-xps/pharma-xps";
import PharmaSTD from "../views/products/nationals/pharma-std/pharma-std";
import Pouch from "../views/products/nationals/pouch/pouch";
import HazardousGoods from "../views/products/nationals/hazardous-goods/hazardous-goods";
import IndustryServices from "../views/products/nationals/industry-services/industry-services";
import InternationalMain from "../views/products/internationals/international-main/international-main";
import InternationalHome from "../views/products/internationals/international-home/international-home";
import Express from "../views/products/internationals/express/express";
import General from "../views/products/internationals/general/general";
import Customized from "../views/products/internationals/customized/customized";
import Specialized from "../views/products/internationals/specialized/specialized";
import SpecializedArt from "../views/products/internationals/specialized-art/specialized-art";
import SpecializedCare from "../views/products/internationals/specialized-care/specialized-care";
import SpecializedDRG from "../views/products/internationals/specialized-dgr/specialized-dgr";
import SpecializedFresh from "../views/products/internationals/specialized-fresh/specialized-fresh";
import SpecializedLive from "../views/products/internationals/specialized-live/specialized-live";
import SpecializedPharma from "../views/products/internationals/specialized-pharma/specialized-pharma";
import SpecializedSafe from "../views/products/internationals/specialized-safe/specialized-safe";
import AddServicesMain from "../views/products/additional-services/main/main";
import GenCargoMain from "../views/prepare-shipment/general-cargo/gen-cargo-main";
import LabelGenMain from "../views/prepare-shipment/label-generator/label-gen-main";
import RestrictionsMain from "../views/prepare-shipment/restrictions/main/restrictions-main";
import StatiosNationalsHome from "../views/stations/nationals/nationals-home/stations-nationals-home";
import StatiosInternationalsHome from "../views/stations/internationals/station-international-home";
import BondedWarehouse from "../views/stations/bonded-warehouse/bonded-warehouse";
import ScrollToTop from "../components/scrollToTop/scrollToTop";
import GetQuote from "../views/get-quote/get-quote";
import AeromexicoCargo from "../views/about-us/aeromexico-cargo/aeromexico-cargo";
import ShippingInformation from "../views/get-quote/shipping-information/shipping-information";
import Fleet from "../views/about-us/fleet/fleet";
import Erj190 from "../views/about-us/fleet/erj-190/erj-190";
import B737700 from "../views/about-us/fleet/b737-700/b737-700";
import B737800 from "../views/about-us/fleet/b737-800/b737-800";
import B7878 from "../views/about-us/fleet/b787-8/b787-8";
import B7879 from "../views/about-us/fleet/b787-9/b787-9";
import SkyTeam from "../views/about-us/sky-team/sky-team";
import Login from "../views/dashboard/login/login";
import Contact from "../views/contact/contact";
import Contracts from "../views/contracts/contracts";
import Privacy from "../views/privacy/privacy";
import Compliance from "../views/compliance/compliance";
import Alerts from "../views/alerts/alerts";
import ICS2Regulation from "../views/alerts/ics2-regulation/ics-regulation";
import PharmaServices from "../views/alerts/pharma-service/pharma-service";
import AgainstCovid from "../views/alerts/against-covid/against-covid";
import AeromexicoProud from "../views/alerts/aeromexico-proud/aeromexico-proud";
import NoShowPolicy from "../views/alerts/no-show-policy/no-show-policy";
import AeromexicoObtainsIATA from "../views/alerts/aeromexico-obtains-iata/aeromexico-obtains-iata";
import NationalContract from "../views/contract-national/cont_nat";
import InternationalContract from "../views/contract-international/contract_inter";
import Admin from "../views/dashboard/admin/admin";
import Error404 from "../components/error404/error404";
import ProtectedRoute from "../components/utils/ProtectedRoute";
import FormPage from "../views/tracking/form-page/main/form-page";
import TrackMain from "../views/tracking/track-main/track-main";
import ErrorNoDisponible404 from "../components/errorNoDisponible/errorNoDisponible";
import ErrorCotizador from "../components/errorCotizador/errorCotizador";
import TrackNotFound from "../views/tracking/track-not-found/track-not-found";
import TrackNotInfo from "../views/tracking/track-not-info/track-not-info";
import Landing from "../views/landing-page/landing";
import PrivacyAcceptance from "../views/privacy/privacy-acceptance";
import AcasProtocol from "../views/alerts/acas-protocol/acas-protocol";
import FormInfo from "../views/form-info/form-info";
import FrequentlyQuestions from "../views/frequently-questions/freq-questions";

import IndexDomestico from "../views/amc-domestico/index";
//Carga Ligera
import Weigher from "../views/amc-domestico/light-load/views/weigher/Weigher";
import LoginDomestico from "../views/amc-domestico/light-load/login/Login";
import ApprovedRegistration from "../views/amc-domestico/light-load/views/approved-registration/approved-registration";
import Reception from "../views/amc-domestico/light-load/views/reception/Reception";
import ReceptionSecondary from "../views/amc-domestico/light-load/views/reception/reception-secondary";
import ProtectedDomesticRoute from "../views/amc-domestico/light-load/components/ProtectedDomesticRoute";
import WeighingControl from "../views/amc-domestico/light-load/views/weighing-control/weighing-control";
import Turns from "../views/amc-domestico/light-load/views/turns/Turns";

//Carga Pesada
import HeavyProtectedDomesticRoute from "../views/amc-domestico/heavy-load/components/ProtectedDomesticRoute";
import LoginHeavyLoad from "../views/amc-domestico/heavy-load/Login/Login";
import SetterView from "../views/amc-domestico/heavy-load/views/setter/setter";
import WeigherHeavyLoad from "../views/amc-domestico/heavy-load/views/weigher/Weigher";
import Documenter from "../views/amc-domestico/heavy-load/views/documenter/Documenter";
import Inspector from "../views/amc-domestico/heavy-load/views/inspector/inspector";
import HeavyLoadReceptionPrimary from "../views/amc-domestico/heavy-load/views/reception/reception-primary";
import HeavyLoadReceptionSecunday from "../views/amc-domestico/heavy-load/views/reception/reception-secunday";
import ClientsMain from "../views/amc-domestico/light-load/views/clients/clients-main";
//Recolección
import CollectionLogin from "../views/amc-domestico/collections/login/Login";
import ClientsCollection from "../views/amc-domestico/collections/views/clients/Clients";
import Departures from "../views/amc-domestico/collections/views/departures/departures";
import Seeker from "../views/amc-domestico/collections/views/seeker/seeker";
import CollectionDocumenter from "../views/amc-domestico/collections/views/documenter/Documenter";
import CollectionInspector from "../views/amc-domestico/collections/views/inspector/Inspector";
import Coordinator from "../views/amc-domestico/collections/views/coordinator/Coordinator";
import ReceptionCollection from "../views/amc-domestico/collections/views/reception/Reception";
import ReceptionCollectionSecundary from "../views/amc-domestico/collections/views/reception/ReceptionSecundary";
import ProtectedDomesticRouteCollection from "../views/amc-domestico/collections/components/ProtectedDomesticRoute";
import ReprintShifts from "../views/amc-domestico/light-load/views/reprint-shifts/reprint-shifts";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error404 />,
    // errorElement: <ErrorNoDisponible404 />
  },
  {
    path: "page-not-found",
    element: <Error404 />,
    // element: <ErrorNoDisponible404 />
  },
  {
    path: "error-cotizacion",
    element: <ErrorCotizador />,
  },
  {
    path: "quote-error",
    element: <ErrorCotizador />,
  },

  {
    path: "/servicios",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "nacionales",
        element: <NationalsMain />,
        children: [
          {
            path: "",
            element: <NationalsHome />,
          },
          {
            path: "gold",
            element: <Gold />,
          },
          {
            path: "doce+doce",
            element: <Standar />,
          },
          {
            path: "mensajeria",
            element: <Courier />,
          },
          {
            path: "perecederos",
            element: <PerishableGoods />,
          },
          {
            path: "alto-valor",
            element: <HighValue />,
          },
          {
            path: "alta-tecnologia",
            element: <HighTechnology />,
          },
          {
            path: "animales-vivos",
            element: <LiveAnimals />,
          },
          {
            path: "restos-humanos",
            element: <HumanRemains />,
          },
          {
            path: "pharma-express",
            element: <PharmaXPS />,
          },
          {
            path: "pharma-estandar",
            element: <PharmaSTD />,
          },
          {
            path: "valija",
            element: <Pouch />,
          },
          {
            path: "mercancia-peligrosa",
            element: <HazardousGoods />,
          },
          {
            path: "servicios-para-la-industria",
            element: <IndustryServices />,
          },
        ],
      },
      {
        path: "internacional",
        element: <InternationalMain />,
        children: [
          {
            path: "",
            element: <InternationalHome />,
          },
          {
            path: "express",
            element: <Express />,
          },
          {
            path: "general",
            element: <General />,
          },
          {
            path: "especializado",
            element: <Specialized />,
          },
          {
            path: "obras-de-arte",
            element: <SpecializedArt />,
          },
          {
            path: "cuidado-de-la-salud",
            element: <SpecializedCare />,
          },
          {
            path: "mercancias-peligrosas",
            element: <SpecializedDRG />,
          },
          {
            path: "temperatura-controlada",
            element: <SpecializedFresh />,
          },
          {
            path: "cuidado-especializado-para-animales-vivos",
            element: <SpecializedLive />,
          },
          {
            path: "pharma-especializado",
            element: <SpecializedPharma />,
          },
          {
            path: "carga-asegurada",
            element: <SpecializedSafe />,
          },
          {
            path: "customizado",
            element: <Customized />,
          },
        ],
      },
      {
        path: "servicios-adicionales",
        element: <AddServicesMain />,
      },
    ],
  },
  {
    path: "/services",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "nationals",
        element: <NationalsMain />,
        children: [
          {
            path: "",
            element: <NationalsHome />,
          },
          {
            path: "gold",
            element: <Gold />,
          },
          {
            path: "twelve+twelve",
            element: <Standar />,
          },
          {
            path: "courier",
            element: <Courier />,
          },
          {
            path: "perishable-goods",
            element: <PerishableGoods />,
          },
          {
            path: "high-value",
            element: <HighValue />,
          },
          {
            path: "high-technology",
            element: <HighTechnology />,
          },
          {
            path: "live-animals",
            element: <LiveAnimals />,
          },
          {
            path: "human-remains",
            element: <HumanRemains />,
          },
          {
            path: "pharma-xps",
            element: <PharmaXPS />,
          },
          {
            path: "pharma-std",
            element: <PharmaSTD />,
          },
          {
            path: "pouch",
            element: <Pouch />,
          },
          {
            path: "hazardous-goods",
            element: <HazardousGoods />,
          },
          {
            path: "industry-services",
            element: <IndustryServices />,
          },
        ],
      },
      {
        path: "international",
        element: <InternationalMain />,
        children: [
          {
            path: "",
            element: <InternationalHome />,
          },
          {
            path: "express",
            element: <Express />,
          },
          {
            path: "general",
            element: <General />,
          },
          {
            path: "specialized",
            element: <Specialized />,
          },
          {
            path: "specialized-art",
            element: <SpecializedArt />,
          },
          {
            path: "specialized-care",
            element: <SpecializedCare />,
          },
          {
            path: "specialized-dgr",
            element: <SpecializedDRG />,
          },
          {
            path: "specialized-fresh",
            element: <SpecializedFresh />,
          },
          {
            path: "specialized-live",
            element: <SpecializedLive />,
          },
          {
            path: "specialized-pharma",
            element: <SpecializedPharma />,
          },
          {
            path: "specialized-safe",
            element: <SpecializedSafe />,
          },
          {
            path: "customized",
            element: <Customized />,
          },
        ],
      },
      {
        path: "additional-services",
        element: <AddServicesMain />,
      },
    ],
  },
  {
    path: "/envios",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "restricciones",
        element: <RestrictionsMain />,
      },
      {
        path: "carga-general",
        element: <GenCargoMain />,
      },
      {
        path: "generador-de-etiquetas",
        element: <LabelGenMain />,
      },
    ],
  },
  {
    path: "/shipments",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "restrictions",
        element: <RestrictionsMain />,
      },
      {
        path: "genaral-cargo",
        element: <GenCargoMain />,
      },
      {
        path: "label-generator",
        element: <LabelGenMain />,
      },
    ],
  },
  {
    path: "/estaciones",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "nacionales",
        element: <StatiosNationalsHome />,
      },
      {
        path: "internacional",
        element: <StatiosInternationalsHome />,
      },
      {
        path: "almacen-fiscalizado",
        element: <BondedWarehouse />,
      },
    ],
  },
  {
    path: "/stations",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "nationals",
        element: <StatiosNationalsHome />,
      },
      {
        path: "international",
        element: <StatiosInternationalsHome />,
      },
      {
        path: "bonded-warehouse",
        element: <BondedWarehouse />,
      },
    ],
  },
  {
    path: "/seguimiento",
    children: [
      {
        path: "",
        element: <FormPage />,
      },
      {
        path: "resultado/:code",
        element: <TrackMain />,
      },
      {
        path: "track-not-found",
        element: <TrackNotFound />,
      },
      {
        path: "track-not-info",
        element: <TrackNotInfo />,
      },
    ],
  },
  {
    path: "/track",
    children: [
      {
        path: "",
        element: <FormPage />,
      },
      {
        path: "result/:code",
        element: <TrackMain />,
      },
      {
        path: "track-not-found",
        element: <TrackNotFound />,
      },
      {
        path: "track-not-info",
        element: <TrackNotInfo />,
      },
    ],
  },
  {
    path: "cotizar",
    children: [
      {
        path: "",
        element: <GetQuote />,
      },
      {
        path: "Información-de-envio",
        element: <ShippingInformation />,
      },
    ],
  },
  {
    path: "quote",
    children: [
      {
        path: "",
        element: <GetQuote />,
      },
      {
        path: "ShippingInformation",
        element: <ShippingInformation />,
      },
    ],
  },
  {
    path: "nosotros",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "aeromexico-cargo",
        element: <AeromexicoCargo />,
      },
      {
        path: "flota",
        children: [
          {
            path: "",
            element: <Fleet />,
          },
          {
            path: "erj-190",
            element: <Erj190 />,
          },
          {
            path: "b737-700",
            element: <B737700 />,
          },
          {
            path: "b737-800",
            element: <B737800 />,
          },
          {
            path: "b787-8",
            element: <B7878 />,
          },
          {
            path: "b787-9",
            element: <B7879 />,
          },
        ],
      },
      {
        path: "skyteam",
        element: <SkyTeam />,
      },
    ],
  },
  {
    path: "/about-us",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "aeromexico-cargo",
        element: <AeromexicoCargo />,
      },
      {
        path: "fleet",
        children: [
          {
            path: "",
            element: <Fleet />,
          },
          {
            path: "erj-190",
            element: <Erj190 />,
          },
          {
            path: "b737-700",
            element: <B737700 />,
          },
          {
            path: "b737-800",
            element: <B737800 />,
          },
          {
            path: "b787-8",
            element: <B7878 />,
          },
          {
            path: "b787-9",
            element: <B7879 />,
          },
        ],
      },
      {
        path: "skyteam",
        element: <SkyTeam />,
      },
    ],
  },
  {
    path: "/dashboard",
    children: [
      {
        path: "",
        element: <Navigate to="/" replace />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
    ],
  },
  {
    path: "/contacto",
    element: <Contact />,
  },
  {
    path: "/gracias",
    element: <ProtectedRoute />,
  },
  {
    path: "/thankyou",
    element: <ProtectedRoute />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/contratos",
    element: <Contracts />,
  },
  {
    path: "/contracts",
    element: <Contracts />,
  },
  {
    path: "/aviso",
    element: <Privacy />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/aviso-aceptacion",
    element: <PrivacyAcceptance />,
  },
  {
    path: "/privacy-acceptance",
    element: <PrivacyAcceptance />,
  },
  {
    path: "/cumplimiento-normativo",
    element: <Compliance />,
  },
  {
    path: "/compliance",
    element: <Compliance />,
  },
  {
    path: "/contrato-de-carga-aerea-nacional",
    element: <NationalContract />,
  },
  {
    path: "/nationalContract",
    element: <NationalContract />,
  },
  {
    path: "/contrato-de-carga-aerea-internacional",
    element: <InternationalContract />,
  },
  {
    path: "/internationalContract",
    element: <InternationalContract />,
  },
  {
    path: "alertas",
    children: [
      {
        path: "",
        element: <Alerts />,
      },
      {
        path: "protocolo-acas",
        element: <AcasProtocol />,
      },
      {
        path: "ics2-regulacion",
        element: <ICS2Regulation />,
      },
      {
        path: "venta-azul",
        element: <PharmaServices />,
      },
      {
        path: "covid-19",
        element: <AgainstCovid />,
      },
      {
        path: "aeromexico-proud",
        element: <AeromexicoProud />,
      },
      {
        path: "politica-no-show",
        element: <NoShowPolicy />,
      },
      {
        path: "aeromexico-obtiene-certificacion-de-iata-para-transporte-de-farmacos",
        element: <AeromexicoObtainsIATA />,
      },
    ],
  },
  {
    path: "alerts",
    children: [
      {
        path: "",
        element: <Alerts />,
      },
      {
        path: "acas-protocol",
        element: <AcasProtocol />,
      },
      {
        path: "ics2-regulation",
        element: <ICS2Regulation />,
      },
      {
        path: "pharma-services",
        element: <PharmaServices />,
      },
      {
        path: "againts-covid",
        element: <AgainstCovid />,
      },
      {
        path: "aeromexico-proud",
        element: <AeromexicoProud />,
      },
      {
        path: "no-show-policy",
        element: <NoShowPolicy />,
      },
      {
        path: "aeromexico-obtains-iata",
        element: <AeromexicoObtainsIATA />,
      },
    ],
  },
  {
    path: "/anuncios",
    element: <Landing />,
  },
  {
    path: "/dyn-pwc-ac-amlab7",
    element: <InternationalContract />,
  },
  {
    path: "/form-info",
    element: <FormInfo />,
  },
  {
    path: "/amc-domestico",
    element: <IndexDomestico />,
  },
  {
    path: "/faqs",
    element: <FrequentlyQuestions />,
  },
  //carga ligera
  {
    path: "/amc-domestico/light-load",
    children: [
      {
        path: "",
        element: <LoginDomestico />,
      },
      {
        path: "clients",
        element: (
          <ProtectedDomesticRoute>
            <ClientsMain />
          </ProtectedDomesticRoute>
        ),
      },
      {
        path: "weigher",
        element: (
          <ProtectedDomesticRoute>
            <Weigher />
          </ProtectedDomesticRoute>
        ),
      },
      {
        path: "approved-registration",
        element: (
          <ProtectedDomesticRoute>
            <ApprovedRegistration />
          </ProtectedDomesticRoute>
        ),
      },
      {
        path: "reception",
        element: (
          <ProtectedDomesticRoute>
            <Reception />
          </ProtectedDomesticRoute>
        ),
      },
      {
        path: "reception-nv",
        element: (
          <ProtectedDomesticRoute>
            <ReceptionSecondary />
          </ProtectedDomesticRoute>
        ),
      },
      {
        path: "weighing-control",
        element: (
          <ProtectedDomesticRoute>
            <WeighingControl />
          </ProtectedDomesticRoute>
        ),
      },
      {
        path: "reprint_shifts",
        element: (
          <ProtectedDomesticRoute>
            <ReprintShifts />
          </ProtectedDomesticRoute>
        )
      },
      {
        path: "turns",
        element: <Turns />,
      },
    ],
  },
  //carga pesada
  //carga pesada
  //carga pesada
  //carga pesada
  {
    path: "/amc-domestico/heavy-load",
    children: [
      {
        path: "",
        element: <LoginHeavyLoad />,
      },
      {
        path: "setter",
        element: (
          <HeavyProtectedDomesticRoute>
            <SetterView />
          </HeavyProtectedDomesticRoute>
        ),
      },
      {
        path: "weigher",
        element: (
          <HeavyProtectedDomesticRoute>
            <WeigherHeavyLoad />
          </HeavyProtectedDomesticRoute>
        ),
      },
      {
        path: "documenter",
        element: (
          <HeavyProtectedDomesticRoute>
            <Documenter />
          </HeavyProtectedDomesticRoute>
        ),
      },
      {
        path: "inspector",
        element: (
          <HeavyProtectedDomesticRoute>
            <Inspector />
          </HeavyProtectedDomesticRoute>
        ),
      },
      {
        path: "reception",
        element: (
          <HeavyProtectedDomesticRoute>
            <HeavyLoadReceptionPrimary />
          </HeavyProtectedDomesticRoute>
        ),
      },
      {
        path: "reception-nv",
        element: (
          <HeavyProtectedDomesticRoute>
            <HeavyLoadReceptionSecunday />
          </HeavyProtectedDomesticRoute>
        ),
      },
    ],
  },
  //Recoleccion
  //Recoleccion
  //Recoleccion
  //Recoleccion
  {
    path: "/amc-domestico/collection",
    children: [
      {
        path: "",
        element: <CollectionLogin />,
      },
      {
        path: "clients",
        element: (
          <ProtectedDomesticRouteCollection>
            <ClientsCollection />
          </ProtectedDomesticRouteCollection>
        ),
      },
      {
        path: "seeker",
        element: (
          <ProtectedDomesticRouteCollection>
            <Seeker />
          </ProtectedDomesticRouteCollection>
        ),
      },
      {
        path: "departures",
        element: (
          <ProtectedDomesticRouteCollection>
            <Departures />
          </ProtectedDomesticRouteCollection>
        ),
      },
      {
        path: "documenter",
        element: (
          <ProtectedDomesticRouteCollection>
            <CollectionDocumenter />
          </ProtectedDomesticRouteCollection>
        ),
      },
      {
        path: "inspector",
        element: (
          <ProtectedDomesticRouteCollection>
            <CollectionInspector />
          </ProtectedDomesticRouteCollection>
        ),
      },
      {
        path: "coordinator",
        element: (
          <ProtectedDomesticRouteCollection>
            <Coordinator />
          </ProtectedDomesticRouteCollection>
        ),
      },
      {
        path: "reception",
        element: (
          <ProtectedDomesticRouteCollection>
            <ReceptionCollection />
          </ProtectedDomesticRouteCollection>
        ),
      },
      {
        path: "reception-nv",
        element: (
          <ProtectedDomesticRouteCollection>
            <ReceptionCollectionSecundary />
          </ProtectedDomesticRouteCollection>
        ),
      },
    ],
  },
]);

const MyRoutes = () => (
  <RouterProvider router={router}>
    <ScrollToTop />
  </RouterProvider>
);

export default MyRoutes;
