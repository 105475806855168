/* eslint-disable no-unused-vars */
import React, { useEffect, useState} from 'react'
import axios from 'axios';
import Swal from "sweetalert2";
import { FaEdit, FaTimes } from "react-icons/fa";
import './usuarios_style.css'

function Usuarios() {
  const [users, setUsers] = useState([])
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState("");
  
  const getUsers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
        },
      });
      if (res.data.code === 1){
        setUsers(res.data.users)
        setLoading(false)
      }
      
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
      setLoading(true)
    }
  };
  
  const deleteUser = async ( email ) => {
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_URL_BACKEND}/api/user/${email}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );

        Swal.fire("¡Eliminado!", "El usuario ha sido eliminado.", "success");
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "Tu usuario está a salvo :)",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  }
  
  const updateUser = async (email) => {
    try {
      // Obtener los datos del usuario a editar del servidor
      const response = await axios.get(
        `${process.env.REACT_APP_URL_BACKEND}/api/user/${email}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
          },
        }
      );
      const userData = response.data.user;

      const result = await Swal.fire({
        title: "Editar Usuario",
        html:
          '<div class="form-grid">' +
          `<input type="text" id="email" class="swal2-input" placeholder="Ejemplo@email.com" value="${userData.email}" disabled>` +
          `<input type="text" id="name" class="swal2-input" placeholder="Pepito Perez" value="${userData.name}">` +
          `<input type="password" id="password" class="swal2-input" placeholder value="">` +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve({
              email: document.getElementById("email").value.replace(/\s/g, ''),
              name: document.getElementById("name").value,
              password: document.getElementById("password").value,
            });
          });
        },
      });

      if (result.isConfirmed) {
        const updatedUserData = result.value;
        console.log(updatedUserData)
        // Realizar la solicitud PUT al servidor para actualizar el usuario
        const updateResponse = await axios.put(
          `${process.env.REACT_APP_URL_BACKEND}/api/user/update`,
          updatedUserData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Agrega el token de sesión en los encabezados con el formato "Bearer {token}"
            },
          }
        );
        Swal.fire(
          "¡Actualizado!",
          "El usuario ha sido actualizado exitosamente.",
          "success"
        );
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "No se ha realizado ninguna actualización.",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  };
  
  const createUser = async () => {
    try {
      const result = await Swal.fire({
        title: "Crear Usuario",
        html:
          '<div class="form-grid">' +
          '<input type="email" id="email" class="swal2-input" placeholder="Ejemplo@email.com">' +
          '<input type="text" id="name" class="swal2-input" placeholder="Pepito Perez">' +
          '<input type="password" id="password" class="swal2-input" placeholder="********">' +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar",
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve({
              email: document.getElementById("email").value.replace(/\s/g, ''),
              name: document.getElementById("name").value,
              password: document.getElementById("password").value
            });
          });
        },
      });
  
      if (result.isConfirmed) {
        const userData = result.value;
  
        const response = await axios.post(
          `${process.env.REACT_APP_URL_BACKEND}/api/user/create`,
          userData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        Swal.fire(
          "¡Creado!",
          "El usuario ha sido creado exitosamente.",
          "success"
        );
        setUpdateTrigger(!updateTrigger);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
    } catch (error) {
      if (error.response.data.message === "El usuario ya existe") {
        Swal.fire("Error al crear el usuario", "El usuario ya existe.", "error");
      } else if (error.response.data.message === "El usuario fue eliminado anteriormente") {
        Swal.fire("Error al crear el usuario", "El usuario fue eliminado anteriormente", "error");
      } else if (error.response.data.errors[0] === "La contraseña debe ser mayor a 6 caracteres") {
        Swal.fire("Error al crear el usuario", "La contraseña debe ser mayor a 6 caracteres", "error");
      } else if (error.response.data.errors[0] === "El formato del correo no es valido") {
        Swal.fire("Error al crear el usuario", "El formato del correo no es valido", "error");
      } else {
        Swal.fire(
          "Error al crear el usuario",
          "No se ha creado ningún usuario."
        );
      }
    }
  };
  
  const handleSearch = () => {
    const input = document.getElementById("searchInput");
    setSearchValue(input.value.trim());
  };
  
  const filteredUsers = searchValue
  ? users.filter(
      (user) =>
        user.name
          .toUpperCase()
          .includes(searchValue.toUpperCase()) ||
        user.email.toUpperCase().includes(searchValue.toUpperCase())
    )
  : users

  useEffect(() => {
    getUsers();
  }, [updateTrigger]);

  return (
    <>
    <div className="panel-table">
    <div className='colum-left'>
      <input type="text" className='searchInput' id="searchInput" placeholder="Buscar..." />
      <button className='btnn' onClick={handleSearch}>Buscar</button>
    </div>
    <div className='colum-rigth'>
      <button className="btnn" onClick={createUser}>
        Crear Usuario
      </button>
    </div>
    </div>
    <div className='table-responsive'>
      <table className='table table-preve' id='tableToSearch'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <td className='accions'>Acciones</td>
          </tr>
        </thead>
        
        <tbody>
        {loading ? (<tr><td>Cargando...</td></tr>) : 
        ( filteredUsers.map((user) => (
          <tr key={user.email} data-master-id={user.email}>
            <td>{user.name}</td>
            <td>{user.email}</td>
            <td>
            <FaEdit className='editButton' onClick={() =>{ updateUser(user.email)}}/>
            <FaTimes className='deleteButton' onClick={() =>{ deleteUser(user.email)}}/>
            </td>
          </tr>
        )))}
        </tbody>
      </table>
    </div>
    </>
  )
}

export default Usuarios