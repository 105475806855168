/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Modal, Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import Header from "../../components/header/Header";
import "./style.css";

const Turns = () => {
  const [records, setRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const recordsPerPage = 11;
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const containerRef = useRef(null);

  const getData = async (page) => {
    if (loading || page > totalPages) return;

    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/turns?page=${page}&limit=${recordsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      const { current_page, total_pages, shipments } = res.data.data;

      setRecords((prevRecords) => [...prevRecords, ...shipments]);
      setCurrentPage(current_page);
      setTotalPages(total_pages);
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setLoading(false);
    }
  };

  // Manejar selección individual
  const toggleSelect = (id) => {
    setSelectedRecords((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  // Manejar selección de todos
  const toggleSelectAll = () => {
    if (selectedRecords.length === records.length) {
      setSelectedRecords([]);
    } else {
      setSelectedRecords(records.map((record) => record.shipment_id));
    }
  };

  const finishTurn = async (id) => {
    if (!id) return;

    const result = await Swal.fire({
      title: "¿Quieres finalizar este turno?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/turns/${id}`,
        { user_id: `${localStorage.getItem("light-load-email")}` },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        setLoading(true);
        setRecords([]);
        getData(1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const finishSelectedTurns = async () => {
    if (selectedRecords.length === 0) {
      Swal.fire("Atención", "No hay turnos seleccionados", "warning");
      return;
    }

    const result = await Swal.fire({
      title: `¿Quieres finalizar ${selectedRecords.length} turnos?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;

    setLoadingModal(true);

    try {
      for (const id of selectedRecords) {
        const res = await axios.put(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/turns/${id}`,
          { user_id: `${localStorage.getItem("light-load-email")}` },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "light-load-token"
              )}`,
            },
          }
        );
      }
      setLoading(true);
      setRecords([]);
      getData(1);
      setLoadingModal(false);
      Swal.fire({
        title: "Completado",
        text: `${selectedRecords.length} turnos finalizados correctamente.`,
        icon: "success",
      });

      setSelectedRecords([]);
    } catch (error) {
      console.error("Error finalizando turnos:", error);
      Swal.fire("Error", "Hubo un problema finalizando los turnos", "error");
    } finally {
      setLoadingModal(false);
    }
  };

  useEffect(() => {
    getData(1);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 10 && !loading) {
        getData(currentPage + 1);
      }
    };

    const container = containerRef.current;
    container?.addEventListener("scroll", handleScroll);

    return () => container?.removeEventListener("scroll", handleScroll);
  }, [currentPage, loading]);

  return (
    <div className="container-turns">
      <Header />
      <div
        className="turns-body"
        ref={containerRef}
        style={{ overflowY: "auto", maxHeight: "80vh" }}
      >
        <div className="container-turns-table">
          <div className="finsh-container">
            <button
              disabled={selectedRecords.length === 0}
              onClick={finishSelectedTurns}
            >
              Borrar seleccionados
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    onChange={toggleSelectAll}
                    checked={
                      selectedRecords.length === records.length &&
                      records.length > 0
                    }
                  />
                </th>
                <th># Turno</th>
                <th>Nombre Consignatario</th>
                <th># Embarque</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr key={`${record.turn_id}-${index}`}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRecords.includes(record.shipment_id)}
                      onChange={() => toggleSelect(record.shipment_id)}
                    />
                  </td>
                  <td>{record.turn_number}</td>
                  <td>{record.consigne_name}</td>
                  <td>{record.tag_number}</td>
                  <td className="butons-container">
                    <button
                      className="finish"
                      onClick={() => finishTurn(record.shipment_id)}
                    >
                      Finalizar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="loading-spinner">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={loadingModal}
        onHide={() => {}}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status" />
          <p className="mt-3">Procesando turnos...</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Turns;
