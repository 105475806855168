import React, { useState, useEffect } from "react";
import { FaArrowLeft, FaEdit, FaList, FaTimes } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import './faq_style.css';
import { useQuill } from "react-quilljs";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import DOMPurify from "dompurify";

function Faq () {

  const [vista, setVista] = useState("niveles"); //
  const [nivelSeleccionado, setNivelSeleccionado] = useState(null);
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(null);
  const [niveles1, setNiveles1] = useState([]);
  const [niveles1Ingles, setNiveles1Ingles] = useState([]);
  const [categoriasIngles, setCategoriasIngles] = useState([]);
  const [faqIngles, setFaqIngles] = useState([]);

  /* Eventos para navegación entre vistas  */
  const handleVerCategorias = async (nivel) => {
    try {
      const response = await axios.get("http://localhost:3001/back-office/api/qfa/category/lang/es", {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });

      const responseEn = await axios.get("http://localhost:3001/back-office/api/qfa/category/lang/en", {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });

      // Filtrar solo las categorías que pertenecen al nivel seleccionado
      const categoriasDelNivel = response.data.data.filter(
        (categoria) => categoria.section_id === nivel.id
      );

      // Guardar el nivel y sus categorías en el estado
      setNivelSeleccionado({ ...nivel, categorias: categoriasDelNivel });
      setCategoriasIngles(responseEn.data.data)

      setVista("categorias");  // Cambiar vista a categorias
    } catch (error) {
      console.error("Error obteniendo categorías:", error);
      Swal.fire("Error", "No se pudieron cargar las categorías", "error");
    }
  };


  const handleVerPreguntas = async (categoria) => {
    try {
      const response = await axios.get("http://localhost:3001/back-office/api/qfa/question/lang/es", {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });

      const responseEn = await axios.get("http://localhost:3001/back-office/api/qfa/question/lang/en", {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });

      // Filtrar solo las categorías que pertenecen al nivel seleccionado
      const preguntasDeCategoria = response.data.data.filter(
        (pregunta) => pregunta.category_id === categoria.id
      );

      // Guardar la categoria y sus preguntas en el estado
      setCategoriaSeleccionada({ ...categoria, preguntas: preguntasDeCategoria });
      setFaqIngles(responseEn.data.data)

      setVista("preguntas");  // Cambiar la vista a preguntas
    } catch (error) {
      console.error("Error obteniendo categorías:", error);
      Swal.fire("Error", "No se pudieron cargar las categorías", "error");
    }
  };

  const handleVolver = () => {
    if (vista === "preguntas") {
      setVista("categorias");
    } else if (vista === "categorias") {
      setVista("niveles");
    }
  };

  /* Buscador */
  const handleSearch = () => {
      console.log("Boton de buscar");
  }

  /* Buscar nivel, categoria y pregunta correspondiente en ingles mediante la fecha de creacion */
  const getEnglishLevel = (nivelEs) => {
    return niveles1Ingles.find(nivelEn => nivelEn.created_at === nivelEs.created_at) || {};
  };

  const getEnglishCategory = (categorieEs) => {
    return categoriasIngles.find(nivelEn => nivelEn.created_at === categorieEs.created_at) || {};
  };

  const getEnglishFaq = (questionEs) => {
    return faqIngles.find(nivelEn => nivelEn.created_at === questionEs.created_at) || {};
  }

  /* Obtener datos del API */
  const getLevels1 = async () => {
    try {
      const token = process.env.REACT_APP_PUBLIC_KEY;

      const res = await axios.get(
        "http://localhost:3001/back-office/api/qfa/issues/lang/es",
        {
          headers: { 
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      const resEn = await axios.get(
        "http://localhost:3001/back-office/api/qfa/issues/lang/en",
        {
          headers: {
            Authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      if (res.data.code === 1){
        setNiveles1(res.data.data); // Guardar niveles 1
      }

      if (resEn.data.code === 1){
        setNiveles1Ingles(resEn.data.data); // Guardar niveles 1 en ingles
      }

    } catch (error) {
      console.error("Error al obtener los niveles 1:", error);
    }
  };

  useEffect(() => {
    getLevels1();
  }, []);

  /* Eventos para crear "nivel 1", "categoria" y "faq" */
  const createLevel1 = async () => {
    try {
      const result = await Swal.fire({
        title: "Agregar Nivel 1",
        html:
          '<div class="form-grid">' +
          '<font size="3">Nombre en español</font><br />' +
          '<input type="text" id="level1" class="swal2-input" style="margin-top: 0px;" placeholder="Nivel 1"><br /><br />' +
          '<font size="3">Nombre en inglés</font><br />' +
          '<input type="text" id="level1_en" class="swal2-input" style="margin-top: 0px;" placeholder="Level 1">' +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar",
        preConfirm: () => {
          const nameEs = document.getElementById("level1").value.trim();
          const nameEn = document.getElementById("level1_en").value.trim();

          if (!nameEs || !nameEn) {
            Swal.showValidationMessage("Ambos campos son obligatorios");
            return false;
          }

          return { nameEs, nameEn };
        },
      });

      if (result.isConfirmed) {
        const response = await Promise.all([
          axios.post(
            "http://localhost:3001/back-office/api/qfa/issues/create/es",
            { name: result.value.nameEs },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
          axios.post(
            "http://localhost:3001/back-office/api/qfa/issues/create/en",
            { name: result.value.nameEn },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
        ]);

        Swal.fire(
          "¡Creado!",
          "El nivel 1 ha sido creado exitosamente.",
          "success"
        );
        
        getLevels1();  // Recargar la lista de niveles
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
    } catch (error) {
      console.error("Error al crear el nivel 1:", error);
      Swal.fire(
        "Error al crear el nivel 1",
        "No se ha creado ningún nivel 1."
      );
    }
  };

  const createCategorie = async () => {
    try {
      const result = await Swal.fire({
        title: "Agregar Categoria",
        html:
          '<div class="form-grid">' +
          '<font size="3">Categoria en español</font><br />' +
          '<input type="text" id="categorie" class="swal2-input" style="margin-top: 0px;" placeholder="Categoria"><br /><br />' +
          '<font size="3">Categoria en inglés</font><br />' +
          '<input type="text" id="categorie_en" class="swal2-input" style="margin-top: 0px;" placeholder="Categorie">' +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar",
        preConfirm: () => {
          const categorieEs = document.getElementById("categorie").value.trim();
          const categorieEn = document.getElementById("categorie_en").value.trim();

          if (!categorieEs || !categorieEn) {
            Swal.showValidationMessage("Ambos campos son obligatorios");
            return false;
          }

          return { categorieEs, categorieEn };
        },
      });

      // Buscar el nivel en inglés basado en el nivel en español
      const nivelEn = getEnglishLevel(nivelSeleccionado);

      if (result.isConfirmed) {
        const response = await Promise.all([
          axios.post(
            `http://localhost:3001/back-office/api/qfa/category/create/es/${nivelSeleccionado.id}`,
            { name: result.value.categorieEs },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
          axios.post(
            `http://localhost:3001/back-office/api/qfa/category/create/en/${nivelEn.id}`,
            { name: result.value.categorieEn },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
        ]);

        Swal.fire(
          "¡Creado!",
          "La categoria ha sido creada exitosamente.",
          "success"
        );

        handleVerCategorias(nivelSeleccionado);  // Actualizar la vista con las nuevas categorías
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
    } catch (error) {
      console.error("Error al crear la categoría:", error);
      Swal.fire(
        "Error al crear categoria",
        "No se ha creado ninguna categoria"
      );
    }
  };

  const createFaq = async () => {
    let quillInstance; // Variable para almacenar la instancia de Quill
    let quillInstanceEn; // Variable para almacenar la instancia de Quill en ingles
    let selectedFile = null; // Variable para almacenar el archivo seleccionado
    let selectedFileEn = null; // Variable para almacenar el archivo en inglés

    try {
      const result = await Swal.fire({
        title: "Agregar Pregunta",
        html:
          '<div class="form-grid">' +
          '<font size="3">Pregunta en español</font><br />' +
          '<input type="text" id="question" class="swal2-input" style="margin-bottom: 20px; margin-top: 0px;" placeholder="Pregunta"><br />' +
          '<font size="3">Pregunta en ingles</font><br />' +
          '<input type="text" id="question_en" class="swal2-input" style="margin-bottom: 20px; margin-top: 0px;" placeholder="Question"><br />' +
          '<font size="3">Respuesta en español</font><br />' +
          '<div id="editorContainer" style="height: 150px; border: 1px solid black; padding: 5px; border-radius: 5px;"></div>' + // Contenedor del editor
          '<font size="3">Respuesta en ingles</font><br />' +
          '<div id="editorContainerEn" style="height: 150px; border: 1px solid black; padding: 5px; border-radius: 5px;"></div>' +  // Contenedor del editor en ingles
          '<font size="3">Archivo en español</font><br />' +
          '<input type="file" id="fileUpload" class="swal2-in"><br /><br />' + // Campo de carga de archivos
          '<font size="3">Archivo en ingles</font><br />' +
          '<input type="file" id="fileUploadEn" class="swal2-in">' + // Campo de carga de archivos en ingles
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Crear",
        cancelButtonText: "Cancelar",
        didOpen: () => {
          // Módulos personalizados para Quill
          const modules = {
            toolbar: [
              [{ header: [1, 2, false] }], // Encabezados h1, h2 y texto normal
              ["bold", "italic", "underline"], // Negrita, cursiva y subrayado
              [{ list: "ordered" }, { list: "bullet" }], // Listas ordenadas y con viñetas
              ["blockquote", "code-block"], // Bloques de cita y código
              [{ align: [] }], // Alineaciones
              ["clean"], // Botón para limpiar el formato
            ],
          };

          // Inicializar Quill en es y en dentro del modal
          quillInstance = new Quill("#editorContainer", {
            theme: "snow",
            placeholder: "Escribe tu respuesta aquí...",
            modules,
          });

          quillInstanceEn = new Quill("#editorContainerEn", {
            theme: "snow",
            placeholder: "Write your answer here...",
            modules,
          });

          // Capturar archivo en español
          document.getElementById("fileUpload").addEventListener("change", (event) => {
            selectedFile = event.target.files[0]; // Guardar el archivo
          });

          // Capturar archivo en inglés
          document.getElementById("fileUploadEn").addEventListener("change", (event) => {
            selectedFileEn = event.target.files[0];
          });
        },
        preConfirm: () => {
          const questionEs = document.getElementById("question").value.trim();
          const questionEn = document.getElementById("question_en").value.trim();
          const answerEs = quillInstance.root.innerHTML.trim();
          const answerEn = quillInstanceEn.root.innerHTML.trim();

          if (!questionEs || !questionEn || !answerEs || !answerEn) {
              Swal.showValidationMessage("La pregunta y respuesta son obligatorias en ambos idiomas");
              return false;
          }

          return { questionEs, questionEn, answerEs, answerEn };
        },
      });

      // Obtener la categoría en inglés basada en la categoría en español seleccionada
      const categoriaEn = getEnglishCategory(categoriaSeleccionada);

      if (result.isConfirmed) {
        const formDataEs = new FormData();
        formDataEs.append("name", result.value.questionEs);
        formDataEs.append("question", result.value.questionEs);
        formDataEs.append("answers", result.value.answerEs);
        if (selectedFile) {
          formDataEs.append("file", selectedFile);
        } else {
          formDataEs.append("file", null);
        }

        const formDataEn = new FormData();
        formDataEn.append("name", result.value.questionEn);
        formDataEn.append("question", result.value.questionEn);
        formDataEn.append("answers", result.value.answerEn);
        if (selectedFileEn) {
          formDataEn.append("file", selectedFileEn);
        } else {
          formDataEn.append("file", null);
        }

        const response = await Promise.all([
          axios.post(
            `http://localhost:3001/back-office/api/qfa/question/create/es/${categoriaSeleccionada.id}`,
            formDataEs,
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}` 
              } 
            }
          ),
          axios.post(
            `http://localhost:3001/back-office/api/qfa/question/create/en/${categoriaEn.id}`,
            formDataEn,
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}` 
              } 
            }
          ),
        ]);

        Swal.fire("¡Creado!", "La pregunta ha sido creada.", "success");
        handleVerPreguntas(categoriaSeleccionada); // Recargar lista de preguntas
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Acción cancelada.", "error");
      }
    } catch (error) {
      console.error("Error al crear la pregunta:", error);
      Swal.fire("Error", "No se pudo crear la pregunta.", "error");
    }
  };

  /* Eventos para actualizar "nivel 1", "categoria" y "faq" */
  const updateLevel1 = async (nivelOne) => {
    // Buscar el nivel en inglés correspondiente
    const nivelEn = getEnglishLevel(nivelOne);

    try {
      const result = await Swal.fire({
        title: "Editar Nivel 1",
        html:
          '<font size="3">Nombre en español</font><br />' +
          `<input type="text" id="level1" class="swal2-input" style="margin-top: 0px;" value="${nivelOne.name}" placeholder="Nivel 1"><br /><br />` +
          '<font size="3">Nombre en inglés</font><br />' +
          `<input type="text" id="level1_en" class="swal2-input" style="margin-top: 0px;" value="${nivelEn.name}" placeholder="Level 1">` +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        preConfirm: () => {
          const nameEs = document.getElementById("level1").value.trim();
          const nameEn = document.getElementById("level1_en").value.trim();

          if (!nameEs || !nameEn) {
            Swal.showValidationMessage("Ambos campos son obligatorios");
            return false;
          }

          return { nameEs, nameEn };
        }
      });

      if (result.isConfirmed) {
        const response = await Promise.all([
          axios.put(
            `http://localhost:3001/back-office/api/qfa/issues/update/${nivelOne.id}`,
            { name: result.value.nameEs },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
          axios.put(
            `http://localhost:3001/back-office/api/qfa/issues/update/${nivelEn.id}`,
            { name: result.value.nameEn },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
        ]);

        Swal.fire(
          "¡Actualizado!",
          "El nivel 1 ha sido actualizado.",
          "success"
        );
        
        getLevels1();  // Recargar la lista de niveles
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
    } catch (error) {
      Swal.fire(
        "Error al actualizar el nivel 1",
        "No se ha actualizado ningún nivel 1."
      );
    }
  };

  const updateCategorie = async (categorie) => {
    const categorieEn = getEnglishCategory(categorie);
    try {
      const result = await Swal.fire({
        title: "Editar Categoria",
        html:
          '<div class="form-grid">' +
          '<font size="3">Categoria en español</font><br />' +
          `<input type="text" id="categorie" class="swal2-input" style="margin-top: 0px;" value="${categorie.name}" placeholder="Categoria"><br /><br />` +
          '<font size="3">Categoria en inglés</font><br />' +
          `<input type="text" id="categorie_en" class="swal2-input" style="margin-top: 0px;" value="${categorieEn.name}" placeholder="Categorie">` +
          "</div>",
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        cancelButtonText: "Cancelar",
        preConfirm: () => {
          const categorieEs = document.getElementById("categorie").value.trim();
          const categorieEn = document.getElementById("categorie_en").value.trim();

          if (!categorieEs || !categorieEn) {
            Swal.showValidationMessage("Ambos campos son obligatorios");
            return false;
          }

          return { categorieEs, categorieEn };
        }
      });

      if (result.isConfirmed) {
        const response = await Promise.all([
          axios.put(
            `http://localhost:3001/back-office/api/qfa/category/update/${categorie.id}`,
            { name: result.value.categorieEs },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
          axios.put(
            `http://localhost:3001/back-office/api/qfa/category/update/${categorieEn.id}`,
            { name: result.value.categorieEn },
            { 
              headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              } 
            }
          ),
        ]);

        Swal.fire(
          "¡Actualizado!",
          "La categoria ha sido actualizada.",
          "success"
        );
      
        handleVerCategorias(nivelSeleccionado);  // Recargar la lista de categorias
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelado", "Accion cancelada.", "error");
      }
      
    } catch (error) {
      Swal.fire(
        "Error al actualizar la categoria",
        "No se ha actualizado ninguna categoria."
      );
    }
  };

  const updateFaq = async (faq) => {
    let quillInstance; // Variable para almacenar la instancia de Quill
    let quillInstanceEn; // Variable para almacenar la instancia de Quill en ingles
    let selectedFile = null; // Variable para almacenar el archivo seleccionado
    let selectedFileEn = null; // Archivo en inglés

    const faqEn = getEnglishFaq(faq);

    const result = await Swal.fire({
      title: "Editar Pregunta",
      html:
        '<div class="form-grid">' +
        '<font size="3">Pregunta en español</font><br />' +
        `<input type="text" id="question" class="swal2-input" style="margin-bottom: 20px; margin-top: 0px;" placeholder="Pregunta" value="${faq.question}"><br />` +
        '<font size="3">Pregunta en ingles</font><br />' +
        `<input type="text" id="question_en" class="swal2-input" style="margin-bottom: 20px; margin-top: 0px;" placeholder="Question" value="${faqEn.question}"><br />` +
        '<font size="3">Respuesta en español</font><br />' +
        '<div id="editorContainer" style="height: 150px; border: 1px solid black; padding: 5px; border-radius: 5px;"></div>' + // Contenedor del editor
        '<font size="3">Respuesta en ingles</font><br />' +
        '<div id="editorContainerEn" style="height: 150px; border: 1px solid black; padding: 5px; border-radius: 5px;"></div>' +  // Contenedor del editor en ingles
        '<font size="3">Archivo en español</font><br />' +
        `<input type="file" id="fileUpload" class="swal2-in"><br/>
        ${faq.file_url ? `<span id="fileLabel">Archivo actual: ${faq.file_url.split('/').pop()}</span><br/><br/>` : ""}` +
        '<font size="3">Archivo en ingles</font><br />' +
        `<input type="file" id="fileUploadEn" class="swal2-in"><br/>
        ${faqEn.file_url ? `<span id="fileLabelEn">Archivo actual: ${faqEn.file_url.split('/').pop()}</span><br/>` : ""}` +
        "</div>",
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      didOpen: () => {
        // Módulos personalizados para Quill
        const modules = {
          toolbar: [
            [{ header: [1, 2, false] }], // Encabezados h1, h2 y texto normal
            ["bold", "italic", "underline"], // Negrita, cursiva y subrayado
            [{ list: "ordered" }, { list: "bullet" }], // Listas ordenadas y con viñetas
            ["blockquote", "code-block"], // Bloques de cita y código
            [{ align: [] }], // Alineaciones
            ["clean"], // Botón para limpiar el formato
          ],
        };

        // Inicializar Quill en es y en dentro del modal
        quillInstance = new Quill("#editorContainer", {
          theme: "snow",
          placeholder: "Escribe tu respuesta aquí...",
          modules,
        });
        quillInstance.root.innerHTML = faq.answers; // Cargar la respuesta existente

        quillInstanceEn = new Quill("#editorContainerEn", {
          theme: "snow",
          placeholder: "Escribe tu respuesta aquí...",
          modules,
        });
        quillInstanceEn.root.innerHTML = faqEn.answers; // Cargar la respuesta existente

        // Capturar archivo en español
        document.getElementById("fileUpload").addEventListener("change", (event) => {
          selectedFile = event.target.files[0]; // Guardar el archivo
        });

        // Capturar archivo en inglés
        document.getElementById("fileUploadEn").addEventListener("change", (event) => {
          selectedFileEn = event.target.files[0];
        });
      },
      preConfirm: () => {
        const questionEs = document.getElementById("question").value.trim();
        const questionEn = document.getElementById("question_en").value.trim();
        const answerEs = quillInstance.root.innerHTML.trim();
        const answerEn = quillInstanceEn.root.innerHTML.trim();

        if (!questionEs || !questionEn || !answerEs || !answerEn) {
            Swal.showValidationMessage("La pregunta y respuesta son obligatorias en ambos idiomas");
            return false;
        }

        return { questionEs, questionEn, answerEs, answerEn };
      },
    });

    if (result.isConfirmed) {
      const formDataEs = new FormData();
      formDataEs.append("name", result.value.questionEs);
      formDataEs.append("question", result.value.questionEs);
      formDataEs.append("answers", result.value.answerEs);
      if (selectedFile) {
        formDataEs.append("file", selectedFile);
      }

      const formDataEn = new FormData();
      formDataEn.append("name", result.value.questionEn);
      formDataEn.append("question", result.value.questionEn);
      formDataEn.append("answers", result.value.answerEn);
      if (selectedFileEn) {
        formDataEn.append("file", selectedFileEn);
      }

      const response = await Promise.all([
        axios.put(
          `http://localhost:3001/back-office/api/qfa/question/update/${faq.id}`,
          formDataEs,
          { 
            headers: { 
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            } 
          }
        ),
        axios.put(
          `http://localhost:3001/back-office/api/qfa/question/update/${faqEn.id}`,
          formDataEn,
          { 
            headers: { 
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            } 
          }
        ),
      ]);

      Swal.fire("¡Actualizado!", "La pregunta ha sido actualizada.", "success");
      handleVerPreguntas(categoriaSeleccionada); // Recargar lista de preguntas
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelado", "Acción cancelada.", "error");
    }
  };

  /* Eventos para eliminar "nivel 1", "categoria" y "faq" */
  const deleteLevel = async (nivel) => {
    const nivelEn = getEnglishLevel(nivel)

    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        const requests = [
          axios.delete(
            `http://localhost:3001/back-office/api/qfa/issues/delete/${nivel.id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
        ];

        if (nivelEn.id) {
          requests.push(
            axios.delete(
              `http://localhost:3001/back-office/api/qfa/issues/delete/${nivelEn.id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
          );
        }

        await Promise.all(requests);

        Swal.fire("¡Eliminado!", `El nivel ha sido eliminado.`, "success");
        getLevels1();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "El nivel 1 está a salvo :)",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  };

  const deleteCategorie = async (categorie) => {
    const categorieEn = getEnglishCategory(categorie);
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        const requests = [
          axios.delete(
            `http://localhost:3001/back-office/api/qfa/category/delete/${categorie.id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
        ];

        if (categorieEn.id) {
          requests.push(
            axios.delete(
              `http://localhost:3001/back-office/api/qfa/category/delete/${categorieEn.id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
          );
        }

        await Promise.all(requests);

        Swal.fire("¡Eliminado!", `La categoria ha sido eliminada.`, "success");
        
        handleVerCategorias(nivelSeleccionado);  // Recargar la lista de categorias
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "Tu categoria está a salvo :)",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  };

  const deleteFaq = async (faq) => {
    const faqEn = getEnglishFaq(faq);
    try {
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });

      if (result.isConfirmed) {
        const requests = [
          axios.delete(
            `http://localhost:3001/back-office/api/qfa/question/delete/${faq.id}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
        ];

        if (faqEn.id) {
          requests.push(
            axios.delete(
              `http://localhost:3001/back-office/api/qfa/question/delete/${faqEn.id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
          );
        }

        await Promise.all(requests);

        Swal.fire("¡Eliminado!", "La pregunta ha sido eliminada.", "success");
        handleVerPreguntas(categoriaSeleccionada); // Recargar lista de preguntas
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          "Cancelado",
          "Tu pregunta está a salvo :)",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Error", "Hubo un error", "error");
    }
  };

  return(
    <>
      <div className="table-responsive">
        {/* Vista de niveles 1 */}
        {vista === "niveles" && (
          <>
          <h3 style={{color: '#020c41'}}>Niveles 1</h3>
          <div className="panel-table">
            <div className="colum-left">
                <input
                type="text"
                className="searchInput"
                id="searchInput"
                placeholder="Buscar..."
                />
                <button className="btnn" onClick={handleSearch}>
                Buscar
                </button>
            </div>
            <div className="colum-rigth">
                <button className="btnn" onClick={createLevel1}>
                Agregar Nivel 1
                </button>
            </div>
          </div>

          <table className="table table-preve" id="tableToSearch">
            <thead>
                <tr>
                    <th style={{ width: "45%" }}>Nombre de nivel 1</th>
                    <th style={{ width: "40%" }}>Fecha de creación</th>
                    <td className="accions" style={{ width: "15%" }}>Acciones</td>
                </tr>
            </thead>

            {niveles1.map((nivel) => (
            <tbody>
                <tr key={nivel.id} data-master-id={nivel.id}>
                    <td>{nivel.name}</td>
                    <td>{nivel.created_at}</td>
                    <td>
                        <FaEdit className='editButton' onClick={() => { updateLevel1(nivel) }} />
                        <FaTimes className='deleteButton' onClick={() => { deleteLevel(nivel) }} />
                        <FaList className="folderButton" onClick={() => handleVerCategorias(nivel)} />
                    </td>
                </tr>
            </tbody>
            ))}
          </table>
          </>
        )}

        {/* Vista de categorias */}
        {vista === "categorias" && nivelSeleccionado && (
          <>
            <div className="panel-table">
              <div className="colum-left">
                <button onClick={handleVolver} className="btnn" style={{marginBottom: '10px'}}>
                  <FaArrowLeft />
                </button> <br></br>
                <h3 style={{color: '#020c41'}}>{nivelSeleccionado.name} - <span>Categorías</span></h3>
                <input
                  type="text"
                  className="searchInput"
                  id="searchInput"
                  placeholder="Buscar..."
                />
                <button className="btnn" onClick={handleSearch}>
                  Buscar
                </button>
              </div>
              <div className="colum-rigth">
                  <button className="btnn" onClick={createCategorie}>
                    Agregar Categoria
                  </button>
              </div>
            </div>
            <table className="table table-preve">
              <thead>
                <tr>
                  <th>Categoría</th>
                  <td className="accions" style={{ width: "15%" }}>Acciones</td>
                </tr>
              </thead>
              <tbody>
                {nivelSeleccionado?.categorias?.length > 0 ? (
                  nivelSeleccionado.categorias.map((categoria) => (
                    <tr key={categoria.id}>
                      <td>{categoria.name}</td>
                      <td>
                        <FaEdit className='editButton' onClick={() => { updateCategorie(categoria) }} />
                        <FaTimes className='deleteButton' onClick={() => { deleteCategorie(categoria) }} />
                        <FaList className="folderButton" onClick={() => handleVerPreguntas(categoria)} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No hay categorías en este nivel 1.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}

        {/* Vista de preguntas y respuestas */}
        {vista === "preguntas" && categoriaSeleccionada && (
          <>
            <div className="panel-table">
              <div className="colum-left">
                <button onClick={handleVolver} className="btnn" style={{marginBottom: '10px'}}>
                  <FaArrowLeft />
                </button> <br></br>
                <h3 style={{color: '#020c41'}}>{nivelSeleccionado.name} - {categoriaSeleccionada.name} - <span>Preguntas</span></h3>
                <input
                type="text"
                className="searchInput"
                id="searchInput"
                placeholder="Buscar..."
                />
                <button className="btnn" onClick={handleSearch}>
                Buscar
                </button>
              </div>
              <div className="colum-rigth">
                <button className="btnn" onClick={createFaq}>
                  Agregar Pregunta
                </button>
              </div>
            </div>
            <table className="table table-preve">
              <thead>
                <tr>
                  <th>Pregunta</th>
                  <th>Respuesta</th>
                  <td className="accions" style={{ width: "15%" }}>Acciones</td>
                </tr>
              </thead>
              <tbody>
                {categoriaSeleccionada?.preguntas?.length > 0 ? (
                  categoriaSeleccionada.preguntas.map((pregunta) => (
                    <tr key={pregunta.id}>
                      <td>{pregunta.question}</td>
                      <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(pregunta.answers) }}></td>
                      <td>
                        <FaEdit className='editButton' onClick={() => { updateFaq(pregunta) }} />
                        <FaTimes className='deleteButton' onClick={() => { deleteFaq(pregunta) }} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="2">No hay preguntas en esta categoría.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
}

export default Faq