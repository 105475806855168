import {
  value_aditional_HIT,
  tarifa_Gold,
  tarifa_perecederos,
  tarifa_mensajeria,
  tarifa_animales_perros_general,
  Zona_01,
  Zona_02,
  Zona_03,
  Zona_04,
  Zona_05,
  Zona_06,
  Zona_07,
  Zona_08,
  Zona_09,
  Zona_10,
  Zona_11,
  matriz,
} from "./tarifas";

function encontrarZona(origen, destino) {
  const zonas = [
    Zona_01,
    Zona_02,
    Zona_03,
    Zona_04,
    Zona_05,
    Zona_06,
    Zona_07,
    Zona_08,
    Zona_09,
    Zona_10,
    Zona_11,
  ];

  // Encontrar el índice de la zona del origen y destino
  let indiceOrigen = -1;
  let indiceDestino = -1;

  for (let i = 0; i < zonas.length; i++) {
    if (zonas[i].some((zona) => origen.startsWith(zona))) {
      indiceOrigen = i;
    }

    if (zonas[i].some((zona) => destino.startsWith(zona))) {
      indiceDestino = i;
    }
  }

  if (
    indiceOrigen >= 0 &&
    indiceOrigen < matriz.length &&
    indiceDestino >= 0 &&
    indiceDestino < matriz[indiceOrigen].length
  ) {
    return matriz[indiceOrigen][indiceDestino];
  } else {
    return "No definido";
  }
}

function convertStringNumbers(obj) {
  if (typeof obj === "object" && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map(convertStringNumbers); // Si es array, iteramos sobre sus elementos
    } else {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key,
          convertStringNumbers(value),
        ])
      );
    }
  } else if (typeof obj === "string" && !isNaN(obj) && obj.trim() !== "") {
    return parseFloat(obj); // Si es string y es un número válido, lo convertimos
  }
  return obj; // Si no es un objeto ni un string numérico, lo dejamos igual
}

function getConvertedRates() {
  const tarifas = JSON.parse(localStorage.getItem("rates")); // Obtener datos

  return convertStringNumbers(tarifas); // Convertir valores y retornar
}

function formatValue(valor) {
  // Verifica si el valor es un número
  if (typeof valor !== "number") {
    return "";
  }

  // Redondea el valor a dos decimales
  valor = Math.round(valor * 100) / 100;

  // Formatea el valor como cadena con dos decimales
  const valorFormateado = valor.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return valorFormateado;
}

function calcularCargoCombustible(pesoFinal) {
  const tarifas = getConvertedRates();
  const tarifaCombustible =
    tarifas.find((tarifa) => tarifa.service === "Cargo por Combustible (MY)") ||
    [];
  let cargoCombustible = pesoFinal * parseFloat(tarifaCombustible.tarifas.kg);
  cargoCombustible = Math.round(cargoCombustible * 100) / 100; // Redondear a dos decimales

  if (cargoCombustible <= tarifaCombustible.tarifas.min) {
    cargoCombustible = parseFloat(tarifaCombustible.tarifas.min);
  }
  return parseFloat(cargoCombustible);
}

function calcularOperacionTerrestre(origen, destino, peso) {
  const tarifas = getConvertedRates();
  const tarifaOperacionTerrestre =
    tarifas.find((tarifa) => tarifa.service === "Operación Terrestre (MC)") ||
    [];
  let operacionTerrestre = 0;

  // Orígenes
  if (
    (origen === "PBC" || origen === "SLW") &&
    destino !== "PBC" &&
    destino !== "SLW"
  ) {
    operacionTerrestre = peso * tarifaOperacionTerrestre.tarifas.origenes.kg;

    if (operacionTerrestre <= tarifaOperacionTerrestre.tarifas.origenes.min) {
      operacionTerrestre = tarifaOperacionTerrestre.tarifas.origenes.min;
    }
    // Destinos
  } else if (
    (destino === "PBC" || destino === "SLW") &&
    origen !== "PBC" &&
    origen !== "SLW"
  ) {
    operacionTerrestre = peso * tarifaOperacionTerrestre.tarifas.destinos.kg;

    if (operacionTerrestre <= tarifaOperacionTerrestre.tarifas.destinos.min) {
      operacionTerrestre = tarifaOperacionTerrestre.tarifas.destinos.min;
    }
    // Origen y Destino
  } else if (origen === "PBC" && destino === "SLW") {
    operacionTerrestre = peso * tarifaOperacionTerrestre.tarifas.PBCSLW.kg;

    if (operacionTerrestre <= tarifaOperacionTerrestre.tarifas.PBCSLW.min) {
      operacionTerrestre = tarifaOperacionTerrestre.tarifas.PBCSLW.min;
    }
    // Origen y Destino
  } else if (origen === "SLW" && destino === "PBC") {
    operacionTerrestre = peso * tarifaOperacionTerrestre.tarifas.SLWPBC.kg;

    if (operacionTerrestre <= tarifaOperacionTerrestre.tarifas.SLWPBC.min) {
      operacionTerrestre = tarifaOperacionTerrestre.tarifas.SLWPBC.min;
    }
  } else {
    operacionTerrestre = 0;
  }

  operacionTerrestre = Math.round(operacionTerrestre * 100) / 100;

  return operacionTerrestre;
}

function calcularCertificacionFB() {
  const tarifas = getConvertedRates();

  const tarifasCertificacionFB =
    tarifas.find(
      (tarifa) =>
        tarifa.service === "Cargo por manejo especializado de Pharma (FB)"
    ) || [];

  return Math.round(tarifasCertificacionFB.tarifas * 100) / 100;
}

function calcularCargoFijo() {
  const tarifas = getConvertedRates();

  const tarifasCargoFijo =
    tarifas.find((tarifa) => tarifa.service === "Cargo Fijo (AW)") || [];

  return Math.round(tarifasCargoFijo.tarifas * 100) / 100;
}

function calcularCargosAdicionales(PesoFinal, destinoCode, domicilio_cp) {
  const tarifas = getConvertedRates();
  const tarifaCargosAdicionales =
    tarifas.find((tarifa) => tarifa.service === "Otros Cargos (DDLV)") || [];
  let CargosAdicionales = 0;

  if (domicilio_cp !== "") {
    // Destinos TIJ, GDL, MTY, MID, MEX, CUN
    if (
      destinoCode === "TIJ" ||
      destinoCode === "GDL" ||
      destinoCode === "MTY" ||
      destinoCode === "MID" ||
      destinoCode === "MEX1" ||
      destinoCode === "MEX2" ||
      destinoCode === "MEX3" ||
      destinoCode === "MEX" ||
      destinoCode === "CUN"
    ) {
      // Rangos de peso y tarifas
      if (PesoFinal > 0 && PesoFinal <= 21) {
        CargosAdicionales =
          tarifaCargosAdicionales.tarifas.destinoEspecial["0kg"];
      } else if (PesoFinal >= 22 && PesoFinal <= 50) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.destinoEspecial["+21kg"];
      } else if (PesoFinal >= 51 && PesoFinal <= 300) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.destinoEspecial["+50kg"];
      } else if (PesoFinal >= 301 && PesoFinal <= 999) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.destinoEspecial["+300kg"];
      } else if (PesoFinal >= 1000) {
        CargosAdicionales =
          PesoFinal *
          tarifaCargosAdicionales.tarifas.destinoEspecial["+1000kg"];
      }
    } else {
      // Otros destinos
      // Rangos de peso y tarifas
      if (PesoFinal > 0 && PesoFinal <= 9) {
        CargosAdicionales = tarifaCargosAdicionales.tarifas.otrosDestino["0kg"];
      } else if (PesoFinal >= 10 && PesoFinal <= 24) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+10kg"];
      } else if (PesoFinal >= 25 && PesoFinal <= 49) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+25kg"];
      } else if (PesoFinal >= 50 && PesoFinal <= 99) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+50kg"];
      } else if (PesoFinal >= 100 && PesoFinal <= 299) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+100kg"];
      } else if (PesoFinal >= 300 && PesoFinal <= 399) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+300kg"];
      } else if (PesoFinal >= 400 && PesoFinal <= 499) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+400kg"];
      } else if (PesoFinal >= 500 && PesoFinal <= 999) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+500kg"];
      } else if (PesoFinal >= 1000 && PesoFinal <= 1499) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+1000kg"];
      } else if (PesoFinal >= 1500) {
        CargosAdicionales =
          PesoFinal * tarifaCargosAdicionales.tarifas.otrosDestino["+1500kg"];
      }
    }
  }

  return CargosAdicionales;
}

function calcularSubtotal(
  flete,
  cargoFijo,
  cargoCombustible,
  valorDeclarado,
  otrosCargos,
  pagoCC,
  operacionTerrestre,
  cargoAcarreo,
  cargoPorCertificacion
) {
  let subtotal =
    flete +
    cargoFijo +
    cargoCombustible +
    valorDeclarado +
    otrosCargos +
    pagoCC +
    operacionTerrestre +
    cargoAcarreo +
    cargoPorCertificacion;

  subtotal = Math.round(subtotal * 100) / 100;

  return subtotal;
}

function calcularTotal(subtotal, subtotalIva) {
  let total = subtotal + subtotalIva;
  total = Math.round(total * 100) / 100; // Redondear a dos decimales

  return total;
}

function calcularPagoCC(pago, flete) {
  const tarifas = getConvertedRates();

  const tarifaPagoCC =
    tarifas.find((tarifa) => tarifa.service === "Pago al cobro (CC)") || [];
  const pagoCC = tarifaPagoCC.tarifas;
  let pagoCobro = 0.0;

  if (pago === "destino" || pago === "destination") {
    pagoCobro = flete * pagoCC;
    pagoCobro = Math.round(pagoCobro * 100) / 100;
  }

  return pagoCobro;
}

function calcularValuacion(valor, pesoFinal) {
  const importeBase = 0.01;
  const factor = 1.8;
  let valorDeclarado = 0;

  if (valor > 0) {
    valorDeclarado = parseFloat(
      ((parseFloat(valor) - pesoFinal * importeBase) * factor) / 100
    );
    valorDeclarado = Math.round(valorDeclarado * 100) / 100;
    valorDeclarado = Math.ceil(valorDeclarado);
  } else {
    valorDeclarado = 0;
  }

  return valorDeclarado;
}

function calculateFlete(
  servicio,
  peso_final,
  tarifa,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino
) {
  parseFloat(peso_final);

  const tarifas = getConvertedRates();

  const tarifario =
    tarifas.find((tarifa) => tarifa.service === "12 + 12") || [];

  const tarifaHIT =
    tarifas.find((tarifa) => tarifa.service === "Alta Tecnología") || [];

  const tarifaPerecederos =
    tarifas.find((tarifa) => tarifa.service === "Perecederos") || [];

  const tarifaAltoValor =
    tarifas.find((tarifa) => tarifa.service === "Alto Valor") || [];

  const tarifaAnimalesVivos =
    tarifas.find((tarifa) => tarifa.service === "Animales Vivos") || [];

  const tarifaGold = tarifas.find((tarifa) => tarifa.service === "Gold") || [];

  const tarifaPharmaXPS =
    tarifas.find((tarifa) => tarifa.service === "Pharma XPS") || [];

  const tarifaPharmaSTD =
    tarifas.find((tarifa) => tarifa.service === "Tarifas Pharma STD (FAD)") ||
    [];

  /*
        MIN Tarifas Table ––> [A]
        🗓 Update 8/ENE/2024
    */
  const MIN_A = parseFloat(tarifario.tarifas.tarifaA.A.MIN);
  const MIN_B = parseFloat(tarifario.tarifas.tarifaA.B.MIN);
  const MIN_C = parseFloat(tarifario.tarifas.tarifaA.C.MIN);
  const MIN_D = parseFloat(tarifario.tarifas.tarifaA.D.MIN);
  const MIN_E = parseFloat(tarifario.tarifas.tarifaA.E.MIN);
  const MIN_F = parseFloat(tarifario.tarifas.tarifaA.F.MIN);
  const MIN_G = parseFloat(tarifario.tarifas.tarifaA.G.MIN);

  /*
        MIN Tarifas Table ––> [B]
        🗓 Update 8/ENE/2024
    */
  const TarifasB_MIN_A = parseFloat(tarifario.tarifas.tarifaB.A.MIN);
  const TarifasB_MIN_B = parseFloat(tarifario.tarifas.tarifaB.B.MIN);
  const TarifasB_MIN_C = parseFloat(tarifario.tarifas.tarifaB.C.MIN);
  const TarifasB_MIN_D = parseFloat(tarifario.tarifas.tarifaB.D.MIN);
  const TarifasB_MIN_E = parseFloat(tarifario.tarifas.tarifaB.E.MIN);
  const TarifasB_MIN_F = parseFloat(tarifario.tarifas.tarifaB.F.MIN);
  const TarifasB_MIN_G = parseFloat(tarifario.tarifas.tarifaB.G.MIN);

  /*
===================================
    TABLE [A]
===================================
*/
  /*
    Tarifas para letra ––> [A]
    🗓 Update 8/ENE/2024
*/
  const tarifa_A_01 = parseFloat(tarifario.tarifas.tarifaA.A["1kg"]); // 1kg*
  const tarifa_A_02 = parseFloat(tarifario.tarifas.tarifaA.A["+45kg"]); // +45kg*
  const tarifa_A_03 = parseFloat(tarifario.tarifas.tarifaA.A["+100kg"]); // +100kg*
  const tarifa_A_04 = parseFloat(tarifario.tarifas.tarifaA.A["+300kg"]); // +300kg*
  const tarifa_A_05 = parseFloat(tarifario.tarifas.tarifaA.A["+500kg"]); // +500kg*

  /*
    Tarifas para letra ––> [B]
    🗓 🗓 Update 8/ENE/2024
*/
  const tarifa_B_01 = parseFloat(tarifario.tarifas.tarifaA.B["1kg"]); // 1kg*
  const tarifa_B_02 = parseFloat(tarifario.tarifas.tarifaA.B["+45kg"]); // +45kg*
  const tarifa_B_03 = parseFloat(tarifario.tarifas.tarifaA.B["+100kg"]); // +100kg*
  const tarifa_B_04 = parseFloat(tarifario.tarifas.tarifaA.B["+300kg"]); // +300kg*
  const tarifa_B_05 = parseFloat(tarifario.tarifas.tarifaA.B["+500kg"]); // +500kg*

  /*
    Tarifas para letra ––> [C]
    🗓 🗓 Update 8/ENE/2024
*/
  const tarifa_C_01 = parseFloat(tarifario.tarifas.tarifaA.C["1kg"]); // 1kg*
  const tarifa_C_02 = parseFloat(tarifario.tarifas.tarifaA.C["+45kg"]); // +45kg*
  const tarifa_C_03 = parseFloat(tarifario.tarifas.tarifaA.C["+100kg"]); // +100kg*
  const tarifa_C_04 = parseFloat(tarifario.tarifas.tarifaA.C["+300kg"]); // +300kg*
  const tarifa_C_05 = parseFloat(tarifario.tarifas.tarifaA.C["+500kg"]); // +500kg*

  /*
    Tarifas para letra ––> [D]
    🗓 🗓 Update 8/ENE/2024
*/
  const tarifa_D_01 = parseFloat(tarifario.tarifas.tarifaA.D["1kg"]); // 1kg*
  const tarifa_D_02 = parseFloat(tarifario.tarifas.tarifaA.D["+45kg"]); // +45kg*
  const tarifa_D_03 = parseFloat(tarifario.tarifas.tarifaA.D["+100kg"]); // +100kg*
  const tarifa_D_04 = parseFloat(tarifario.tarifas.tarifaA.D["+300kg"]); // +300kg*
  const tarifa_D_05 = parseFloat(tarifario.tarifas.tarifaA.D["+500kg"]); // +500kg*

  /*
    Tarifas para letra ––> [E]
    🗓 🗓 Update 8/ENE/2024
*/
  const tarifa_E_01 = parseFloat(tarifario.tarifas.tarifaA.E["1kg"]); // 1kg*
  const tarifa_E_02 = parseFloat(tarifario.tarifas.tarifaA.E["+45kg"]); // +45kg*
  const tarifa_E_03 = parseFloat(tarifario.tarifas.tarifaA.E["+100kg"]); // +100kg*
  const tarifa_E_04 = parseFloat(tarifario.tarifas.tarifaA.E["+300kg"]); // +300kg*
  const tarifa_E_05 = parseFloat(tarifario.tarifas.tarifaA.E["+500kg"]); // +500kg*

  /*
    Tarifas para letra ––> [F]
    🗓 🗓 Update 8/ENE/2024
*/
  const tarifa_F_01 = parseFloat(tarifario.tarifas.tarifaA.F["1kg"]); // 1kg*
  const tarifa_F_02 = parseFloat(tarifario.tarifas.tarifaA.F["+45kg"]); // +45kg*
  const tarifa_F_03 = parseFloat(tarifario.tarifas.tarifaA.F["+100kg"]); // +100kg*
  const tarifa_F_04 = parseFloat(tarifario.tarifas.tarifaA.F["+300kg"]); // +300kg*
  const tarifa_F_05 = parseFloat(tarifario.tarifas.tarifaA.F["+500kg"]); // +500kg*

  /*
    Tarifas para letra ––> [G]
    🗓 🗓 Update 8/ENE/2024
*/
  const tarifa_G_01 = parseFloat(tarifario.tarifas.tarifaA.G["1kg"]); // 1kg*
  const tarifa_G_02 = parseFloat(tarifario.tarifas.tarifaA.G["+45kg"]); // +45kg*
  const tarifa_G_03 = parseFloat(tarifario.tarifas.tarifaA.G["+100kg"]); // +100kg*
  const tarifa_G_04 = parseFloat(tarifario.tarifas.tarifaA.G["+300kg"]); // +300kg*
  const tarifa_G_05 = parseFloat(tarifario.tarifas.tarifaA.G["+500kg"]); // +500kg*

  /*
===================================
    TABLE B
===================================
/*
    Tarifas Tipo B para letra ––> [A]
    🗓 🗓 Update 8/ENE/2024
*/
  const table_B_tarifa_A_01 = parseFloat(tarifario.tarifas.tarifaB.A["1kg"]); // 1kg*
  const table_B_tarifa_A_02 = parseFloat(tarifario.tarifas.tarifaB.A["+45kg"]); // +45kg*
  const table_B_tarifa_A_03 = parseFloat(tarifario.tarifas.tarifaB.A["+100kg"]); // +100kg*
  const table_B_tarifa_A_04 = parseFloat(tarifario.tarifas.tarifaB.A["+300kg"]); // +300kg*
  const table_B_tarifa_A_05 = parseFloat(tarifario.tarifas.tarifaB.A["+500kg"]); // +500kg*

  /*
    Tarifas Tipo B para letra ––> [B]
    🗓 🗓 Update 8/ENE/2024
*/
  const table_B_tarifa_B_01 = parseFloat(tarifario.tarifas.tarifaB.B["1kg"]); // 1kg*
  const table_B_tarifa_B_02 = parseFloat(tarifario.tarifas.tarifaB.B["+45kg"]); // +45kg*
  const table_B_tarifa_B_03 = parseFloat(tarifario.tarifas.tarifaB.B["+100kg"]); // +100kg*
  const table_B_tarifa_B_04 = parseFloat(tarifario.tarifas.tarifaB.B["+300kg"]); // +300kg*
  const table_B_tarifa_B_05 = parseFloat(tarifario.tarifas.tarifaB.B["+500kg"]); // +500kg*

  /*
    Tarifas Tipo B para letra ––> [C]
    🗓 🗓 Update 8/ENE/2024
*/
  const table_B_tarifa_C_01 = parseFloat(tarifario.tarifas.tarifaB.C["1kg"]); // 1kg*
  const table_B_tarifa_C_02 = parseFloat(tarifario.tarifas.tarifaB.C["+45kg"]); // +45kg*
  const table_B_tarifa_C_03 = parseFloat(tarifario.tarifas.tarifaB.C["+100kg"]); // +100kg*
  const table_B_tarifa_C_04 = parseFloat(tarifario.tarifas.tarifaB.C["+300kg"]); // +300kg*
  const table_B_tarifa_C_05 = parseFloat(tarifario.tarifas.tarifaB.C["+500kg"]); // +500kg*

  /*
    Tarifas Tipo B para letra ––> [D]
    🗓 🗓 Update 8/ENE/2024
*/
  const table_B_tarifa_D_01 = parseFloat(tarifario.tarifas.tarifaB.D["1kg"]); // 1kg*
  const table_B_tarifa_D_02 = parseFloat(tarifario.tarifas.tarifaB.D["+45kg"]); // +45kg*
  const table_B_tarifa_D_03 = parseFloat(tarifario.tarifas.tarifaB.D["+100kg"]); // +100kg*
  const table_B_tarifa_D_04 = parseFloat(tarifario.tarifas.tarifaB.D["+300kg"]); // +300kg*
  const table_B_tarifa_D_05 = parseFloat(tarifario.tarifas.tarifaB.D["+500kg"]); // +500kg*

  /*
    Tarifas Tipo B para letra ––> [E]
    🗓 🗓 Update 8/ENE/2024
*/
  const table_B_tarifa_E_01 = parseFloat(tarifario.tarifas.tarifaB.E["1kg"]); // 1kg*
  const table_B_tarifa_E_02 = parseFloat(tarifario.tarifas.tarifaB.E["+45kg"]); // +45kg*
  const table_B_tarifa_E_03 = parseFloat(tarifario.tarifas.tarifaB.E["+100kg"]); // +100kg*
  const table_B_tarifa_E_04 = parseFloat(tarifario.tarifas.tarifaB.E["+300kg"]); // +300kg*
  const table_B_tarifa_E_05 = parseFloat(tarifario.tarifas.tarifaB.E["+500kg"]); // +500kg*

  /*
    Tarifas Tipo B para letra ––> [F]
    🗓 🗓 Update 8/ENE/2024
*/
  const table_B_tarifa_F_01 = parseFloat(tarifario.tarifas.tarifaB.F["1kg"]); // 1kg*
  const table_B_tarifa_F_02 = parseFloat(tarifario.tarifas.tarifaB.F["+45kg"]); // +45kg*
  const table_B_tarifa_F_03 = parseFloat(tarifario.tarifas.tarifaB.F["+100kg"]); // +100kg*
  const table_B_tarifa_F_04 = parseFloat(tarifario.tarifas.tarifaB.F["+300kg"]); // +300kg*
  const table_B_tarifa_F_05 = parseFloat(tarifario.tarifas.tarifaB.F["+500kg"]); // +500kg*

  /*
    Tarifas Tipo B para letra ––> [G]
    🗓 🗓 Update 8/ENE/2024
*/
  const table_B_tarifa_G_01 = parseFloat(tarifario.tarifas.tarifaB.G["1kg"]); // 1kg*
  const table_B_tarifa_G_02 = parseFloat(tarifario.tarifas.tarifaB.G["+45kg"]); // +45kg*
  const table_B_tarifa_G_03 = parseFloat(tarifario.tarifas.tarifaB.G["+100kg"]); // +100kg*
  const table_B_tarifa_G_04 = parseFloat(tarifario.tarifas.tarifaB.G["+300kg"]); // +300kg*
  const table_B_tarifa_G_05 = parseFloat(tarifario.tarifas.tarifaB.G["+500kg"]); // +500kg*

  // Tarifas fijas y Campos adicionales
  const value_aditional_HIT = parseFloat(tarifaHIT.tarifas);

  const tarifa_Gold = parseFloat(tarifaGold.tarifas.kg);
  const tarifa_Gold_MIN = parseFloat(tarifaGold.tarifas.min);

  const tarifa_perecederos = parseFloat(tarifaPerecederos.tarifas);
  const tarifa_AltoValor = parseFloat(tarifaAltoValor.tarifas);

  const tarifa_restos_humanos = 46.22;
  const tarifa_restos_humanos_MIN = 6434.31;
  const tarifa_restos_cenizas = 701.92;

  const tarifa_animales_perros_especial = parseFloat(
    tarifaAnimalesVivos.tarifas.perros.origenEspecial.kg
  );

  const tarifa_animales_perros_especial_MIN = parseFloat(
    tarifaAnimalesVivos.tarifas.perros.origenEspecial.min
  );

  const tarifa_animales_perros_general = parseFloat(
    tarifaAnimalesVivos.tarifas.perros.otrosOrigenes.kg
  );
  const tarifa_animales_perros_general_MIN = parseFloat(
    tarifaAnimalesVivos.tarifas.perros.otrosOrigenes.min
  );

  const tarifa_animales_aves = parseFloat(tarifaAnimalesVivos.tarifas.aves.kg);
  const tarifa_animales_aves_MIN = parseFloat(
    tarifaAnimalesVivos.tarifas.aves.min
  );

  const tarifa_animales_otros = parseFloat(
    tarifaAnimalesVivos.tarifas.otros.kg
  );
  const tarifa_animales_otros_MIN = parseFloat(
    tarifaAnimalesVivos.tarifas.otros.min
  );

  const tarifa_farma_especializado = 70.0;
  const tarifa_farma_especializado_MIN = 1548.1;

  if (origen !== "") {
    var origen_num = origen.indexOf("(");
    origen = origen.substring(origen_num + 1, origen_num + 4);
  }

  var flete = 0;

  if (servicio === "Gold") {
    flete = peso_final * tarifa_Gold;

    if (flete <= tarifa_Gold_MIN) {
      flete = tarifa_Gold_MIN;
    }

    flete = parseFloat(flete.toFixed(2));
  } else if (servicio === "Perecederos" || servicio === "Perishable Goods") {
    if (
      origen === "MEX" &&
      (destino === "LAP" ||
        destino === "CUL" ||
        destino === "MXL" ||
        destino === "CUU" ||
        destino === "TGZ")
    ) {
      if (destino === "LAP") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["1kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["+45kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["+100kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["+300kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["+500kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        }
      } else if (destino === "CUL") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["1kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["+45kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["+100kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["+300kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["+500kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        }
      } else if (destino === "MXL") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["1kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["+45kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["+100kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["+300kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["+500kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        }
      } else if (destino === "CUU") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["1kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["+45kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["+100kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["+300kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["+500kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        }
      } else if (destino === "TGZ") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["1kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["+45kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["+100kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["+300kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            (tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["+500kg"]) *
            peso_final;
          if (
            flete <
            tarifa_perecederos + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              tarifa_perecederos +
              tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        }
      }
    } else if (
      origen === "CUL" ||
      origen === "CEN" ||
      origen === "LMM" ||
      origen === "AGU" ||
      origen === "SLP" ||
      origen === "ZIH" ||
      origen === "TAP" ||
      origen === "VSA" ||
      origen === "TGZ" ||
      origen === "CME" ||
      origen === "MTT" ||
      origen === "CPE"
    ) {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + table_B_tarifa_A_01) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_A) {
            flete = tarifa_perecederos + TarifasB_MIN_A;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + table_B_tarifa_A_02) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_A) {
            flete = tarifa_perecederos + TarifasB_MIN_A;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + table_B_tarifa_A_03) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_A) {
            flete = tarifa_perecederos + TarifasB_MIN_A;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + table_B_tarifa_A_04) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
            flete = flete + tarifa_perecederos;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + table_B_tarifa_A_05) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
            flete = flete + tarifa_perecederos;
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + table_B_tarifa_B_01) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = tarifa_perecederos + TarifasB_MIN_B;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + table_B_tarifa_B_02) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = tarifa_perecederos + TarifasB_MIN_B;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            (parseFloat(tarifa_perecederos) + table_B_tarifa_B_03) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = tarifa_perecederos + TarifasB_MIN_B;
          }
          console.log("===================peso=================");
          console.log(peso_final);
          console.log("===================tarifa=================");
          console.log(table_B_tarifa_B_03);
          console.log("===================min=================");
          console.log(TarifasB_MIN_B);
          console.log("====================perecedero================");
          console.log(tarifa_perecederos);
          console.log("=============flete=======================");
          console.log(flete);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            (parseFloat(tarifa_perecederos) + table_B_tarifa_B_04) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = tarifa_perecederos + TarifasB_MIN_B;
          }
          console.log("===================peso=================");
          console.log(peso_final);
          console.log("===================tarifa=================");
          console.log(table_B_tarifa_B_04);
          console.log("===================min=================");
          console.log(TarifasB_MIN_B);
          console.log("====================perecedero================");
          console.log(tarifa_perecederos);
          console.log("=============flete=======================");
          console.log(flete);
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + table_B_tarifa_B_05) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = tarifa_perecederos + TarifasB_MIN_B;
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + table_B_tarifa_C_01) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_C) {
            flete = tarifa_perecederos + TarifasB_MIN_C;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + table_B_tarifa_C_02) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_C) {
            flete = tarifa_perecederos + TarifasB_MIN_C;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + table_B_tarifa_C_03) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_C) {
            flete = tarifa_perecederos + TarifasB_MIN_C;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + table_B_tarifa_C_04) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_C) {
            flete = tarifa_perecederos + TarifasB_MIN_C;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + table_B_tarifa_C_05) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_C) {
            flete = tarifa_perecederos + TarifasB_MIN_C;
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + table_B_tarifa_D_01) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_D) {
            flete = tarifa_perecederos + TarifasB_MIN_D;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + table_B_tarifa_D_02) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_D) {
            flete = tarifa_perecederos + TarifasB_MIN_D;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + table_B_tarifa_D_03) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_D) {
            flete = tarifa_perecederos + TarifasB_MIN_D;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + table_B_tarifa_D_04) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_D) {
            flete = tarifa_perecederos + TarifasB_MIN_D;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + table_B_tarifa_D_05) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_D) {
            flete = tarifa_perecederos + TarifasB_MIN_D;
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + table_B_tarifa_E_01) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_E) {
            flete = tarifa_perecederos + TarifasB_MIN_E;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + table_B_tarifa_E_02) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_E) {
            flete = tarifa_perecederos + TarifasB_MIN_E;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + table_B_tarifa_E_03) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_E) {
            flete = tarifa_perecederos + TarifasB_MIN_E;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + table_B_tarifa_E_04) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_E) {
            flete = tarifa_perecederos + TarifasB_MIN_E;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + table_B_tarifa_E_05) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_E) {
            flete = tarifa_perecederos + TarifasB_MIN_E;
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + table_B_tarifa_F_01) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_F) {
            flete = tarifa_perecederos + TarifasB_MIN_F;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + table_B_tarifa_F_02) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_F) {
            flete = tarifa_perecederos + TarifasB_MIN_F;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + table_B_tarifa_F_03) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_F) {
            flete = tarifa_perecederos + TarifasB_MIN_F;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + table_B_tarifa_F_04) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_F) {
            flete = tarifa_perecederos + TarifasB_MIN_F;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + table_B_tarifa_F_05) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_F) {
            flete = tarifa_perecederos + TarifasB_MIN_F;
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + table_B_tarifa_G_01) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_G) {
            flete = tarifa_perecederos + TarifasB_MIN_G;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + table_B_tarifa_G_02) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_G) {
            flete = tarifa_perecederos + TarifasB_MIN_G;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + table_B_tarifa_G_03) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_G) {
            flete = tarifa_perecederos + TarifasB_MIN_G;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + table_B_tarifa_G_04) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_G) {
            flete = tarifa_perecederos + TarifasB_MIN_G;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + table_B_tarifa_G_05) * peso_final;
          if (flete < tarifa_perecederos + TarifasB_MIN_G) {
            flete = tarifa_perecederos + TarifasB_MIN_G;
          }
        }
      }
    } else {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + tarifa_A_01) * peso_final;

          if (flete < tarifa_perecederos + MIN_A) {
            flete = tarifa_perecederos + MIN_A;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + tarifa_A_02) * peso_final;
          if (flete < tarifa_perecederos + MIN_A) {
            flete = tarifa_perecederos + MIN_A;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + tarifa_A_03) * peso_final;
          if (flete < tarifa_perecederos + MIN_A) {
            flete = tarifa_perecederos + MIN_A;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + tarifa_A_04) * peso_final;
          if (flete < tarifa_perecederos + MIN_A) {
            flete = MIN_A;
            flete = flete + tarifa_perecederos;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + tarifa_A_05) * peso_final;
          if (flete < tarifa_perecederos + MIN_A) {
            flete = MIN_A;
            flete = flete + tarifa_perecederos;
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + tarifa_B_01) * peso_final;
          if (flete < tarifa_perecederos + MIN_B) {
            flete = tarifa_perecederos + MIN_B;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + tarifa_B_02) * peso_final;
          if (flete < tarifa_perecederos + MIN_B) {
            flete = tarifa_perecederos + MIN_B;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + tarifa_B_03) * peso_final;
          if (flete < tarifa_perecederos + MIN_B) {
            flete = tarifa_perecederos + MIN_B;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + tarifa_B_04) * peso_final;

          if (flete < tarifa_perecederos + MIN_B) {
            flete = tarifa_perecederos + MIN_B;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + tarifa_B_05) * peso_final;

          if (flete < tarifa_perecederos + MIN_B) {
            flete = tarifa_perecederos + MIN_B;
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + tarifa_C_01) * peso_final;
          if (flete < tarifa_perecederos + MIN_C) {
            flete = tarifa_perecederos + MIN_C;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + tarifa_C_02) * peso_final;
          if (flete < tarifa_perecederos + MIN_C) {
            flete = tarifa_perecederos + MIN_C;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + tarifa_C_03) * peso_final;
          if (flete < tarifa_perecederos + MIN_C) {
            flete = tarifa_perecederos + MIN_C;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + tarifa_C_04) * peso_final;
          if (flete < tarifa_perecederos + MIN_C) {
            flete = tarifa_perecederos + MIN_C;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + tarifa_C_05) * peso_final;
          if (flete < tarifa_perecederos + MIN_C) {
            flete = tarifa_perecederos + MIN_C;
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + tarifa_D_01) * peso_final;
          if (flete < tarifa_perecederos + MIN_D) {
            flete = tarifa_perecederos + MIN_D;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + tarifa_D_02) * peso_final;
          if (flete < tarifa_perecederos + MIN_D) {
            flete = tarifa_perecederos + MIN_D;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + tarifa_D_03) * peso_final;
          if (flete < tarifa_perecederos + MIN_D) {
            flete = tarifa_perecederos + MIN_D;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + tarifa_D_04) * peso_final;
          if (flete < tarifa_perecederos + MIN_D) {
            flete = tarifa_perecederos + MIN_D;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + tarifa_D_05) * peso_final;
          if (flete < tarifa_perecederos + MIN_D) {
            flete = tarifa_perecederos + MIN_D;
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + tarifa_E_01) * peso_final;
          if (flete < tarifa_perecederos + MIN_E) {
            flete = tarifa_perecederos + MIN_E;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + tarifa_E_02) * peso_final;
          if (flete < tarifa_perecederos + MIN_E) {
            flete = tarifa_perecederos + MIN_E;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + tarifa_E_03) * peso_final;
          if (flete < tarifa_perecederos + MIN_E) {
            flete = tarifa_perecederos + MIN_E;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + tarifa_E_04) * peso_final;
          if (flete < tarifa_perecederos + MIN_E) {
            flete = tarifa_perecederos + MIN_E;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + tarifa_E_05) * peso_final;
          if (flete < tarifa_perecederos + MIN_E) {
            flete = tarifa_perecederos + MIN_E;
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + tarifa_F_01) * peso_final;
          if (flete < tarifa_perecederos + MIN_F) {
            flete = tarifa_perecederos + MIN_F;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + tarifa_F_02) * peso_final;
          if (flete < tarifa_perecederos + MIN_F) {
            flete = tarifa_perecederos + MIN_F;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + tarifa_F_03) * peso_final;
          if (flete < tarifa_perecederos + MIN_F) {
            flete = tarifa_perecederos + MIN_F;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + tarifa_F_04) * peso_final;
          if (flete < tarifa_perecederos + MIN_F) {
            flete = tarifa_perecederos + MIN_F;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + tarifa_F_05) * peso_final;
          if (flete < tarifa_perecederos + MIN_F) {
            flete = tarifa_perecederos + MIN_F;
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (tarifa_perecederos + tarifa_G_01) * peso_final;
          if (flete < tarifa_perecederos + MIN_G) {
            flete = tarifa_perecederos + MIN_G;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (tarifa_perecederos + tarifa_G_02) * peso_final;
          if (flete < tarifa_perecederos + MIN_G) {
            flete = tarifa_perecederos + MIN_G;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (tarifa_perecederos + tarifa_G_03) * peso_final;
          if (flete < tarifa_perecederos + MIN_G) {
            flete = tarifa_perecederos + MIN_G;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (tarifa_perecederos + tarifa_G_04) * peso_final;
          if (flete < tarifa_perecederos + MIN_G) {
            flete = tarifa_perecederos + MIN_G;
          }
        } else if (peso_final >= 500) {
          flete = (tarifa_perecederos + tarifa_G_05) * peso_final;
          if (flete < tarifa_perecederos + MIN_G) {
            flete = tarifa_perecederos + MIN_G;
          }
        }
      }
    }
  } else if (servicio === "Restos Humanos" || servicio === "Human Remains") {
    if (servicio_extra_humanos !== "") {
      if (servicio_extra_humanos === "Restos") {
        flete = peso_final * tarifa_restos_humanos;

        if (flete <= tarifa_restos_humanos_MIN) {
          flete = tarifa_restos_humanos_MIN;
        } else {
          flete = parseFloat(flete.toFixed(2));
        }
      } else {
        flete = tarifa_restos_cenizas;
        flete = parseFloat(flete.toFixed(2));
      }
    }
  } else if (servicio === "Animales Vivos" || servicio === "Live Animals") {
    if (servicio_extra_animales !== "") {
      if (servicio_extra_animales === "Perros y Gatos") {
        if (
          [
            "AGU",
            "BJX",
            "CME",
            "CPE",
            "CUN",
            "DGO",
            "GDL",
            "HMO",
            "LMM",
            "MZT",
            "MID",
            "MTY",
            "PVR",
            "SJD",
            "SLP",
            "TRC",
            "VER",
            "ZIH",
          ].includes(origen)
        ) {
          flete = peso_final * tarifa_animales_perros_especial;
          flete = parseFloat(flete.toFixed(2));

          if (flete <= tarifa_animales_perros_especial_MIN) {
            flete = tarifa_animales_perros_especial_MIN;
          }
        } else {
          flete = peso_final * tarifa_animales_perros_general;
          flete = parseFloat(flete.toFixed(2));

          if (flete <= tarifa_animales_perros_general_MIN) {
            flete = tarifa_animales_perros_general_MIN;
          }
        }
      } else if (servicio_extra_animales === "Gallos y aves de corral") {
        flete = peso_final * tarifa_animales_aves;
        flete = parseFloat(flete.toFixed(2));

        if (flete <= tarifa_animales_aves_MIN) {
          flete = tarifa_animales_aves_MIN;
        }
      } else if (
        servicio_extra_animales ===
          "Otros Animales (peces vivos, tortugas, ratones, etc.)" ||
        servicio_extra_animales === "Otros Animales (únicamente peces)"
      ) {
        flete = peso_final * tarifa_animales_otros;
        flete = parseFloat(flete.toFixed(2));

        if (flete <= tarifa_animales_otros_MIN) {
          flete = tarifa_animales_otros_MIN;
        }
      } else {
        flete = peso_final * tarifa_animales_otros;
        flete = parseFloat(flete.toFixed(2));

        if (flete <= tarifa_animales_otros_MIN) {
          flete = tarifa_animales_otros_MIN;
        }
      }
    }
  } else if (servicio === "Alto Valor" || servicio === "High Value") {
    let aumento;
    if (
      origen === "MEX" &&
      (destino === "LAP" ||
        destino === "CUL" ||
        destino === "MXL" ||
        destino === "CUU" ||
        destino === "TGZ")
    ) {
      if (destino === "LAP") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["1kg"]; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+45kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+100kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+300kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+500kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      } else if (destino === "CUL") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["1kg"]; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+45kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+100kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+300kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+500kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      } else if (destino === "MXL") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["1kg"]; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+45kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+100kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+300kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+500kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      } else if (destino === "CUU") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["1kg"]; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+45kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+100kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+300kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+500kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      } else if (destino === "TGZ") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["1kg"]; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+45kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+100kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+300kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+500kg"];
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] +
            tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"] * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      }
    } else if (
      origen === "CUL" ||
      origen === "CEN" ||
      origen === "LMM" ||
      origen === "AGU" ||
      origen === "SLP" ||
      origen === "ZIH" ||
      origen === "TAP" ||
      origen === "VSA" ||
      origen === "TGZ" ||
      origen === "CME" ||
      origen === "MTT" ||
      origen === "CPE"
    ) {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_A_01; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            TarifasB_MIN_A + TarifasB_MIN_A * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_A_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_A + TarifasB_MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_A_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_A + TarifasB_MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_A_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_A + TarifasB_MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_A_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_A + TarifasB_MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_B_01; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            tarifa_perecederos +
            TarifasB_MIN_B +
            TarifasB_MIN_B * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_B_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifa_perecederos +
            TarifasB_MIN_B +
            TarifasB_MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_B_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifa_perecederos +
            TarifasB_MIN_B +
            TarifasB_MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_B_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifa_perecederos +
            TarifasB_MIN_B +
            TarifasB_MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_B_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            tarifa_perecederos +
            TarifasB_MIN_B +
            TarifasB_MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_C_01; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            TarifasB_MIN_C + TarifasB_MIN_C * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_C_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_C + TarifasB_MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_C_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_C + TarifasB_MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_C_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_C + TarifasB_MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_C_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;
          let min_permitido =
            TarifasB_MIN_C + TarifasB_MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
          flete = flete.toFixed(2);
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_D_01; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            TarifasB_MIN_D + TarifasB_MIN_D * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_D_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_D + TarifasB_MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_D_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_D + TarifasB_MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_D_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_D + TarifasB_MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_D_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_D + TarifasB_MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_E_01; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular mínimo permitido con aumento del 150%
          let min_permitido =
            TarifasB_MIN_E + TarifasB_MIN_E * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_E_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_E + TarifasB_MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_E_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_E + TarifasB_MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_E_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_E + TarifasB_MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_E_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_E + TarifasB_MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_F_01; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular el mínimo permitido con aumento del 150%
          let min_permitido =
            TarifasB_MIN_F + TarifasB_MIN_F * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_F_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_F + TarifasB_MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_F_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_F + TarifasB_MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_F_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_F + TarifasB_MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_F_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_F + TarifasB_MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_G_01; // Cálculo base del flete
          aumento = flete * tarifa_AltoValor; // Aumento del 150%
          flete = flete + aumento; // Sumar aumento al flete

          // Calcular el mínimo permitido con aumento del 150%
          let min_permitido =
            TarifasB_MIN_G + TarifasB_MIN_G * tarifa_AltoValor;

          // Ajustar el flete si es menor al mínimo permitido
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          // Formatear valores en pesos mexicanos con dos decimales
          flete = flete.toFixed(2);
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_G_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_G + TarifasB_MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_G_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_G + TarifasB_MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_G_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_G + TarifasB_MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_G_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido =
            TarifasB_MIN_G + TarifasB_MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }

          flete = flete.toFixed(2);
        }
      }
    } else {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_A_01;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_A + MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_A_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_A + MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_A_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_A + MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_A_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_A + MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_A_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_A + MIN_A * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_B_01;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_B + MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_B_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_B + MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_B_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_B + MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_B_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_B + MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_B_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_B + MIN_B * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_C_01;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_C + MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_C_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_C + MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_C_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_C + MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_C_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_C + MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_C_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_C + MIN_C * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_D_01;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_D + MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_D_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_D + MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_D_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_D + MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_D_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_D + MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_D_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_D + MIN_D * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_E_01;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_E + MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_E_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_E + MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_E_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_E + MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_E_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_E + MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_E_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_E + MIN_E * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_F_01;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_F + MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_F_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_F + MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_F_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_F + MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_F_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_F + MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_F_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_F + MIN_F * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_G_01;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_G + MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_G_02;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_G + MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_G_03;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_G + MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_G_04;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_G + MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_G_05;
          aumento = flete * tarifa_AltoValor;
          flete = flete + aumento;

          let min_permitido = MIN_G + MIN_G * tarifa_AltoValor;
          if (flete < min_permitido) {
            flete = min_permitido;
          }
        }
      }
    }
  } else if (servicio === "Mensajería" || servicio === "Courier") {
    const tarifasMensajeria =
      tarifas.find((tarifa) => tarifa.service === "Mensajería") || [];
    flete = parseFloat(tarifasMensajeria.tarifas);
    flete = flete.toFixed(2);
    flete = parseFloat(flete);
  } else if (servicio === "Alta Tecnología" || servicio === "High Technology") {
    if (
      origen === "MEX" &&
      (destino === "LAP" ||
        destino === "CUL" ||
        destino === "MXL" ||
        destino === "CUU" ||
        destino === "TGZ")
    ) {
      if (destino === "LAP") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXLAP["1kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXLAP["+45kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXLAP["+100kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXLAP["+300kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXLAP["+500kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) *
                  100
              ) / 100;
          }
        }
      } else if (destino === "CUL") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUL["1kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUL["+45kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUL["+100kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUL["+300kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUL["+500kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) *
                  100
              ) / 100;
          }
        }
      } else if (destino === "MXL") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXMXL["1kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXMXL["+45kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXMXL["+100kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXMXL["+300kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXMXL["+500kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) *
                  100
              ) / 100;
          }
        }
      } else if (destino === "CUU") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUU["1kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUU["+45kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUU["+100kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUU["+300kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXCUU["+500kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) *
                  100
              ) / 100;
          }
        }
      } else if (destino === "TGZ") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXTGZ["1kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXTGZ["+45kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXTGZ["+100kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXTGZ["+300kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) *
                  100
              ) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil(
              (value_aditional_HIT +
                tarifario.tarifas.tarifaEspecial.MEXTGZ["+500kg"]) *
                peso_final *
                100
            ) / 100;
          if (
            flete <
            value_aditional_HIT + tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]
          ) {
            flete =
              Math.ceil(
                (value_aditional_HIT +
                  tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) *
                  100
              ) / 100;
          }
        }
      }
    } else if (
      origen === "CUL" ||
      origen === "CEN" ||
      origen === "LMM" ||
      origen === "AGU" ||
      origen === "SLP" ||
      origen === "ZIH" ||
      origen === "TAP" ||
      origen === "VSA" ||
      origen === "TGZ" ||
      origen === "CME" ||
      origen === "MTT" ||
      origen === "CPE"
    ) {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (value_aditional_HIT + table_B_tarifa_A_01) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_A) {
            flete = value_aditional_HIT + TarifasB_MIN_A;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (value_aditional_HIT + table_B_tarifa_A_02) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_A) {
            flete = value_aditional_HIT + TarifasB_MIN_A;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (value_aditional_HIT + table_B_tarifa_A_03) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_A) {
            flete = value_aditional_HIT + TarifasB_MIN_A;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (value_aditional_HIT + table_B_tarifa_A_04) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
            flete = flete + value_aditional_HIT;
          }
        } else if (peso_final >= 500) {
          flete = (value_aditional_HIT + table_B_tarifa_A_05) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
            flete = flete + value_aditional_HIT;
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (value_aditional_HIT + table_B_tarifa_B_01) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_B) {
            flete = value_aditional_HIT + TarifasB_MIN_B;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (value_aditional_HIT + table_B_tarifa_B_02) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_B) {
            flete = value_aditional_HIT + TarifasB_MIN_B;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (value_aditional_HIT + table_B_tarifa_B_03) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_B) {
            flete = value_aditional_HIT + TarifasB_MIN_B;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (value_aditional_HIT + table_B_tarifa_B_04) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_B) {
            flete = value_aditional_HIT + TarifasB_MIN_B;
          }
        } else if (peso_final >= 500) {
          flete = (value_aditional_HIT + table_B_tarifa_B_05) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_B) {
            flete = value_aditional_HIT + TarifasB_MIN_B;
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (value_aditional_HIT + table_B_tarifa_C_01) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_C) {
            flete = value_aditional_HIT + TarifasB_MIN_C;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (value_aditional_HIT + table_B_tarifa_C_02) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_C) {
            flete = value_aditional_HIT + TarifasB_MIN_C;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (value_aditional_HIT + table_B_tarifa_C_03) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_C) {
            flete = value_aditional_HIT + TarifasB_MIN_C;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (value_aditional_HIT + table_B_tarifa_C_04) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_C) {
            flete = value_aditional_HIT + TarifasB_MIN_C;
          }
        } else if (peso_final >= 500) {
          flete = (value_aditional_HIT + table_B_tarifa_C_05) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_C) {
            flete = value_aditional_HIT + TarifasB_MIN_C;
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (value_aditional_HIT + table_B_tarifa_D_01) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_D) {
            flete = value_aditional_HIT + TarifasB_MIN_D;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (value_aditional_HIT + table_B_tarifa_D_02) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_D) {
            flete = value_aditional_HIT + TarifasB_MIN_D;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (value_aditional_HIT + table_B_tarifa_D_03) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_D) {
            flete = value_aditional_HIT + TarifasB_MIN_D;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (value_aditional_HIT + table_B_tarifa_D_04) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_D) {
            flete = value_aditional_HIT + TarifasB_MIN_D;
          }
        } else if (peso_final >= 500) {
          flete = (value_aditional_HIT + table_B_tarifa_D_05) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_D) {
            flete = value_aditional_HIT + TarifasB_MIN_D;
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (value_aditional_HIT + table_B_tarifa_E_01) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_E) {
            flete = value_aditional_HIT + TarifasB_MIN_E;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (value_aditional_HIT + table_B_tarifa_E_02) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_E) {
            flete = value_aditional_HIT + TarifasB_MIN_E;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (value_aditional_HIT + table_B_tarifa_E_03) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_E) {
            flete = value_aditional_HIT + TarifasB_MIN_E;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (value_aditional_HIT + table_B_tarifa_E_04) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_E) {
            flete = value_aditional_HIT + TarifasB_MIN_E;
          }
        } else if (peso_final >= 500) {
          flete = (value_aditional_HIT + table_B_tarifa_E_05) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_E) {
            flete = value_aditional_HIT + TarifasB_MIN_E;
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (value_aditional_HIT + table_B_tarifa_F_01) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_F) {
            flete = value_aditional_HIT + TarifasB_MIN_F;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (value_aditional_HIT + table_B_tarifa_F_02) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_F) {
            flete = value_aditional_HIT + TarifasB_MIN_F;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (value_aditional_HIT + table_B_tarifa_F_03) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_F) {
            flete = value_aditional_HIT + TarifasB_MIN_F;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (value_aditional_HIT + table_B_tarifa_F_04) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_F) {
            flete = value_aditional_HIT + TarifasB_MIN_F;
          }
        } else if (peso_final >= 500) {
          flete = (value_aditional_HIT + table_B_tarifa_F_05) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_F) {
            flete = value_aditional_HIT + TarifasB_MIN_F;
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = (value_aditional_HIT + table_B_tarifa_G_01) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_G) {
            flete = value_aditional_HIT + TarifasB_MIN_G;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = (value_aditional_HIT + table_B_tarifa_G_02) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_G) {
            flete = value_aditional_HIT + TarifasB_MIN_G;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = (value_aditional_HIT + table_B_tarifa_G_03) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_G) {
            flete = value_aditional_HIT + TarifasB_MIN_G;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = (value_aditional_HIT + table_B_tarifa_G_04) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_G) {
            flete = value_aditional_HIT + TarifasB_MIN_G;
          }
        } else if (peso_final >= 500) {
          flete = (value_aditional_HIT + table_B_tarifa_G_05) * peso_final;
          if (flete < value_aditional_HIT + TarifasB_MIN_G) {
            flete = value_aditional_HIT + TarifasB_MIN_G;
          }
        }
      }
    } else {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_A_01) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_A) {
            flete = Math.ceil((value_aditional_HIT + MIN_A) * 100) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_A_02) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_A) {
            flete = Math.ceil((value_aditional_HIT + MIN_A) * 100) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_A_03) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_A) {
            flete = Math.ceil((value_aditional_HIT + MIN_A) * 100) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_A_04) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_A) {
            flete = Math.ceil((value_aditional_HIT + MIN_A) * 100) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_A_05) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_A) {
            flete = Math.ceil((value_aditional_HIT + MIN_A) * 100) / 100;
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_B_01) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_B) {
            flete = Math.ceil((value_aditional_HIT + MIN_B) * 100) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_B_02) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_B) {
            flete = Math.ceil((value_aditional_HIT + MIN_B) * 100) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_B_03) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_B) {
            flete = Math.ceil((value_aditional_HIT + MIN_B) * 100) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_B_04) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_B) {
            flete = Math.ceil((value_aditional_HIT + MIN_B) * 100) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_B_05) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_B) {
            flete = Math.ceil((value_aditional_HIT + MIN_B) * 100) / 100;
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_C_01) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_C) {
            flete = Math.ceil((value_aditional_HIT + MIN_C) * 100) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_C_02) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_C) {
            flete = Math.ceil((value_aditional_HIT + MIN_C) * 100) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_C_03) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_C) {
            flete = Math.ceil((value_aditional_HIT + MIN_C) * 100) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_C_04) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_C) {
            flete = Math.ceil((value_aditional_HIT + MIN_C) * 100) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_C_05) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_C) {
            flete = Math.ceil((value_aditional_HIT + MIN_C) * 100) / 100;
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_D_01) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_D) {
            flete = Math.ceil((value_aditional_HIT + MIN_D) * 100) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_D_02) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_D) {
            flete = Math.ceil((value_aditional_HIT + MIN_D) * 100) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_D_03) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_D) {
            flete = Math.ceil((value_aditional_HIT + MIN_D) * 100) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_D_04) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_D) {
            flete = Math.ceil((value_aditional_HIT + MIN_D) * 100) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_D_05) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_D) {
            flete = Math.ceil((value_aditional_HIT + MIN_D) * 100) / 100;
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_E_01) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_E) {
            flete = Math.ceil((value_aditional_HIT + MIN_E) * 100) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_E_02) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_E) {
            flete = Math.ceil((value_aditional_HIT + MIN_E) * 100) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_E_03) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_E) {
            flete = Math.ceil((value_aditional_HIT + MIN_E) * 100) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_E_04) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_E) {
            flete = Math.ceil((value_aditional_HIT + MIN_E) * 100) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_E_05) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_E) {
            flete = Math.ceil((value_aditional_HIT + MIN_E) * 100) / 100;
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_F_01) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_F) {
            flete = Math.ceil((value_aditional_HIT + MIN_F) * 100) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_F_02) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_F) {
            flete = Math.ceil((value_aditional_HIT + MIN_F) * 100) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_F_03) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_F) {
            flete = Math.ceil((value_aditional_HIT + MIN_F) * 100) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_F_04) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_F) {
            flete = Math.ceil((value_aditional_HIT + MIN_F) * 100) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_F_05) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_F) {
            flete = Math.ceil((value_aditional_HIT + MIN_F) * 100) / 100;
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_G_01) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_G) {
            flete = Math.ceil((value_aditional_HIT + MIN_G) * 100) / 100;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_G_02) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_G) {
            flete = Math.ceil((value_aditional_HIT + MIN_G) * 100) / 100;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_G_03) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_G) {
            flete = Math.ceil((value_aditional_HIT + MIN_G) * 100) / 100;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_G_04) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_G) {
            flete = Math.ceil((value_aditional_HIT + MIN_G) * 100) / 100;
          }
        } else if (peso_final >= 500) {
          flete =
            Math.ceil((value_aditional_HIT + tarifa_G_05) * peso_final * 100) /
            100;
          if (flete < value_aditional_HIT + MIN_G) {
            flete = Math.ceil((value_aditional_HIT + MIN_G) * 100) / 100;
          }
        }
      }
    }
  } else if (
    servicio === "Farma Especializado" ||
    servicio === "Health Chain Service"
  ) {
    flete = peso_final * tarifa_farma_especializado;

    if (flete <= tarifa_farma_especializado_MIN) {
      flete = tarifa_farma_especializado_MIN;
    } else {
      flete = parseFloat(flete.toFixed(2));
    }
  } else if (servicio === "Pharma STD") {
    if (origen === "MEX") {
      if (destino === "AGU") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXAGU["MIN"];
          }
        }
      } else if (destino === "BJX") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXBJX["MIN"];
          }
        }
      } else if (destino === "CEN") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCEN["MIN"];
          }
        }
      } else if (destino === "CJS") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCJS["MIN"];
          }
        }
      } else if (destino === "CME") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCME["MIN"];
          }
        }
      } else if (destino === "CPE") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCPE["MIN"];
          }
        }
      } else if (destino === "CUL") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        }
      } else if (destino === "CUN") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUN["MIN"];
          }
        }
      } else if (destino === "CUU") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        }
      } else if (destino === "DGO") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXDGO["MIN"];
          }
        }
      } else if (destino === "GDL") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXGDL["MIN"];
          }
        }
      } else if (destino === "HMO") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXHMO["MIN"];
          }
        }
      } else if (destino === "LAP") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        }
      } else if (destino === "LMM") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXLMM["MIN"];
          }
        }
      } else if (destino === "MID") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMID["MIN"];
          }
        }
      } else if (destino === "MTT") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTT["MIN"];
          }
        }
      } else if (destino === "MTY") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMTY["MIN"];
          }
        }
      } else if (destino === "MXL") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        }
      } else if (destino === "MZT") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXMZT["MIN"];
          }
        }
      } else if (destino === "OAX") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXOAX["MIN"];
          }
        }
      } else if (destino === "PBC") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPBC["MIN"];
          }
        }
      } else if (destino === "PVR") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXPVR["MIN"];
          }
        }
      } else if (destino === "QRO") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXQRO["MIN"];
          }
        }
      } else if (destino === "REX") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXREX["MIN"];
          }
        }
      } else if (destino === "SJD") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSJD["MIN"];
          }
        }
      } else if (destino === "SLP") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLP["MIN"];
          }
        }
      } else if (destino === "SLW") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXSLW["MIN"];
          }
        }
      } else if (destino === "TAM") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAM["MIN"];
          }
        }
      } else if (destino === "TAP") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTAP["MIN"];
          }
        }
      } else if (destino === "TGZ") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        }
      } else if (destino === "TIJ") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTIJ["MIN"];
          }
        }
      } else if (destino === "TRC") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXTRC["MIN"];
          }
        }
      } else if (destino === "VER") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVER["MIN"];
          }
        }
      } else if (destino === "VSA") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXVSA["MIN"];
          }
        }
      } else if (destino === "ZIH") {
        if (peso_final > 0 && peso_final < 45) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete =
            peso_final * tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final *
            tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tarifaEspecial.MEXZIH["MIN"];
          }
        }
      }
    } else if (
      origen === "CUL" ||
      origen === "CEN" ||
      origen === "LMM" ||
      origen === "AGU" ||
      origen === "SLP" ||
      origen === "ZIH" ||
      origen === "TAP" ||
      origen === "VSA" ||
      origen === "TGZ" ||
      origen === "CME" ||
      origen === "MTT" ||
      origen === "CPE"
    ) {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.A["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.A["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.A["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.A["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.A["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.A["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.A["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.A["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.A["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.A["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.B["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.B["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.B["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.B["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.B["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.B["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.B["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.B["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.B["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.B["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.C["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.C["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.C["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.C["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.C["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.C["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.C["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.C["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.C["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.C["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.D["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.D["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.D["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.D["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.D["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.D["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.D["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.D["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.D["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.D["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.E["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.E["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.E["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.E["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.E["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.E["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.E["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.E["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.E["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.E["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.F["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.F["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.F["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.F["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.F["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.F["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.F["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.F["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.F["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.F["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.G["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.G["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.G["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.G["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.G["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.G["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.G["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.G["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaA.G["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaA.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaA.G["MIN"];
          }
        }
      }
    } else {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.A["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.A["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.A["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.A["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.A["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.A["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.A["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.A["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.A["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.A["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.A["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.B["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.B["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.B["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.B["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.B["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.B["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.B["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.B["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.B["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.B["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.B["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.C["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.C["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.C["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.C["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.C["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.C["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.C["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.C["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.C["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.C["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.C["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.D["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.D["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.D["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.D["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.D["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.D["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.D["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.D["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.D["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.D["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.D["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.E["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.E["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.E["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.E["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.E["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.E["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.E["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.E["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.E["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.E["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.E["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.F["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.F["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.F["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.F["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.F["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.F["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.F["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.F["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.F["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.F["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.F["MIN"];
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.G["1kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.G["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.G["+45kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.G["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.G["+100kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.G["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.G["+300kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.G["MIN"];
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifaPharmaSTD.tarifas.tablaB.G["+500kg"];
          if (flete < tarifaPharmaSTD.tarifas.tablaB.G["MIN"]) {
            flete = tarifaPharmaSTD.tarifas.tablaB.G["MIN"];
          }
        }
      }
    }
  } else if (servicio === "Pharma XPS") {
    flete = tarifaPharmaXPS.tarifas.kg * peso_final;
    if (flete < tarifaPharmaXPS.tarifas.min) {
      flete = tarifaPharmaXPS.tarifas.min;
    }
  } else if (servicio === "12 + 12") {
    if (
      (origen === "MEX" ||
        origen === "MEX1" ||
        origen === "MEX2" ||
        origen === "MEX3") &&
      (destino === "LAP" ||
        destino === "CUL" ||
        destino === "MXL" ||
        destino === "CUU" ||
        destino === "TGZ")
    ) {
      if (destino === "LAP") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["1kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+45kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+100kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+300kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXLAP["+500kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXLAP["MIN"];
          }
        }
      } else if (destino === "CUL") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["1kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+45kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+100kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+300kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUL["+500kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUL["MIN"];
          }
        }
      } else if (destino === "MXL") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["1kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+45kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+100kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+300kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXMXL["+500kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXMXL["MIN"];
          }
        }
      } else if (destino === "CUU") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["1kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+45kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+100kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+300kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXCUU["+500kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXCUU["MIN"];
          }
        }
      } else if (destino === "TGZ") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["1kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+45kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+100kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+300kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        } else if (peso_final >= 500) {
          flete =
            peso_final * tarifario.tarifas.tarifaEspecial.MEXTGZ["+500kg"];
          if (flete < tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"]) {
            flete = tarifario.tarifas.tarifaEspecial.MEXTGZ["MIN"];
          }
        }
      }
    } else if (
      origen === "CUL" ||
      origen === "CEN" ||
      origen === "LMM" ||
      origen === "AGU" ||
      origen === "SLP" ||
      origen === "ZIH" ||
      origen === "TAP" ||
      origen === "VSA" ||
      origen === "TGZ" ||
      origen === "CME" ||
      origen === "MTT" ||
      origen === "CPE"
    ) {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_A_01;
          if (flete < TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_A_02;
          if (flete < TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_A_03;
          if (flete < TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_A_04;
          if (flete < TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_A_05;
          if (flete < TarifasB_MIN_A) {
            flete = TarifasB_MIN_A;
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_B_01;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = TarifasB_MIN_B;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_B_02;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = TarifasB_MIN_B;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_B_03;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = TarifasB_MIN_B;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_B_04;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = TarifasB_MIN_B;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_B_05;
          if (flete < tarifa_perecederos + TarifasB_MIN_B) {
            flete = TarifasB_MIN_B;
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_C_01;
          if (flete < TarifasB_MIN_C) {
            flete = TarifasB_MIN_C;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_C_02;
          if (flete < TarifasB_MIN_C) {
            flete = TarifasB_MIN_C;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_C_03;
          if (flete < TarifasB_MIN_C) {
            flete = TarifasB_MIN_C;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_C_04;
          if (flete < TarifasB_MIN_C) {
            flete = TarifasB_MIN_C;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_C_05;
          if (flete < TarifasB_MIN_C) {
            flete = TarifasB_MIN_C;
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_D_01;
          if (flete < TarifasB_MIN_D) {
            flete = TarifasB_MIN_D;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_D_02;
          if (flete < TarifasB_MIN_D) {
            flete = TarifasB_MIN_D;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_D_03;
          if (flete < TarifasB_MIN_D) {
            flete = TarifasB_MIN_D;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_D_04;
          if (flete < TarifasB_MIN_D) {
            flete = TarifasB_MIN_D;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_D_05;
          if (flete < TarifasB_MIN_D) {
            flete = TarifasB_MIN_D;
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_E_01;
          if (flete < TarifasB_MIN_E) {
            flete = TarifasB_MIN_E;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_E_02;
          if (flete < TarifasB_MIN_E) {
            flete = TarifasB_MIN_E;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_E_03;
          if (flete < TarifasB_MIN_E) {
            flete = TarifasB_MIN_E;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_E_04;
          if (flete < TarifasB_MIN_E) {
            flete = TarifasB_MIN_E;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_E_05;
          if (flete < TarifasB_MIN_E) {
            flete = TarifasB_MIN_E;
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_F_01;
          if (flete < TarifasB_MIN_F) {
            flete = TarifasB_MIN_F;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_F_02;
          if (flete < TarifasB_MIN_F) {
            flete = TarifasB_MIN_F;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_F_03;
          if (flete < TarifasB_MIN_F) {
            flete = TarifasB_MIN_F;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_F_04;
          if (flete < TarifasB_MIN_F) {
            flete = TarifasB_MIN_F;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_F_05;
          if (flete < TarifasB_MIN_F) {
            flete = TarifasB_MIN_F;
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * table_B_tarifa_G_01;
          if (flete < TarifasB_MIN_G) {
            flete = TarifasB_MIN_G;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * table_B_tarifa_G_02;
          if (flete < TarifasB_MIN_G) {
            flete = TarifasB_MIN_G;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * table_B_tarifa_G_03;
          if (flete < TarifasB_MIN_G) {
            flete = TarifasB_MIN_G;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * table_B_tarifa_G_04;
          if (flete < TarifasB_MIN_G) {
            flete = TarifasB_MIN_G;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * table_B_tarifa_G_05;
          if (flete < TarifasB_MIN_G) {
            flete = TarifasB_MIN_G;
          }
        }
      }
    } else {
      if (encontrarZona(origen, destino) === "A") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_A_01;
          if (flete < MIN_A) {
            flete = MIN_A;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_A_02;
          if (flete < MIN_A) {
            flete = MIN_A;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_A_03;
          if (flete < MIN_A) {
            flete = MIN_A;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_A_04;
          if (flete < MIN_A) {
            flete = MIN_A;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_A_05;
          if (flete < MIN_A) {
            flete = MIN_A;
          }
        }
      } else if (encontrarZona(origen, destino) === "B") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_B_01;
          if (flete < MIN_B) {
            flete = MIN_B;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_B_02;
          if (flete < MIN_B) {
            flete = MIN_B;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_B_03;
          if (flete < MIN_B) {
            flete = MIN_B;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_B_04;
          if (flete < MIN_B) {
            flete = MIN_B;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_B_05;
          if (flete < MIN_B) {
            flete = MIN_B;
          }
        }
      } else if (encontrarZona(origen, destino) === "C") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_C_01;
          if (flete < MIN_C) {
            flete = MIN_C;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_C_02;
          if (flete < MIN_C) {
            flete = MIN_C;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_C_03;
          if (flete < MIN_C) {
            flete = MIN_C;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_C_04;
          if (flete < MIN_C) {
            flete = MIN_C;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_C_05;
          if (flete < MIN_C) {
            flete = MIN_C;
          }
        }
      } else if (encontrarZona(origen, destino) === "D") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_D_01;
          if (flete < MIN_D) {
            flete = MIN_D;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_D_02;
          if (flete < MIN_D) {
            flete = MIN_D;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_D_03;
          if (flete < MIN_D) {
            flete = MIN_D;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_D_04;
          if (flete < MIN_D) {
            flete = MIN_D;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_D_05;
          if (flete < MIN_D) {
            flete = MIN_D;
          }
        }
      } else if (encontrarZona(origen, destino) === "E") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_E_01;
          if (flete < MIN_E) {
            flete = MIN_E;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_E_02;
          if (flete < MIN_E) {
            flete = MIN_E;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_E_03;
          if (flete < MIN_E) {
            flete = MIN_E;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_E_04;
          if (flete < MIN_E) {
            flete = MIN_E;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_E_05;
          if (flete < MIN_E) {
            flete = MIN_E;
          }
        }
      } else if (encontrarZona(origen, destino) === "F") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_F_01;
          if (flete < MIN_F) {
            flete = MIN_F;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_F_02;
          if (flete < MIN_F) {
            flete = MIN_F;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_F_03;
          if (flete < MIN_F) {
            flete = MIN_F;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_F_04;
          if (flete < MIN_F) {
            flete = MIN_F;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_F_05;
          if (flete < MIN_F) {
            flete = MIN_F;
          }
        }
      } else if (encontrarZona(origen, destino) === "G") {
        if (peso_final > 0 && peso_final < 45) {
          flete = peso_final * tarifa_G_01;
          if (flete < MIN_G) {
            flete = MIN_G;
          }
        } else if (peso_final >= 45 && peso_final < 100) {
          flete = peso_final * tarifa_G_02;
          if (flete < MIN_G) {
            flete = MIN_G;
          }
        } else if (peso_final >= 100 && peso_final < 300) {
          flete = peso_final * tarifa_G_03;
          if (flete < MIN_G) {
            flete = MIN_G;
          }
        } else if (peso_final >= 300 && peso_final < 500) {
          flete = peso_final * tarifa_G_04;
          if (flete < MIN_G) {
            flete = MIN_G;
          }
        } else if (peso_final >= 500) {
          flete = peso_final * tarifa_G_05;
          if (flete < MIN_G) {
            flete = MIN_G;
          }
        }
      }
    }
  } else {
    flete = peso_final * tarifa;
    flete = parseFloat(flete.toFixed(2));
  }

  return flete;
}

function calculateAcarreo(peso, origen) {
  const tarifas = getConvertedRates();
  const tarifaAcarreo =
    tarifas.find((tarifa) => tarifa.service === "Cargo por acarreo (PU)") || [];

  const cargoFijo = tarifaAcarreo.tarifas.cargoFijo;
  const acarreoKg = tarifaAcarreo.tarifas.kg;
  let acarreo;

  if (origen === "MEX1" || origen === "MEX2") {
    acarreo = peso * acarreoKg + cargoFijo;
  } else {
    acarreo = 0;
  }

  return acarreo;
}

function calculateIVA(subtotal, iva) {
  let subtotalIVA = subtotal * (iva / 100);
  subtotalIVA = Math.round(subtotalIVA * 100) / 100; // Redondear a 2 decimales

  return subtotalIVA;
}

function getIVA01(
  servicio,
  codigoServicio,
  flete,
  pago,
  valorDeclarado,
  ocurreOficina,
  cargoFijo,
  cargoCombustible,
  cargoAcarreo,
  operacionTerrestre,
  otrosCargos,
  cargoPorCertificacion
) {
  let cargoPorCertificacionIVA = 0.0;
  // Cargo por Certificación (FB)
  if (
    codigoServicio === "HCS" ||
    codigoServicio === "FAU" ||
    codigoServicio === "FAD"
  ) {
    cargoPorCertificacionIVA = calculateIVA(cargoPorCertificacion, 16);
  } else {
    cargoPorCertificacion = 0.0;
    cargoPorCertificacionIVA = 0.0;
  }

  // Cargo por Combustible (MY)
  let pagoCC = parseFloat(calcularPagoCC(pago, flete));

  // Calculate Subtotal
  let subtotal = calcularSubtotal(
    flete,
    cargoFijo,
    cargoCombustible,
    valorDeclarado,
    otrosCargos,
    pagoCC,
    operacionTerrestre,
    cargoAcarreo,
    cargoPorCertificacion
  );

  /*
      ==============================================
        💵 SUBTOTAL IVA
        No Franja  Fronteriza  ––> Franja Fronteriza Norte
      ==============================================
      1.- FLETE ––> 								4%
      2.- AL COBRO (CC) ––> 					16%
      3.- VALUACION (VA) ––> 				16%
      4.- OCURRE OFICINA (SA) ––>			16%
      5.-Cargo Fijo (AW) ––> 					16%
      6.- Cargo por Combustible (MY) ––> 	16%
      7.- ACARREO (PU) ––> 					16%
      8.- Cargo por Certificación (FB) ––> 	16%
      9.- Operación Terrestre (MC) ––> 		16%
      10.- Otros Cargos ––>						16%
    */
  let fleteIVA = calculateIVA(flete, 4);
  let pagoCCIVA = calculateIVA(pagoCC, 16);
  let valorDeclaradoIVA = calculateIVA(valorDeclarado, 16);
  let ocurreOficinaIVA = calculateIVA(ocurreOficina, 16);
  let cargoFijoIVA = calculateIVA(cargoFijo, 16);
  let cargoCombustibleIVA = calculateIVA(cargoCombustible, 16);
  let cargoAcarreoIVA = calculateIVA(cargoAcarreo, 16);
  let operacionTerrestreIVA = calculateIVA(operacionTerrestre, 16);
  let otrosCargosIVA = calculateIVA(otrosCargos, 16);

  // Sumando Subtotal IVA
  let subtotalIVA =
    fleteIVA +
    pagoCCIVA +
    valorDeclaradoIVA +
    ocurreOficinaIVA +
    cargoFijoIVA +
    cargoCombustibleIVA +
    cargoAcarreoIVA +
    operacionTerrestreIVA +
    cargoPorCertificacionIVA +
    otrosCargosIVA;

  // Total del Flete
  let total = calcularTotal(subtotal, subtotalIVA);

  return [pagoCC, subtotal, subtotalIVA, total];
}

function getIVA02(
  servicio,
  codigoServicio,
  flete,
  pago,
  valorDeclarado,
  ocurreOficina,
  cargoFijo,
  cargoCombustible,
  cargoAcarreo,
  operacionTerrestre,
  otrosCargos,
  cargoPorCertificacion
) {
  let cargoPorCertificacionIVA = 0.0;
  // Cargo por Certificación (FB)
  if (
    codigoServicio === "HCS" ||
    codigoServicio === "FAU" ||
    codigoServicio === "FAD"
  ) {
    cargoPorCertificacionIVA = calculateIVA(cargoPorCertificacion, 16);
  } else {
    cargoPorCertificacion = 0.0;
    cargoPorCertificacionIVA = 0.0;
  }

  // Cargo por Combustible (MY)
  let pagoCC = calcularPagoCC(pago, flete);

  // Calculate Subtotal
  let subtotal = calcularSubtotal(
    flete,
    cargoFijo,
    cargoCombustible,
    valorDeclarado,
    otrosCargos,
    pagoCC,
    operacionTerrestre,
    cargoAcarreo,
    cargoPorCertificacion
  );

  /*
    ==============================================
      💵 SUBTOTAL IVA
      No Franja  Fronteriza  ––> Franja Fronteriza Norte
    ==============================================
    1.- FLETE ––> 								16%
    2.- AL COBRO (CC) ––> 					16%
    3.- VALUACION (VA) ––> 				16%
    4.- OCURRE OFICINA (SA) ––>			16%
    5.-Cargo Fijo (AW) ––> 					16%
    6.- Cargo por Combustible (MY) ––> 	16%
    7.- ACARREO (PU) ––> 					16%
    8.- Cargo por Certificación (FB) ––> 	16%
    9.- Operación Terrestre (MC) ––> 		16%
    10.- Otros Cargos ––>						16%
  */
  let fleteIVA = calculateIVA(flete, 16);
  let pagoCCIVA = calculateIVA(pagoCC, 16);
  let valorDeclaradoIVA = calculateIVA(valorDeclarado, 16);
  let ocurreOficinaIVA = calculateIVA(ocurreOficina, 16);
  let cargoFijoIVA = calculateIVA(cargoFijo, 16);
  let cargoCombustibleIVA = calculateIVA(cargoCombustible, 16);
  let cargoAcarreoIVA = calculateIVA(cargoAcarreo, 16);
  let operacionTerrestreIVA = calculateIVA(operacionTerrestre, 16);
  let otrosCargosIVA = calculateIVA(otrosCargos, 16);

  // Sumando Subtotal IVA
  let subtotalIVA =
    fleteIVA +
    pagoCCIVA +
    valorDeclaradoIVA +
    ocurreOficinaIVA +
    cargoFijoIVA +
    cargoCombustibleIVA +
    cargoAcarreoIVA +
    operacionTerrestreIVA +
    cargoPorCertificacionIVA +
    otrosCargosIVA;

  // Total del Flete
  let total = calcularTotal(subtotal, subtotalIVA);

  return [pagoCC, subtotal, subtotalIVA, total];
}

export const msjService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_mensajeria,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_mensajeria,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        tarifa_mensajeria,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(0.0),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          tarifa_mensajeria,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const goldService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_Gold,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_Gold,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        tarifa_Gold,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(0.0),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          tarifa_Gold,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const animalesVivosService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_animales_perros_general,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_animales_perros_general,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(0.0),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          tarifa_animales_perros_general,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const dmdService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_animales_perros_general,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_animales_perros_general,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(0.0),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          tarifa_animales_perros_general,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const hivService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_animales_perros_general,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(0.0),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          0.0,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const prsService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_perecederos,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        tarifa_perecederos,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        tarifa_perecederos,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(0.0),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          tarifa_perecederos,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const hitService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        value_aditional_HIT,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        value_aditional_HIT,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      0.0
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        value_aditional_HIT,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(0.0),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          value_aditional_HIT,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const stdService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      calcularCertificacionFB()
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      calcularCertificacionFB()
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(calcularCertificacionFB()),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          value_aditional_HIT,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};

export const xspService = (
  servicio,
  cod_service,
  servicio_extra_animales,
  servicio_extra_humanos,
  origen,
  destino,
  peso,
  vd,
  pago,
  domicilio_cp
) => {
  let iva;

  if (
    destino === "TAP" ||
    destino === "REX" ||
    destino === "MXL" ||
    destino === "CJS" ||
    destino === "TIJ"
  ) {
    iva = getIVA01(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      calcularCertificacionFB()
    );
  } else {
    iva = getIVA02(
      servicio,
      cod_service,
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      ),
      pago,
      calcularValuacion(vd, peso),
      0.0,
      calcularCargoFijo(),
      calcularCargoCombustible(peso),
      calculateAcarreo(peso, origen),
      calcularOperacionTerrestre(origen, destino, peso),
      calcularCargosAdicionales(peso, destino, domicilio_cp),
      calcularCertificacionFB()
    );
  }

  const prices = {
    flete: formatValue(
      calculateFlete(
        servicio,
        peso,
        0.0,
        servicio_extra_animales,
        servicio_extra_humanos,
        origen,
        destino
      )
    ),
    cargoFijo: formatValue(calcularCargoFijo()),
    combistible: formatValue(calcularCargoCombustible(peso)),
    certificationFee: formatValue(calcularCertificacionFB()),
    valoracion: formatValue(calcularValuacion(vd, peso)),
    otrosCargos: formatValue(
      calcularCargosAdicionales(peso, destino, domicilio_cp)
    ),
    operacionTerrestre: formatValue(
      calcularOperacionTerrestre(origen, destino, peso)
    ),
    pagoCC: formatValue(
      calcularPagoCC(
        pago,
        calculateFlete(
          servicio,
          peso,
          value_aditional_HIT,
          servicio_extra_animales,
          servicio_extra_humanos,
          origen,
          destino
        )
      )
    ),
    acarreo: formatValue(calculateAcarreo(peso, origen)),
    subTotal: formatValue(iva[1]),
    IVA: formatValue(iva[2]),
    total: formatValue(iva[3]),
  };

  return prices;
};
