import React, { useState, useEffect } from "react";
import "./style.css";

import CollectionHeader from "../../components/header/CollectionHeader";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import Swal from "sweetalert2";

const Coordinator = () => {
  const [shipments, setShipments] = useState([]);
  const [status, setStatus] = useState({});
  const [formData, setFormData] = useState({
    startDate: "",
    startHour: "",
    endDate: "",
    endHour: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setFormData({
      startDate: "",
      startHour: "",
      endDate: "",
      endHour: "",
    });
  };

  const getData = async () => {
    // Verificar que todos los campos estén llenos
    const { startDate, startHour, endDate, endHour } = formData;
    if (!startDate || !startHour || !endDate || !endHour) {
      Swal.fire({
        title: "Todos los campos deben estar llenos para filtrar los datos.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ok",
      });
      return;
    }

    // Validar que startDate no sea menor que endDate
    const startDateTime = new Date(`${startDate}T${startHour}`);
    const endDateTime = new Date(`${endDate}T${endHour}`);

    if (startDateTime > endDateTime) {
      Swal.fire({
        title: "La fecha de inicio no puede ser mayor que la fecha de fin.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ok",
      });
      return;
    }

    const formatDate = (date) => {
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    };

    // Función para formatear hora en HH:mm (24 horas)
    const formatTime = (time) => {
      const [hours, minutes] = time.split(":");
      return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
    };

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/coordinator`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
          params: {
            startDate: formatDate(startDate),
            finishDate: formatDate(endDate),
            startTime: formatTime(startHour),
            finishTime: formatTime(endHour),
          },
        }
      );

      if (res.data.code === 1) {
        setShipments(res.data.operations);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getDataStatus = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/coordinator/turns_status`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        setStatus(res.data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getStatusTextSerching = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "Buscando";
      case 2:
        return "Encontrado";
      case 3:
        return "Encontrado Parcial";
      case 4:
        return "No Encontrado";
      default:
        return "--";
    }
  };

  const getStatusClassSerching = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "searching";
      case 2:
        return "found";
      case 3:
        return "found-partial";
      case 4:
        return "not-found";
      default:
        return "null";
    }
  };

  const getStatusTextCounter = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "Aceptado";
      case 0:
        return "No aceptado";
      default:
        return "--";
    }
  };

  const getStatusClassCounter = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "accepted";
      case 0:
        return "not-accepted";
      default:
        return "null";
    }
  };

  const setStatusExit = (status) => {
    switch (status) {
      case 1:
        return "Salida"
      case 2:
        return "Regreso"
      case 3:
        return "Pendiente"
      default:
        return "--"
    }
  }

  const getStatusExitStyle = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "accepted"
      case 2:
        return "not-found"
      case 3:
        return "found-partial"
      default:
        return "null"
    }
  };

  useEffect(() => {
    getDataStatus();

    const interval = setInterval(getDataStatus, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="collection-coordinator">
        <CollectionHeader />
        <div className="collection-coordinator-body">
          <div className="return-container">
            <button type="button" className="return-btn">
              <IoIosArrowBack className="arrow-btn" />
            </button>
          </div>

          <h4>Inspección</h4>

          <div className="waiting-shifts">
            <div className="shifts">
              <div className="title">Turnos Pendientes</div>
              <div className="number-of-turns">
                <span>{status?.turns_await}</span>
              </div>
            </div>
            <div className="shifts">
              <div className="title">Buscando Turnos</div>
              <div className="number-of-turns">
                <span>{status?.turns_search}</span>
              </div>
            </div>
            <div className="shifts">
              <div className="title">Turnos en recolección</div>
              <div className="number-of-turns">
                <span>{status?.truns_in_recolection}</span>
              </div>
            </div>
            <div className="shifts">
              <div className="title">Turnos en espera de salida</div>
              <div className="number-of-turns">
                <span>{status?.turns_await_departure}</span>
              </div>
            </div>
            <div className="shifts">
              <div className="title">Turnos finalizados</div>
              <div className="number-of-turns">
                <span>{status?.turn_finish}</span>
              </div>
            </div>
            <div className="shifts">
              <div className="title">Guías no encontradas</div>
              <div className="number-of-turns">
                <span>{status?.awb_not_find}</span>
              </div>
            </div>
            <div className="shifts">
              <div className="title">Guías retornadas</div>
              <div className="number-of-turns">
                <span>{status?.abws_returned}</span>
              </div>
            </div>
          </div>

          <div className="filter-container">
            <div className="inputs-container">
              <div className="filter-input">
                <input
                  className="date-box"
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="filter-input">
                <input
                  className="date-box"
                  type="time"
                  name="startHour"
                  id="startHour"
                  value={formData.startHour}
                  onChange={handleInputChange}
                />
              </div>
              <div className="filter-input">
                <input
                  className="date-box"
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleInputChange}
                />
              </div>
              <div className="filter-input">
                <input
                  className="date-box"
                  type="time"
                  name="endHour"
                  id="endHour"
                  value={formData.endHour}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="buttons-container">
              <div className="filter-button">
                <button className="btn-filtrar" type="button" onClick={getData}>
                  {" "}
                  Aplicar filtros
                </button>
              </div>
              <div className="filter-button">
                <button
                  type="button"
                  className="btn-delete-filtrar"
                  onClick={handleCancel}
                >
                  Borrar filtros
                </button>
              </div>
            </div>
          </div>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th># Turno</th>
                  <th># de guías/Cliente</th>
                  <th>Hora turno Generado</th>
                  <th>Hora turno buscando</th>
                  <th>Tiempo de espera buscando</th>
                  <th>Buscador asignado</th>
                  <th>Estatus Buscador</th>
                  <th>Hora turno encontrado/no encontrado</th>
                  <th>tiempo de busqueda</th>
                  <th>Hora turno mostrador</th>
                  <th>Mostrador asignado</th>
                  <th>Estatus mostrador</th>
                  <th>Hora finalización mostrador</th>
                  <th>Tiempo espera mostrador</th>
                  <th>Tiempo en el mostrador</th>
                  <th>Hora de salida</th>
                  <th>Estatus salida</th>
                  <th>Personal salida</th>
                </tr>
              </thead>

              <tbody>
                {shipments.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.turn_number ? row?.turn_number : "-"}</td>
                    <td>
                      {row?.awbs ? row?.awbs : ""}
                      {row?.customer ? row?.customer : ""}
                    </td>
                    <td>
                      {row?.turn_created_at ? row?.turn_created_at : "--"}
                    </td>
                    <td>
                      {row?.turn_searched_at ? row?.turn_searched_at : "--"}
                    </td>
                    <td>
                      {row?.waiting_time_search
                        ? row?.waiting_time_search
                        : "--"}
                    </td>
                    <td>{row?.employee_name ? row?.employee_name : "--"}</td>
                    {/* estatus buscador */}
                    <td>
                      <span
                        className={`status ${getStatusClassSerching(
                          row?.search_status
                        )}`}
                      >
                        {row?.search_status
                          ? getStatusTextSerching(row?.search_status)
                          : "-"}
                      </span>
                    </td>
                    <td>
                      {row?.turn_found_or_not_found_at
                        ? row?.turn_found_or_not_found_at
                        : "--"}
                    </td>
                    <td>{row?.time_search ? row?.time_search : "--"}</td>
                    <td>
                      {row?.turn_counter_at ? row?.turn_counter_at : "--"}
                    </td>
                    <td>
                      {row?.counter_checkout_name
                        ? row?.counter_checkout_name
                        : "--"}
                    </td>
                    {/* Estatus mostrador */}
                    <td>
                      <span
                        className={`status ${getStatusClassCounter(
                          row?.counter_status
                        )}`}
                      >
                        {row?.counter_status
                          ? getStatusTextCounter(row?.counter_status)
                          : "-"}
                      </span>
                    </td>
                    <td>
                      {row?.finish_counter_at ? row?.finish_counter_at : "--"}
                    </td>
                    <td>
                      {row?.waiting_time_counter
                        ? row?.waiting_time_counter
                        : "--"}
                    </td>
                    <td>{row?.time_counter ? row?.time_counter : "--"}</td>
                    <td>{row?.departured_at ? row?.departured_at : "--"}</td>
                    <td>
                      <span
                        className={`status ${getStatusExitStyle(row.departure_status)}`}
                      >
                        {row?.departure_status ? setStatusExit(row.departure_status) : "--"}
                      </span>
                    </td>
                    <td>{row?.user_name ? row?.user_name : "--"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coordinator;
