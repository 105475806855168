import React, { useState, useEffect } from "react";
import "./style.css";

import CollectionHeader from "../../components/header/CollectionHeader";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";

const Inspector = () => {
  const [shipments, setShipments] = useState([]);

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/guard`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );
      console.log("====================================");
      console.log(res.data.collection);
      console.log("====================================");
      if (res.data.code === 1) {
        setShipments(res.data.collection);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getStatusTextSerching = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "Buscando";
      case 2:
        return "Encontrado";
      case 3:
        return "Encontrado Parcial";
      case 4:
        return "No Encontrado";
      default:
        return "--";
    }
  };

  const getStatusClassSerching = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "searching";
      case 2:
        return "found";
      case 3:
        return "found-partial";
      case 4:
        return "not-found";
      default:
        return "null";
    }
  };

  const getStatusTextCounter = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "Aceptado";
      case 0:
        return "No aceptado";
      default:
        return "--";
    }
  };

  const getStatusClassCounter = (status) => {
    switch (parseInt(status)) {
      case 1:
        return "accepted";
      case 0:
        return "not-accepted";
      default:
        return "null";
    }
  };

  function formatHour(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");

    const isoFormat = `${year}-${month}-${day}T${timePart}`;

    const date = new Date(isoFormat);
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    getData();

    const interval = setInterval(getData, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="collection-inpector">
        <CollectionHeader />
        <div className="collection-inpector-body">
          <div className="return-container">
            <button type="button" className="return-btn">
              <IoIosArrowBack className="arrow-btn" />
            </button>
          </div>

          <h4>Inspección</h4>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th># Turno</th>
                  <th>Nombre Buscador</th>
                  <th>Hora emisión</th>
                  <th># Guías/Cliente</th>
                  <th>Estatus Busqueda</th>
                  <th>Estatus Busqueda Mostrador</th>
                </tr>
              </thead>

              <tbody>
                {shipments.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.turn_number ? row?.turn_number : "-"}</td>
                    <td>{row?.employee_name ? row?.employee_name : "-"}</td>
                    <td>
                      {row?.created_at ? formatHour(row?.created_at) : "-"}
                    </td>
                    <td>
                      {row?.awbs ? row?.awbs : ""}
                      {row?.customer_name ? row?.customer_name : ""}
                    </td>
                    <td>
                      <span
                        className={`status ${getStatusClassSerching(
                          row?.status
                        )}`}
                      >
                        {row?.status ? getStatusTextSerching(row?.status) : "-"}
                      </span>
                    </td>
                    <td>
                      <span
                        className={`status ${getStatusClassCounter(
                          row?.checkout_status
                        )}`}
                      >
                        {row?.checkout_status
                          ? getStatusTextCounter(row?.checkout_status)
                          : "-"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Inspector;
