import { useEffect, useState } from "react";
import "./shifts.css";
import { useForm } from "react-hook-form";
import ShiftsLightLoad from "./light-load/shift-light-load";
import ShiftsHeavyLoad from "./heavy-load/shifts-heavy-load";
import ShiftsCollection from "./collection/shifts-collection";

function Shifts() {
  const [currentTab, setCurrentTab] = useState("");

  useEffect(() => {
    getPermissions();
  }, []);

  const getPermissions = () => {
    if(localStorage.getItem('permissions').includes('27')) {
      setCurrentTab("27");
    } else if (localStorage.getItem('permissions').includes('36')) {
      setCurrentTab("36");
    } else if (localStorage.getItem('permissions').includes('37')) {
      setCurrentTab("37")
    } else {
      setCurrentTab("");
    }
  }

  const changeTab = (tab) => {
    if(tab === currentTab) {
      return;
    }
    setCurrentTab(tab);
  }

  return (
    <>
      <div className="shifts-container">

        <div className="nav-tabs-container">
          {localStorage.getItem('permissions').includes('27') && (
            <button 
              className={`btn-tab ${currentTab === "27" ? 'active' : ""}`}
              onClick={() => changeTab("27")}
            >
              Carga Ligera
            </button>
          )}
          {localStorage.getItem('permissions').includes('36') && (
            <button 
              className={`btn-tab ${currentTab === "36" ? 'active' : ""}`}
              onClick={() => changeTab("36")}
            >
              Carga Pesada
            </button>
          )}
          {localStorage.getItem('permissions').includes('37') && (
            <button 
              className={`btn-tab ${currentTab === "37" ? 'active' : ""}`}
              onClick={() => changeTab("37")}
            >
              Recoleccion
            </button>
          )}
        </div>

        {currentTab === "27" && <ShiftsLightLoad />}
        {currentTab === "36" && <ShiftsHeavyLoad />}
        {currentTab === "37" && <ShiftsCollection />}

      </div>
    </>
  );
}

export default Shifts;
