import React, { useState, useEffect, useRef } from "react";
import "./weighing-control.css";
import logoAmc from "../../../../../assets/imgs/navbar/logo_AMC.svg";
import axios from "axios";

function WeighingControl() {
  const [shipments, setShipments] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 1 && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [hasMore]);

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/guard?page=${page}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        if(res.data.shipments.length > 0) {
          setShipments((prevData) => [...prevData, ...res.data.shipments]);

        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Etiqueta emitida";
      case 2:
        return "Pesaje";
      case 3:
        return "Documentando";
      case 4:
        return "finalizado";
      case 5:
        return "Cancelado Pesador";
      case 6:
        return "Cancelado Mostrador";
      default:
        return "";
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "validated";
      case 2:
        return "in-process";
      case 3:
        return "documenting";
      case 4:
        return "approved";
      case 5:
        return "cancel";
      case 6:
        return "cancel";
      default:
        return "";
    }
  };

  function formatHour(dateString) {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");

    const isoFormat = `${year}-${month}-${day}T${timePart}`;

    const date = new Date(isoFormat);
    if (isNaN(date.getTime())) {
      return "Fecha inválida";
    }

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  useEffect(() => {
    const fetchDashboardAndLogDates = async () => {
      await getData();
    };

    fetchDashboardAndLogDates(); 
  }, [page]);

  // useEffect(() => {
  //   getData();

  //   const interval = setInterval(getData, 15000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <>
      <div className="header-weighing">
        <img className="logo-amc" src={logoAmc} alt="amc-logo" />
      </div>

      <div className="body-weighing">
        <h4>Inspección</h4>

        <div className="table-container" ref={containerRef}>
          <table>
            <thead>
              <tr>
                <th># Turno</th>
                <th># Embarque</th>
                <th>Hora emisión</th>
                <th># Guía</th>
                <th>Descripción</th>
                <th>Destino</th>
                <th>Piezas</th>
                <th>Estatus</th>
              </tr>
            </thead>

            <tbody>
              {shipments.map((row, index) => (
                <tr key={index}>
                  <td>{row.turn_number ? row.turn_number : "-"}</td>
                  <td>{row.tag_number ? row.tag_number : "-"}</td>
                  <td>{row.created_at ? formatHour(row.created_at) : "-"}</td>
                  <td>{row.awb ? row.awb : "-"}</td>
                  <td>
                    {row.merchandise_description
                      ? row.merchandise_description
                      : "-"}
                  </td>
                  <td>{row.destination ? row.destination : "-"}</td>
                  <td>{row.pieces ? row.pieces : "-"}</td>
                  <td>
                    <span className={`status ${getStatusClass(row?.status)}`}>
                      {row.status ? getStatusText(row.status) : "-"}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default WeighingControl;
