import React from 'react';
import { RiWhatsappFill, RiPhoneFill } from "react-icons/ri";
import btnCopy from '../../../assets/imgs/landing-page/btn_copy.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './contact.css'

const Contact = () => {

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
      };

    return (
        <div className="container-fluid text-center contact-container bg-azul-consistencia mt-5">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <h2>Servicio de <br></br> atención al cliente</h2>
                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <a href="tel:+525551330237" target="_blank" rel="noopener noreferrer">
                        <button className='btn-pink'>
                            <RiPhoneFill className='icon-whatsapp' /> 5551330237
                        </button>
                        </a>
                    </div>
                </div>

                <div className="col-md-1 d-none d-md-block">
                    <div className="border-end" style={{ height: '120%', marginTop: '-20px', marginBottom: '-20px'  }}></div>
                </div>

                <div className="col-md-5 mt-4 mt-md-0 px-md-4">
                    <ul className="list-unstyled">
                        <li className="d-flex align-items-center mb-3">
                            <div className="row w-100 align-items-center flex-nowrap">
                                <div className="col-9 col-md-10 d-flex align-items-center justify-content-center justify-content-md-start">
                                    <h6 className='h6-responsive text-truncate'>cadomestico@aeromexico.com</h6>
                                </div>
                                <div className="col-3 col-md-2 d-flex justify-content-start justify-content-md-center">
                                    <button
                                        onClick={() => copyToClipboard('cadomestico@aeromexico.com')}
                                        className="btn btn-link p-0"
                                    >
                                        <img src={btnCopy} alt="Copiar" className="icon-copy" />
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li className="d-flex align-items-center mb-3">
                            <div className="row w-100 align-items-center flex-nowrap">
                                <div className="col-9 col-md-10 d-flex align-items-center justify-content-center justify-content-md-start">
                                    <h6 className='h6-responsive text-truncate'>caatencionexport@aeromexico.com</h6>
                                </div>
                                <div className="col-3 col-md-2 d-flex justify-content-start justify-content-md-center">
                                    <button
                                        onClick={() => copyToClipboard('caatencionexport@aeromexico.com')}
                                        className="btn btn-link p-0"
                                    >
                                        <img src={btnCopy} alt="Copiar" className="icon-copy" />
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li className="d-flex align-items-center">
                            <div className="row w-100 align-items-center flex-nowrap">
                                <div className="col-9 col-md-10 d-flex align-items-center justify-content-center justify-content-md-start">
                                    <h6 className='h6-responsive text-truncate'>caimport@aeromexico.com</h6>
                                </div>
                                <div className="col-3 col-md-2 d-flex justify-content-start justify-content-md-center">
                                    <button
                                        onClick={() => copyToClipboard('caimport@aeromexico.com')}
                                        className="btn btn-link p-0"
                                    >
                                        <img src={btnCopy} alt="Copiar" className="icon-copy" />
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Contact;