import React, { useState, useEffect } from "react";
import "./style.css";

import amcLogo from "../../../../../assets/imgs/amc-domestico/logo_AMC.png";
import pink_lines from "../../../../../assets/imgs/amc-domestico/Estelas_AMC.svg";

import { FaPause } from "react-icons/fa";
import { VscSearch } from "react-icons/vsc";
import { IoMdExit } from "react-icons/io";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function CountdownTimer({
  initialTime,
  setTimeout,
  isPaused,
  onReset,
  setTurnAsing,
}) {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const asingTurn = async () => {
    const body = {
      user_id: localStorage.getItem("collection-email"),
      counter_checkout_id: localStorage.getItem("collection-counterId"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/assign_turn`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (response.data.code === 1) {
        setTurnAsing(response.data.data);
      } else if (
        response.data.code === 0 &&
        response.data.message === "Ya existe este registro"
      ) {
        setTurnAsing(response.data.data);
      } else if (
        response.data.code === -1 &&
        response.data.message === "No hay turnos en espera"
      ) {
        setTurnAsing({ turn_number: "No hay turnos en espera" });
      } else {
        setTurnAsing({ turn_number: "No hay turnos en espera" });
        throw new Error(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (initialTime !== timeLeft) {
      setTimeLeft(initialTime); // Sincroniza con el nuevo tiempo
    }
  }, [initialTime]);

  useEffect(() => {
    if (timeLeft <= 0) {
      asingTurn();
      setTimeout(true);
      return;
    }

    if (isPaused) {
      return; // Si está pausado, no hacemos nada
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, isPaused]);

  useEffect(() => {
    if (onReset) {
      setTimeLeft(initialTime);
    }
  }, [onReset, initialTime]);

  return (
    <div className="countdown">
      <p>{formatTime(timeLeft)}</p>
    </div>
  );
}

const ModalInformations = ({ id, onClose }) => {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const statusTextMap = {
    1: "Buscando",
    2: "Encontrado",
    3: "Encontrado Parcial",
    4: "No encontrado",
  };

  const accept = async () => {
    const body = {
      status: "1",
      user_id: `${localStorage.getItem("collection-email")}`,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/turn/update/${items?.collection?.id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const rejects = async () => {
    const body = {
      status: "0",
      user_id: `${localStorage.getItem("collection-email")}`,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/turn/update/${items?.collection?.id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/turn/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "collection-token"
              )}`,
            },
          }
        );
        setItems(response.data.data);
        setShowModal(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) fetchData();
  }, [id]);

  return (
    <div>
      {/* Modal */}
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          onClose();
        }}
        centered
        dialogClassName="collection-modal-width"
      >
        <Modal.Body>
          <table className="table-collection">
            <thead>
              <tr>
                <th colSpan="2">Información</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    backgroundColor: "#020c41",
                    width: "20rem",
                    color: "#ffffffff",
                  }}
                >
                  Nombre de cliente/Total de guías
                </td>
                <td
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {items.collection?.customer_name ?? ""}
                  {Array.isArray(items.collection?.awbs) &&
                  items.collection.awbs.length > 0
                    ? items.collection.awbs.map((awb, index) => (
                        <React.Fragment key={index}>
                          <br />
                          {awb}
                        </React.Fragment>
                      ))
                    : ""}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "#020c41",
                    width: "20rem",
                    color: "#ffffffff",
                    textAlign: "center",
                  }}
                >
                  Estatus Buscador
                </td>
                <td>
                  <div
                    className={[
                      "status",
                      items?.collection?.search_status === "1" && "searching",
                      items?.collection?.search_status === "2" && "found",
                      items?.collection?.search_status === "3" &&
                        "partial-found",
                      items?.collection?.search_status === "4" && "not-found",
                    ]
                      .filter(Boolean) // Elimina valores falsy como `false` o `undefined`
                      .join(" ")}
                  >
                    {statusTextMap[items?.collection?.search_status] || ""}
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "#020c41",
                    width: "20rem",
                    color: "#ffffffff",
                    textAlign: "justify",
                  }}
                >
                  Hora de asignación al empleado
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}
                >
                  {items?.collection?.employee_asing_date}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="buttons-collection">
            <button className="btn reject" onClick={rejects}>
              No aceptado
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0005 20.2734C11.3137 20.2734 12.6141 20.0148 13.8273 19.5122C15.0406 19.0097 16.143 18.2731 17.0716 17.3445C18.0001 16.4159 18.7367 15.3135 19.2393 14.1003C19.7418 12.887 20.0005 11.5867 20.0005 10.2734C20.0005 8.96022 19.7418 7.65986 19.2393 6.4466C18.7367 5.23335 18.0001 4.13096 17.0716 3.20237C16.143 2.27378 15.0406 1.53719 13.8273 1.03464C12.6141 0.532095 11.3137 0.273437 10.0005 0.273438C7.34832 0.273438 4.80478 1.32701 2.92942 3.20237C1.05406 5.07773 0.000488281 7.62127 0.000488281 10.2734C0.000488281 12.9256 1.05406 15.4691 2.92942 17.3445C4.80478 19.2199 7.34832 20.2734 10.0005 20.2734ZM9.74271 14.3179L15.2983 7.65122L13.5916 6.22899L8.81382 11.9612L6.3416 9.48788L4.77049 11.059L8.10382 14.3923L8.96382 15.2523L9.74271 14.3179Z"
                  fill="white"
                />
              </svg>
            </button>
            <button className="btn accept" onClick={accept}>
              Aceptado
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.0005 20.2734C11.3137 20.2734 12.6141 20.0148 13.8273 19.5122C15.0406 19.0097 16.143 18.2731 17.0716 17.3445C18.0001 16.4159 18.7367 15.3135 19.2393 14.1003C19.7418 12.887 20.0005 11.5867 20.0005 10.2734C20.0005 8.96022 19.7418 7.65986 19.2393 6.4466C18.7367 5.23335 18.0001 4.13096 17.0716 3.20237C16.143 2.27378 15.0406 1.53719 13.8273 1.03464C12.6141 0.532095 11.3137 0.273437 10.0005 0.273438C7.34832 0.273438 4.80478 1.32701 2.92942 3.20237C1.05406 5.07773 0.000488281 7.62127 0.000488281 10.2734C0.000488281 12.9256 1.05406 15.4691 2.92942 17.3445C4.80478 19.2199 7.34832 20.2734 10.0005 20.2734ZM9.74271 14.3179L15.2983 7.65122L13.5916 6.22899L8.81382 11.9612L6.3416 9.48788L4.77049 11.059L8.10382 14.3923L8.96382 15.2523L9.74271 14.3179Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const Documenter = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [search, setSearch] = useState("");
  const [timeout, setTimeout] = useState(false);
  const [resetCounter, setResetCounter] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [pauseStartTime, setPauseStartTime] = useState(null);
  const [pauseTimeId, setPauseTimeId] = useState(null);
  const [elapsedPauseTime, setElapsedPauseTime] = useState(0);
  const userName = localStorage.getItem("collection-user");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(0);
  const [selectedId, setSelectedId] = useState(null); // Estado para manejar el ID seleccionado
  const [turnAsing, setTurnAsing] = useState({}); // Turno asignado cada que el contador llega a 0
  const location = useLocation();
  const navigate = useNavigate();

  //vista de mnostrador
  const [isCounterSelected, setIsCounterSelected] = useState(false);
  const [idCounterSelected, setIdCounterSelected] = useState("");
  const [isLoadingCounters, setIsLoadingCounters] = useState(true);
  const [counters, setCounters] = useState([]);

  const filteredData = data.filter((item) => item.turn_number.includes(search));

  const convertTimeToSeconds = (time) => {
    const parts = time.split(":").map(Number);

    if (parts.length === 3) {
      // Formato HH:MM:SS
      const [hours, minutes, seconds] = parts;
      return hours * 3600 + minutes * 60 + seconds;
    } else if (parts.length === 2) {
      // Formato MM:SS
      const [minutes, seconds] = parts;
      return minutes * 60 + seconds;
    } else {
      console.error("Formato de tiempo inválido:", time);
      return 0; // Devuelve 0 en caso de error
    }
  };

  const setTimeCounter = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/time/set_timeout`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        const newTime = convertTimeToSeconds(res.data.data.setTimeout);
        setTime(newTime);
      } else {
        console.error("Código inválido en la respuesta:", res.data.code);
      }
    } catch (error) {
      console.error("Error al obtener el tiempo:", error);
      setTime(0);
    }
  };

  const handleFinishTurn = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/turn_finish/${id}`,
        { user_id: localStorage.getItem("collection-email") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (response.data.code === 1) {
        setTurnAsing({});
        setLoading(true);
        setTimeout(false);
        setResetCounter((prev) => !prev);
        setIsPaused(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePausePlay = () => {
    if (isPaused) {
      handlePlay(); // Si está pausado, enviar petición de reanudar
    } else {
      handlePause(); // Si está activo, enviar petición de pausa
    }
  };

  const handlePause = async () => {
    const body = {
      counter_checkout_id: localStorage.getItem("collection-counterId"),
      user_id: localStorage.getItem("collection-email"),
    };
    try {
      // Realizar la petición a la API al pausar
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/record_time`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        // Configurar el estado del cronómetro
        setPauseTimeId(res.data?.data?.id);
        setPauseStartTime(Date.now());
        setShowPauseModal(true);
        setIsPaused(true); // Marcar el estado como pausado
      }
    } catch (error) {
      console.error("Error al pausar:", error);
    }
  };

  const handlePlay = async () => {
    const body = {
      id: pauseTimeId,
      counter_checkout_id: localStorage.getItem("collection-counterId"),
      user_id: localStorage.getItem("collection-email"),
    };
    try {
      // Realizar la petición a la API al reanudar
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/record_time`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        // Detener el cronómetro
        setPauseTimeId(null);
        setElapsedPauseTime(0);
        setShowPauseModal(false);
        setIsPaused(false); // Marcar el estado como reanudado
      }
    } catch (error) {
      console.error("Error al reanudar:", error);
    }
  };

  const callTurn = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/supervisor/turn_scream/${turnAsing.turn_number}`,
        { counter_checkout_id: localStorage.getItem("collection-counterId") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        Swal.fire({
          title: "Turno Voceado",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/turns`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        setData(res.data.data.turns);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  //para mostrar el modal
  const handleOpenIteration = (id) => {
    setSelectedId(id); // Configura el ID dinámico
  };

  //vista mostrador
  const isCounter =
    location.pathname === "/amc-domestico/collection/documenter";

  const getCounters = async () => {
    setIsLoadingCounters(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/select_counter_checkout`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        setCounters(res.data.data.counter_checkouts);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCounters(false);
    }
  };

  const selectCounter = (id) => {
    setIdCounterSelected(id);
  };

  const registerCounert = async () => {
    if (!idCounterSelected) {
      Swal.fire({
        title: "Por favor seleccione un mostrador",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ok",
      });
    } else {
      const body = {
        counter_checkout_id: idCounterSelected,
        user_id: localStorage.getItem("collection-email"),
      };

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/select_counter_checkout`,
          body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "collection-token"
              )}`,
            },
          }
        );
        if (res.data.code === 1) {
          setIsCounterSelected(true);
          localStorage.setItem("collection-counterId", idCounterSelected);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const finishCounter = async () => {
    if (!idCounterSelected) return;

    const body = {
      user_id: localStorage.getItem("collection-email"),
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/counter/select_counter_finish/${idCounterSelected}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        setOpenDropdown(false);
        setIdCounterSelected("");
        setIsCounterSelected(false);
        localStorage.removeItem("collection-counterId");
        getCounters();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const exitCounter = async () => {
    if (!idCounterSelected) return;

    const result = await Swal.fire({
      title: "¿Quieres salir de este mostrador?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;

    await finishCounter();
  };

  const logOut = async () => {
    if (idCounterSelected) {
      await finishCounter(); // Ejecuta directamente la lógica sin preguntar
    }
    localStorage.clear();
    navigate("/amc-domestico/collection/");
  };

  useEffect(() => {
    getCounters();
  }, []);

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} de ${monthName} ${year}`;
    }

    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }

    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeCounter();
  }, []);

  useEffect(() => {
    if (
      Object.keys(turnAsing).length > 0 &&
      turnAsing.turn_number !== "No hay turnos en espera"
    ) {
      getData();
    }
  }, [turnAsing]);

  useEffect(() => {
    let interval;

    if (showPauseModal) {
      interval = setInterval(() => {
        setElapsedPauseTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showPauseModal]);

  useEffect(() => {
    const counterId = localStorage.getItem("collection-counterId");
    if (counterId) {
      setIsCounterSelected(true);
      setIdCounterSelected(counterId);
    }
  }, []);

  return (
    <>
      {isCounterSelected ? (
        <div className="amc-Approved-container">
          <div className="amc-Approved-header">
            <div className="amc-Approved-header-img">
              <div className="logo">
                <img src={amcLogo} alt="Logo" />
              </div>
              <div className="lines_pink">
                <img src={pink_lines} alt="lines" />
              </div>
            </div>
            <div className="amc-Approved-header-hours">
              <div className="hours">
                <span>{currentDate}</span>
                <span>{currentTime}</span>
              </div>
              <div className="user">
                <div className="username">
                  <span>Usuario: {userName}</span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenDropdown}
                  >
                    ▼
                  </span>
                </div>
                {openDropdown && (
                  <div className="dropdownMenu">
                    <ul>
                      {isCounter && (
                        <li>
                          <button
                            type="button"
                            className="exit-btn"
                            onClick={exitCounter}
                          >
                            Salir del mostrador <IoMdExit />
                          </button>
                        </li>
                      )}
                      <li>
                        <button
                          type="button"
                          className="exit-btn"
                          onClick={logOut}
                        >
                          Cerrar sesion <IoMdExit />
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="amc-Approved-body">
            {/* Registros */}
            <div className="amc-Approved-body-content">
              <div className="title">
                <p>Documentación</p>
              </div>
              <div className="search">
                <div className="inputContainer">
                  <span>
                    <VscSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Buscar por número de turno"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="records">
                <table className="records-table">
                  <thead>
                    <tr className="table-header">
                      <th className="table-header-cell"># Turno</th>
                      <th className="table-header-cell">
                        Nombre del cliente/Total de Guías
                      </th>
                      <th className="table-header-cell"></th>
                      <th className="table-header-cell"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        <td></td>
                        <td>
                          <div className="loading-spinner">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Cargando...
                              </span>
                            </Spinner>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                      </>
                    ) : (
                      filteredData
                        .sort((a, b) => {
                          if (a.number === turnAsing.turn_number) return -1;
                          if (b.number === turnAsing.turn_number) return 1;
                          return 0;
                        })
                        .slice(0, 6)
                        .map((turno, index) => (
                          <tr key={turno.id}>
                            <td
                              className={`table-cell ${
                                index === 0 ? "highlight" : ""
                              } first-cell`}
                            >
                              {turno.turn_number}
                            </td>

                            <td
                              className={`table-cell ${
                                index === 0 ? "highlight" : ""
                              } middle-cell`}
                            >
                              {turno.customer_name &&
                              turno.customer_name.trim() !== ""
                                ? turno.customer_name
                                : turno.awbs.length > 0
                                ? turno.awbs.length
                                : ""}
                            </td>

                            <td className="spacing-cell"></td>

                            <td className="info-cell">
                              <div className="info-button-container">
                                <button
                                  className="info-button"
                                  disabled={index !== 0}
                                  style={
                                    index !== 0
                                      ? {
                                          backgroundColor: "#d3d3d3",
                                          cursor: "not-allowed",
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleOpenIteration(turno.id);
                                  }}
                                >
                                  i
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Contador */}
            <div className="amc-Approved-body-timer">
              <div className="next-turn">
                <h3>{timeout ? "Turno Asignado" : "Siguiente Turno en ..."}</h3>
              </div>
              <div className="time">
                {timeout ? (
                  <p
                    className="turn-asing"
                    style={
                      turnAsing.turn_number === "No hay turnos en espera"
                        ? { fontSize: "2rem" }
                        : {}
                    }
                  >
                    {turnAsing.turn_number || ""}
                  </p>
                ) : (
                  time > 0 && (
                    <CountdownTimer
                      initialTime={time}
                      setTimeout={setTimeout}
                      isPaused={isPaused}
                      onReset={resetCounter}
                      setTurnAsing={setTurnAsing}
                    />
                  )
                )}
              </div>
              {timeout ? (
                <div className="acttions-timeOut">
                  {turnAsing.turn_number === "No hay turnos en espera" ? (
                    ""
                  ) : (
                    <button
                      className="finished"
                      onClick={() =>
                        handleFinishTurn(turnAsing?.turn_counter_checkouts_id)
                      }
                    >
                      Finalizar Turno
                    </button>
                  )}

                  {turnAsing.turn_number === "No hay turnos en espera" ? (
                    ""
                  ) : (
                    <button className="vocear" onClick={callTurn}>
                      Vocear Turno
                    </button>
                  )}
                </div>
              ) : (
                <div className="actions">
                  <div className="play_pause">
                    <button
                      className="btn-play_pause"
                      onClick={handlePausePlay}
                    >
                      <FaPause className="HiMiniPlayPause" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="amc-Approved-container">
          <div className="amc-Approved-header">
            <div className="amc-Approved-header-img">
              <div className="logo">
                <img src={amcLogo} alt="Logo" />
              </div>
              <div className="lines_pink">
                <img src={pink_lines} alt="lines" />
              </div>
            </div>
            <div className="amc-Approved-header-hours">
              <div className="hours">
                <span>{currentDate}</span>
                <span>{currentTime}</span>
              </div>
              <div className="user">
                <div className="username">
                  <span>Usuario: {userName}</span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenDropdown}
                  >
                    ▼
                  </span>
                </div>
                {openDropdown && (
                  <div className="dropdownMenu">
                    <ul>
                      <li>
                        <button
                          type="button"
                          className="exit-btn"
                          onClick={logOut}
                        >
                          Cerrar sesion <IoMdExit />
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="amc-Approved-body">
            <div className="amc-Approved-body-counters">
              <div className="title">
                <p>Mostradores Recolección</p>
              </div>
              <div className="counters">
                {isLoadingCounters ? (
                  <div className="loading-spinner">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Cargando...</span>
                    </Spinner>
                  </div>
                ) : counters.length > 0 ? (
                  counters.map((counter, _index) => (
                    <div
                      key={_index}
                      className={`counter ${
                        idCounterSelected === counter.id ? "active" : ""
                      }`}
                      onClick={() => {
                        selectCounter(counter.id);
                      }}
                    >
                      {counter.name.replace(/.*\s(\d+)$/, "$1")}
                    </div>
                  ))
                ) : (
                  <h1>No hay mostradores disponibles</h1>
                )}
              </div>
            </div>

            <div className="amc-Approved-body-btn">
              <div className="counter-btn-container">
                <button className="next-btn" onClick={registerCounert}>
                  Siguiente &#x276f;
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedId && (
        <ModalInformations
          id={selectedId}
          onClose={() => setSelectedId(null)}
          getData={getData}
        />
      )}

      <Modal
        show={showPauseModal}
        onHide={() => {}}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{
            backgroundImage: "none",
            borderBottom: "none",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p className="countUp-header">Tiempo transcurrido</p>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundImage: "none",
            borderBottom: "none",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div className="countUp">
            <p>
              {Math.floor(elapsedPauseTime / 60)}:
              {String(elapsedPauseTime % 60).padStart(2, "0")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          className="footer-mod"
          style={{
            backgroundImage: "none",
            borderTop: "none",
          }}
        >
          <Button className="btn-resumen" onClick={handlePlay}>
            Reanudar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Documenter;
