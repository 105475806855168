import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { FaArrowRightLong, FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { AiFillFilePdf } from "react-icons/ai";
import { FaCircleArrowUp } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/Footer";
import ScrollToTop from "../../components/scrollToTop/scrollToTop";
import axios from "axios";
import cuadroAzul from "../../assets/imgs/preguntas-frecuentes/cuadro_azul_texto.png";
import "./freq-questions.css";

function FrequentlyQuestions() {
  
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSection, setSelectedSection] = useState("Nacional");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // const [OpenDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [OpenDropdownIndex, setOpenDropdownIndex] = useState(0);
  const [categoria, setCategoria] = useState([]);

  const [subcategory, setSubcategory] = useState([]);
  const [pregunta, setPregunta] = useState([]);

  const [tFreqQuestions] = useTranslation("freq_questions");
  const [tSMetaDataFaqs] = useTranslation("metaData");

  const lng = localStorage.getItem("lng") || "es";

  let storedLang = lng === "us" ? "en" : "es";

  //Primer endpoint para los botones Rosa y grises (Nacional e Internacional).
  const endpointUrl = `${process.env.REACT_APP_URL_BACKEND}/api/qfa/issues/lang/${storedLang}`;

  useEffect(() => {
    axios
      .get(endpointUrl, {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      })
      .then((response) => {
        if (response.data.code === 1) {
          setCategoria(response.data.data);
          if (response.data.data.length > 0) {
            setSelectedSection(response.data.data[0].name);
            handleSectionCategoryChange(response.data.data[0]);
          }
        }
      })
      .catch((error) => {
        console.log("Error al obtener los datos de los botones:", error);
      });
  }, [storedLang]);
  

  //Segundo endpoint para Categorias
  const handleSectionCategoryChange = async (item) => {
    setSelectedSection(item.name);
    const endpointUrlCategoria = `${process.env.REACT_APP_URL_BACKEND}/api/qfa/category/forLevel/${storedLang}/${item.id}`;
    try {
      const response = await axios.get(endpointUrlCategoria, {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });
      setSubcategory(response.data.data || []);

      if (response.data.data.length > 0) {
        setSelectedCategory(response.data.data[0].id);
        handleCategoryAndQuestionClick(response.data.data[0]);
      }
    } catch (error) {
      console.log("No hay datos para mostrar");
      setSubcategory([]);
    }
  };

  // Tercer endpoint para Pregunta
  const handleCategoryAndQuestionClick = async (item) => {
    setSelectedCategory(item.id);
    const endpointUrlPregunta = `${process.env.REACT_APP_URL_BACKEND}/api/qfa/question/forLevel/${storedLang}/${item.id}`;
    try {
      const response = await axios.get(endpointUrlPregunta, {
        headers: {
          Authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });

      setPregunta(response.data.data || []);
      setOpenDropdownIndex(null);

      console.log(response.data.data);
    } catch (error) {
      console.log("Error al hacer la conexion al endpoint");
      setPregunta([]);
    }
  };

  // funcion para cambiar el nombre h1 de la columna1 y columna2
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Funcion para alternar el estado de un dropdown individual
  const toggleDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  //Funcion del bton para volver arriba
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{tSMetaDataFaqs("preguntasFrecuentes.title")}</title>
        <meta
          name="description"
          content={tSMetaDataFaqs("preguntasFrecuentes.description")}
        ></meta>
      </Helmet>
      <Navbar />

      {/* primeraSeccionl*/}
      <section className="primeraSeccion">
        <div className="bgAirplaneSky">
          <div className="contenido">
            <img src={cuadroAzul} alt="imagen-fondo-azul-Preguntas-Frecuentes" />
          </div>
        </div>
      </section>

      {/* segundaSeccionl*/}
      <section className="segundaSeccion">
        <div className="botones-container">
          {categoria.map((item, index) => (
            <button
              key={index}
              className={`botonGral ${
                selectedSection === item.name ? "active" : ""
              }`}
              onClick={() => handleSectionCategoryChange(item)}
            >
              {item.name}
            </button>
          ))}
        </div>
      </section>

      {/* terceraSeccionl*/}
      <section className="terceraSeccion">
        <div className="columnas-container">
        {/* colmna de categorias */}
          <div className="columna1">
            <h1>
              {screenWidth <= 1024
                ? tFreqQuestions("categorias")
                : tFreqQuestions("selecciona_categoria")}
            </h1>
            <div className="dropdowns-container">
              {subcategory.map((item, index) => (
                <div
                  key={index}
                  className={`dropdownCat categoria ${
                    selectedCategory === item.id ? "active" : ""
                  }`}
                  onClick={() => handleCategoryAndQuestionClick(item)}
                >
                  {item.name} 
                  <FaArrowRightLong className="iconoRight" />
                </div>
              ))}
            </div>
          </div>

          {/* colmna de preguntas */}
          <div className="columna2">
            <h1>
              {screenWidth <= 1024
                ? tFreqQuestions("pregunta")
                : tFreqQuestions("selecciona_pregunta")}
            </h1>

            <div className="dropdowns-container">
              {pregunta.map((item, index) => (
                <div key={index} className="dropdown-wrapper">
                  <div
                    className={`dropdownPre pregunta ${ OpenDropdownIndex === index ? "open" : ""}`}
                    onClick={() => toggleDropdown(index)}>
                    {item.question}
                    {OpenDropdownIndex === index ? (
                      <FaAngleUp />
                    ) : (
                      <FaAngleDown />
                    )}
                  </div>
                  {OpenDropdownIndex === index && (
                    <div className="dropdown-content">
                      <ul>
                        {Array.isArray(item.answers) ? (
                          item.answers.map((answer, i) => (
                            <li key={i} dangerouslySetInnerHTML={{ __html: answer }}></li>
                          ))
                        ) : (
                          <li dangerouslySetInnerHTML={{ __html: item.answers || "No hay respuestas disponibles", }}></li>
                        )}

                        {item.file_url && (
                          <li>
                            <a href={item.file_url} rel="noopener noreferrer" download target="_blank">
                              {tFreqQuestions("descargar_documento")} {" "}
                              <AiFillFilePdf/>
                            </a>
                          </li>
                        )}
                        
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* cuartaSeccionl*/}
        <section className="cuartaSeccion">
          <div className="scroll-top-button" onClick={scrollToTop}>
            <FaCircleArrowUp size={35} />
          </div>
        </section>
      </section>

      <Footer />
    </>
  );
}

export default FrequentlyQuestions;
