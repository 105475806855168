import "./reprint-shifts.css";
import logoAmc from "../../../../../assets/imgs/navbar/logo_AMC.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import amc_black_logo from "../../../../../assets/imgs/amc-domestico/Logo_AMC_Negro.png";
import Swal from "sweetalert2";

function ReprintShifts() {
  const [turnsArr, setTurnsArr] = useState([]);
  const [currentTurn, setCurrentTurn] = useState({});
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    getTurns();
  }, []);

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();
      return `${dayName}, ${day} de ${monthName} ${year}`;
    }
    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");
      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }
    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const getTurns = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/shipment/five_turns`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
        },
      })

      setTurnsArr(response.data.data);
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Error al mostrar los ultimos 5 turnos",
        icon: "error",
      });
    }
  }

  const shipmentNumber = (index) => {
    const tagNumberArr = currentTurn.labels.map(item => item.tag_number);
    let mapping = {};
    let counter = 1;
    const result = tagNumberArr.map(tag => {
      if (!(tag in mapping)) {
          mapping[tag] = counter++;
      }
      return mapping[tag];
    });
    return result[index]; 
  }

  const printContent = async (elementId, size) => {
    const element = document.getElementById(elementId);

    if (element) {
      const printWindow = window.open("", "_blank");

      if (!printWindow) {
        console.error("No se pudo abrir la ventana de impresión. Asegúrate de que las ventanas emergentes no están bloqueadas.");
        return;
      }

      const styles = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules || [])
              .map((rule) => rule.cssText)
              .join("\n");
          } catch (err) {
            return "";
          }
        })
        .join("\n");

      printWindow.document.open();
      printWindow.document.write(`
        <html>
          <head>
            <title>Imprimir</title>
            <style>
              ${styles}
              @page { size: ${size}; margin: 0; }
            </style>
          </head>
          <body onload="window.print(); window.close();">
            ${element.outerHTML}
          </body>
        </html>
      `);
      printWindow.document.close();

    } else {
      console.error("Elemento no encontrado:", elementId);
    }
  };

  const updateCurrentTurn = async (turn, type) => {
    setCurrentTurn(turn);
    if(type === "label") {
      setTimeout(() => {
        printContent("label-content", "6in 4in");
      }, 500);
    } else if (type === 'turn') {
      setTimeout(() => {
        printContent("ticket-content", "6in 4in");
      }, 500);
    } else {
      setTimeout(() => {
        printContent("both-content", "6in 4in");
      }, 500);
    }
  }

  const renderLabels = () => {
    return (
      <div className="div-1">
        <div id="label-content">
          {currentTurn.labels && currentTurn.labels.map((label, _index) => (
            <div className="amc-home-label page-break" key={_index}>
              <div className="amc-home-label-header">
                <h4>Identificador</h4>
                <div className="amc-logo">
                  <img src={amc_black_logo} alt="Aeromexico Cargo" />
                </div>
              </div>
              <div className="amc-home-label-body">
                <div className="amc-date">
                  <div className="info">
                    <span className="text-bold">{currentTurn.turn.turn_number}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="text-bold">
                      {" "}
                      Embarque: {shipmentNumber(_index)}
                    </span>
                  </div>
                  <div className="date">
                    <span className="text-bold">{currentDate}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="text-bold">{currentTime}</span>
                  </div>
                </div>
                <table className="amc-table">
                  <tbody>
                  <tr>
                      <td>Consignatario</td>
                      <td className="text-bold">
                        {label.shipment_name.toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <td>Destino</td>
                      <td className="text-bold">
                        {label.destination.toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <td>Tipo de carga</td>
                      <td className="text-bold">
                        {label?.merchandise_description
                          ? label?.merchandise_description.toUpperCase()
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Piezas</td>
                      <td className="text-bold">{label.piece}</td>
                    </tr>
                  </tbody>
                </table>
                <p className="paste-text">Pegar en caja</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const renderGetTicket = () => {
    return (
      <div className="div-2">
        <div id="ticket-content" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          { currentTurn.turn && 
            <div className="amc-home-ticket">
              <div className="amc-home-ticket-header">
                <div className="amc-home-ticket-header-icon">
                  <img src={amc_black_logo} alt="logo amc" />
                </div>
                <div className="amc-home-ticket-header-hour">
                  <div className="amc-home-ticket-header-hour-date">
                    <p>{currentDate}</p>
                  </div>
                  <div className="amc-home-ticket-header-date">
                    <p>{currentTime}</p>
                  </div>
                </div>
              </div>
              <div className="amc-home-ticket-body">
                <h2>Turno</h2>
                <h2 className="text-bold" style={{marginTop: "-10px"}}>{currentTurn.turn.turn_number}</h2>
                <p>Cantidad de embarques</p>
                <h5 className="text-bold">{currentTurn.turn.total_shipment}</h5>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }

  const renderBothTikets = () => {
    return (
      <div className="div-1">
        <div id="both-content">
          {currentTurn.labels && currentTurn.labels.map((label, _index) => (
            <div className="amc-home-label page-break" key={_index}>
              <div className="amc-home-label-header">
                <h4>Identificador</h4>
                <div className="amc-logo">
                  <img src={amc_black_logo} alt="Aeromexico Cargo" />
                </div>
              </div>
              <div className="amc-home-label-body">
                <div className="amc-date">
                  <div className="info">
                    <span className="text-bold">{currentTurn.turn.turn_number}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="text-bold">
                      {" "}
                      Embarque: {shipmentNumber(_index)}
                    </span>
                  </div>
                  <div className="date">
                    <span className="text-bold">{currentDate}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="text-bold">{currentTime}</span>
                  </div>
                </div>
                <table className="amc-table">
                  <tbody>
                    <tr>
                      <td>Consignatario</td>
                      <td className="text-bold">
                        {label.shipment_name.toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <td>Destino</td>
                      <td className="text-bold">
                        {label.destination.toUpperCase()}
                      </td>
                    </tr>
                    <tr>
                      <td>Tipo de carga</td>
                      <td className="text-bold">
                        {label?.merchandise_description
                          ? label?.merchandise_description.toUpperCase()
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Piezas</td>
                      <td className="text-bold">{label.piece}</td>
                    </tr>
                  </tbody>
                </table>
                <p className="paste-text">Pegar en caja</p>
              </div>
            </div>
          ))}
          
          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div className="amc-home-ticket">
              <div className="amc-home-ticket-header">
                <div className="amc-home-ticket-header-icon">
                  <img src={amc_black_logo} alt="logo amc" />
                </div>
                <div className="amc-home-ticket-header-hour">
                  <div className="amc-home-ticket-header-hour-date">
                    <p>{currentDate}</p>
                  </div>
                  <div className="amc-home-ticket-header-date">
                    <p>{currentTime}</p>
                  </div>
                </div>
              </div>
              <div className="amc-home-ticket-body">
                { currentTurn.turn &&
                  <>
                    <h2>Turno</h2>
                    <h2 className="text-bold" style={{marginTop: "-10px"}}>{currentTurn.turn.turn_number}</h2>
                    <p>Cantidad de embarques</p>
                    <h5 className="text-bold">{currentTurn.turn.total_shipment}</h5>
                  </>
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }

  return (
    <>
      <div className="header-weighing">
        <img className="logo-amc" src={logoAmc} alt="amc-logo" />
      </div>

      <div className="body-reprint">

        <h4>Ultimos registros</h4>

        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th># Turno</th>
                <th>total de embarques</th>
                <th>Acciones</th>
              </tr>
            </thead>

            <tbody>
              {turnsArr.map((turn, index) => (
                <tr key={index}>
                  <td>{ turn.turn.turn_number }</td>
                  <td>{ turn.turn.total_shipment }</td>
                  <td className="btns-action">
                    <button onClick={() => updateCurrentTurn(turn, "label")}>Tiket</button>
                    <button onClick={() => updateCurrentTurn(turn, "turn")}>Turno</button>
                    <button onClick={() => updateCurrentTurn(turn, "both")}>Ambos</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>

      {/* Modal de Etiquetas */}
      {renderLabels()}
      {/* Modal de Ticket */}
      {renderGetTicket()}
      {/* Modal de Ticket */}
      {renderBothTikets()}

    </>
  )
}

export default ReprintShifts;