import { useEffect, useState } from 'react';
import './search-engines.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { HiOutlineX } from "react-icons/hi";
import { useForm } from 'react-hook-form';

function SearchEngines() {
  const [employees, setEmployees] = useState([]);
  const [statusModal, setStatusModal] = useState({ status: false, modal: "" });
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      id: "",
      name: "",
      number: ""
    },
  })

  useEffect(() => {
    getEmployee();
  }, []);

  useEffect(() => {
    if (statusModal.status === false) {
      reset({
        name: "",
        number: "",
        id: ""
      });
    }
  }, [statusModal]);

  const getEmployee = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/employee`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
      setEmployees(response.data.data.employee);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al consultar, por favor intentalo de nuevo",
        icon: "error",
      });
    }
  }

  const createSearchEng = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/employee`;
      const body = {
        name: data.name,
        number: data.number
      };
      await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
      Swal.fire({
        title: "Exito",
        text: "Buscador creado correctamente",
        icon: "success",
      });
      setStatusModal({ status: false, modal: "" });
      await getEmployee();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al crear, por favor intentalo mas tarde",
        icon: "error",
      });
    }
  }

  const updateSearchEng = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/employee/${data.id}`;
      const body = {
        name: data.name,
        number: data.number
      };
      await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      Swal.fire({
        title: "Exito",
        text: "Datos actualizados correctamente",
        icon: "success",
      });
      setStatusModal({ status: false, modal: "" });
      await getEmployee();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al editar, por favor intentalo mas tarde",
        icon: "error",
      });
    }
  }

  const deleteEmployee = async (id) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/employee/${id}`;
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "esta estacion sera eliminada",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, eliminarlo",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
        buttonsStyling: true,
      });
      if (result.isConfirmed) {
        await axios.delete(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        Swal.fire({
          title: "¡Eliminado!",
          text: "Buscador eliminado correctamente",
          icon: "success",
        });
        await getEmployee();
      } else {
        Swal.fire("Cancelado", "No se realizo ninguna acción", "error");
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al eliminar, por favor intentalo de nuevo",
        icon: "error",
      });
    }
  }

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const handleOpenForm = async (employee) => {
    setStatusModal({status: true, modal: "edit"});
    reset({
      id: employee.id,
      name: employee.name,
      number: employee.number
    });
  }

  const renderModalForm = () => (
    <>
      <form
        className="form-search-update"
        onSubmit={handleSubmit(
          statusModal.modal === "create" ? createSearchEng : updateSearchEng
        )}
      >
        <div className="input-container-search">
          <label>Nombre:</label>
          <input
            id="name"
            type="text"
            placeholder=""
            {...register("name", { required: true })}
            autoComplete="off"
          />
        </div>
        <div className="alert-search">
          {errors.name?.type === "required" && <p>El campo es requerido</p>}
        </div>

        <div className="input-container-search">
          <label>Número:</label>
          <input
            id="number"
            type="text"
            placeholder=""
            {...register("number", { 
              required: "El numero es requerido", 
              pattern: {
                value: /^[0-9]{10}$/, 
                message: "El número debe contener exactamente 10 dígitos sin espacios"
              }
            })}
            autoComplete="off"
          />
        </div>
        <div className="alert-search">
          {errors.number && <p>{errors.number.message}</p>}
        </div>

        <input
          id="idSearch"
          style={{ display: "none" }}
          type="text"
          placeholder=""
          {...register("idSearch")}
          autoComplete="off"
        />

        <div className="btns-container">
          <button
            className="cancel-btn"
            onClick={() => setStatusModal({ status: false, modal: "" })}
          >
            Cancelar
          </button>
          <button className="send-btn" type="submit">
            Enviar
          </button>
        </div>
      </form>
    </>
  )

  return (
    <>
      <div className="search-eng-container">
        <div className="create-search-eng-container">
          <button
            className="btnn"
            onClick={() => setStatusModal({ status: true, modal: "create" })}
          >
            Crear Buscador
          </button>
        </div>

        <div className="table-responsive">
          <table className="table table-preve">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Número</th>
                <th>Fecha de creación</th>
                <th>Acciones</th>
              </tr>
            </thead>

            <tbody>
              {employees.map((employee, index) => (
                <tr key={index}>
                  <td>{employee.name}</td>
                  <td>{employee.number}</td>
                  <td>{employee.created_at}</td>
                  <td className="btns-counter-container">
                    <button
                      className="btn-action-shift"
                      onClick={() => handleOpenForm(employee)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn-action-shift"
                      onClick={() => deleteEmployee(employee.id)}
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>

      {statusModal.status && (
        <div
          className="modal-window-content"
          onClick={() => setStatusModal({ status: false, modal: "" })}
        >
          <div className="modal-window" onClick={handleModalClick}>
            <div className="header-modal">
              <div></div>
              <div className="title">
                {statusModal.modal === "create"
                  ? "Crear Nuevo"
                  : "Editar Buscador"}
              </div>
              <div
                className="close-btn"
                onClick={() => setStatusModal({ status: false, modal: "" })}
              >
                <HiOutlineX />
              </div>
            </div>
            {renderModalForm()}
          </div>
        </div>
      )}
    </>
  )
}

export default SearchEngines;