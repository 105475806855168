import React, { useState, useEffect, useRef } from "react";
import "./style.css";

import amcLogo from "../../../../../assets/imgs/amc-domestico/logo_AMC.png";
import pink_lines from "../../../../../assets/imgs/amc-domestico/Estelas_AMC.svg";

import { FaCheck, FaPause } from "react-icons/fa";
import { VscSearch } from "react-icons/vsc";
import { IoCopy } from "react-icons/io5";
import { IoMdExit } from "react-icons/io";
import { Modal, Button, Table, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { GoCheckCircleFill, GoXCircleFill } from "react-icons/go";

function CountdownTimer({
  initialTime,
  setTimeout,
  isPaused,
  onReset,
  setTurnAsing,
}) {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const asingTurn = async () => {
    const body = {
      user_id: localStorage.getItem("light-load-email"),
      counter_checkout_id: localStorage.getItem("counterId"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/assign_turn`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (response.data.code === 1) {
        setTurnAsing(response.data.data);
      }
    } catch (error) {
      console.error(error);

      if (error.response) {
        const { code, message, data } = error.response.data || {};

        if (code === 0 && message === "Ya existe este registro") {
          setTurnAsing(data);
        } else if (code === -1 && message === "No hay turnos en espera") {
          setTurnAsing({ turn_number: "No hay turnos en espera" });
        } else {
          console.error(`Error del servidor: ${message}`);
        }
      } else {
        console.error("Error de red o desconocido:", error);
      }
    }
  };

  useEffect(() => {
    if (initialTime !== timeLeft) {
      setTimeLeft(initialTime); // Sincroniza con el nuevo tiempo
    }
  }, [initialTime]);

  useEffect(() => {
    if (timeLeft <= 0) {
      asingTurn();
      setTimeout(true);
      return;
    }

    if (isPaused) {
      return; // Si está pausado, no hacemos nada
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, isPaused]);

  useEffect(() => {
    if (onReset) {
      setTimeLeft(initialTime);
    }
  }, [onReset, initialTime]);

  return (
    <div className="countdown">
      <p>{formatTime(timeLeft)}</p>
    </div>
  );
}

const ModalInformations = ({ id, onClose }) => {
  const [items, setItems] = useState([]);
  const [index, setIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState("");
  const [showCancel, setShowCancel] = useState({
    status_cancel: false,
    status_button: "",
  });
  const justificationRef = useRef(null);
  const [showErrorJustification, setShowErrorTextArea] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const handleConfirm = async (formData) => {
    const body = {
      id: formData.id,
      awb: formData.awb,
      user_id: localStorage.getItem("light-load-email"),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/turn/update`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (response.data.code === 1) {
        if (index < items.length - 1) {
          setIndex(index + 1);
          setCurrentItem(items[index + 1]);
          reset();
          setShowModal(false);
          setTimeout(() => {
            setShowModal(true);
          }, 200);
        } else {
          setShowModal(false);
          onClose();
        }
      } else {
        setError("Error updating the turn. Please try again.");
      }
    } catch (error) {
      console.error("Error updating:", error);
      setError(
        "An error occurred while performing the operation. Please try again."
      );
    }
  };

  const handleJustificationClick = async () => {
    if (justificationRef.current.value === "") {
      setShowErrorTextArea(true);
      return;
    } else {
      try {
        setShowErrorTextArea(false);
        const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/cancel`;
        const body = {
          id: items[index]?.id,
          description: justificationRef.current.value,
          user_id: localStorage.getItem("light-load-email"),
        };
        const response = await axios.put(url, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        });
        if (index < items.length - 1) {
          setIndex(index + 1);
          setCurrentItem(items[index + 1]);
          reset();
          setShowModal(false);
          setShowCancel({ status_cancel: false, status_button: "" });
          setShowErrorTextArea(false);
          setTimeout(() => {
            setShowModal(true);
          }, 200);
        } else {
          setShowModal(false);
          onClose();
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Ocurrio un error al enviar la justificación",
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/turn/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "light-load-token"
              )}`,
            },
          }
        );
        setItems(response.data.data.shipments);
        setShowModal(true); // Muestra el modal automáticamente
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (id) fetchData(); // Solo carga datos si hay un ID
  }, [id]);

  return (
    <div>
      {/* Modal */}
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          onClose();
          setShowCancel({ status_cancel: false, status_button: "" });
          setShowErrorTextArea(false);
        }}
        centered
        dialogClassName="custom-modal-width"
      >
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#00154F",
            color: "#FFFFFF",
            backgroundImage: "none",
            textAlign: "center",
          }}
        >
          <Modal.Title>Información</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(handleConfirm)}>
          <Modal.Body>
            <Table bordered style={{ textAlign: "center" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#a3a3a3",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    # de turno
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={items[index]?.turn_number}
                      readOnly
                      disabled
                      style={{
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      backgroundColor: "#a3a3a3",
                      color: "#FFFFFF",
                      padding: "5px",
                    }}
                  >
                    # de ticket
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      defaultValue={items[index]?.tag_number}
                      readOnly
                      disabled
                      style={{
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#a3a3a3",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    Quantity ID
                  </td>
                  <td colSpan="4" style={{ padding: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Form.Control
                        type="text"
                        defaultValue={items[index]?.quantity_id}
                        readOnly
                        disabled
                        style={{
                          border: "none",
                          backgroundColor: "#FFFFFF",
                          width: "85%",
                        }}
                      />
                      <Button
                        onClick={() => handleCopy(items[index]?.quantity_id)}
                        style={{
                          fontSize: "12px",
                          padding: "10px",
                          fontWeight: "bold",
                          width: "15%",
                          height: "90%",
                          color: "#ffffff",
                          backgroundColor: !copied ? "#a3a3a3" : "#66c87b",
                          border: "none",
                        }}
                      >
                        Copiar &nbsp;&nbsp;
                        {!copied ? <IoCopy /> : <FaCheck />}
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#a3a3a3",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    Nombre consignatario
                  </td>
                  <td colSpan="4">
                    <Form.Control
                      type="text"
                      defaultValue={items[index]?.name}
                      disabled
                      style={{
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#a3a3a3",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    No. de piezas
                  </td>
                  <td colSpan="4">
                    <Form.Control
                      type="text"
                      defaultValue={items[index]?.pieces}
                      disabled
                      style={{
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#a3a3a3",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    Destino
                  </td>
                  <td colSpan="4">
                    <Form.Control
                      type="text"
                      defaultValue={items[index]?.destination}
                      disabled
                      style={{
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#a3a3a3",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    Mercancia
                  </td>
                  <td colSpan="4">
                    <Form.Control
                      type="text"
                      defaultValue={
                        !items[index]?.merchandise_description
                          ? "-"
                          : items[index]?.merchandise_description
                      }
                      disabled
                      style={{
                        border: "none",
                        backgroundColor: "#FFFFFF",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#00154F",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    No. Guia
                  </td>
                  <td colSpan="4">
                    <Form.Control
                      type="text"
                      defaultValue="139-"
                      style={{
                        color: "#0000e3",
                        fontWeight: "600",
                        border: "none",
                      }}
                      {...register("awb", {
                        required: "Debes agregar un numero de guía",
                        pattern: {
                          value: /^\d{3}-\d{8}$/,
                          message: "Formato No. Guia invalido",
                        },
                      })}
                    />
                    <Form.Control
                      type="hidden"
                      value={items[index]?.id}
                      {...register("id", {})}
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      backgroundColor: "#00154F",
                      color: "#FFFFFF",
                      padding: "20px",
                    }}
                  >
                    Comentarios Pesaje
                  </td>
                  <td colSpan="3">
                    <Form.Control
                      type="text"
                      as="textarea"
                      defaultValue={items[index]?.weighing_description}
                      rows={3}
                      readOnly
                      style={{ border: "none" }}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
            {errors.awb && (
              <span
                style={{
                  color: "red",
                  fontSize: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {errors.awb.message}
              </span>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Modal.Body>
          <Modal.Footer>
            <div
              className={`content-btns-input ${
                showCancel.status_cancel ? "active" : ""
              }`}
            >
              <div className="btns-decision">
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() =>
                    setShowCancel({
                      status_cancel: true,
                      status_button: "cancel",
                    })
                  }
                >
                  Cancelar <GoXCircleFill />
                </button>
                <button
                  type="button"
                  className="acept-btn"
                  onClick={() =>
                    setShowCancel({
                      status_cancel: false,
                      status_button: "accept",
                    })
                  }
                >
                  Aceptar <GoCheckCircleFill />
                </button>
              </div>

              <p className="cancel-text">
                ¿por que deseas cancelar? <span className="text-alert">*</span>
              </p>
              <textarea
                ref={justificationRef}
                placeholder="Escribe tu justificación"
              ></textarea>
              {showErrorJustification && (
                <p
                  className="text-alert"
                  style={{
                    fontSize: ".8rem",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  Por favor ingresa una justificacion
                </p>
              )}
            </div>
            {(showCancel.status_button === "" ||
              showCancel.status_button === "accept") && (
              <Button
                type="submit"
                disabled={showCancel.status_button === "accept" ? false : true}
                style={{
                  backgroundColor: "#035cf7",
                  border: "none",
                  width: "100%",
                  height: "50px",
                  fontWeight: "bold",
                }}
              >
                Confirmar
              </Button>
            )}
            {showCancel.status_button === "cancel" && (
              <Button
                type="button"
                disabled={showCancel.status_button === "cancel" ? false : true}
                style={{
                  backgroundColor: "#035cf7",
                  border: "none",
                  width: "100%",
                  height: "50px",
                  fontWeight: "bold",
                }}
                onClick={handleJustificationClick}
              >
                Confirmar
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const ApprovedRegistration = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [search, setSearch] = useState("");
  const [timeout, setTimeout] = useState(false);
  const [resetCounter, setResetCounter] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);
  const [pauseStartTime, setPauseStartTime] = useState(null);
  const [pauseTimeId, setPauseTimeId] = useState(null);
  const [elapsedPauseTime, setElapsedPauseTime] = useState(0);
  const userName = localStorage.getItem("light-load-user");
  const [openDropdown, setOpenDropdown] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(0);
  const [selectedId, setSelectedId] = useState(null); // Estado para manejar el ID seleccionado
  const [turnAsing, setTurnAsing] = useState({}); // Turno asignado cada que el contador llega a 0
  const location = useLocation();
  const navigate = useNavigate();

  //vista de mnostrador
  const [isCounterSelected, setIsCounterSelected] = useState(false);
  const [idCounterSelected, setIdCounterSelected] = useState("");
  const [isLoadingCounters, setIsLoadingCounters] = useState(true);
  const [counters, setCounters] = useState([]);

  const filteredData = data.filter((item) => item.number.includes(search));

  const convertTimeToSeconds = (time) => {
    const parts = time.split(":").map(Number);

    if (parts.length === 3) {
      // Formato HH:MM:SS
      const [hours, minutes, seconds] = parts;
      return hours * 3600 + minutes * 60 + seconds;
    } else if (parts.length === 2) {
      // Formato MM:SS
      const [minutes, seconds] = parts;
      return minutes * 60 + seconds;
    } else {
      console.error("Formato de tiempo inválido:", time);
      return 0; // Devuelve 0 en caso de error
    }
  };

  const setTimeCounter = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/time/set_timeout`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        const newTime = convertTimeToSeconds(res.data.data.setTimeout);
        setTime(newTime);
      } else {
        console.error("Código inválido en la respuesta:", res.data.code);
      }
    } catch (error) {
      console.error("Error al obtener el tiempo:", error);
      setTime(0);
    }
  };

  const handleFinishTurn = async (id) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/turn_finish/${id}`,
        { user_id: localStorage.getItem("light-load-email") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (response.data.code === 1) {
        setTurnAsing({});
        setLoading(true);
        setTimeout(false);
        setResetCounter((prev) => !prev);
        setIsPaused(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePausePlay = () => {
    if (isPaused) {
      handlePlay(); // Si está pausado, enviar petición de reanudar
    } else {
      handlePause(); // Si está activo, enviar petición de pausa
    }
  };

  const handlePause = async () => {
    const body = {
      counter_checkout_id: localStorage.getItem("counterId"),
      user_id: localStorage.getItem("light-load-email"),
    };
    try {
      // Realizar la petición a la API al pausar
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/record_time`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        // Configurar el estado del cronómetro
        setPauseTimeId(res.data?.data?.id);
        setPauseStartTime(Date.now());
        setShowPauseModal(true);
        setIsPaused(true); // Marcar el estado como pausado
      }
    } catch (error) {
      console.error("Error al pausar:", error);
    }
  };

  const handlePlay = async () => {
    const body = {
      id: pauseTimeId,
      counter_checkout_id: localStorage.getItem("counterId"),
      user_id: localStorage.getItem("light-load-email"),
    };
    try {
      // Realizar la petición a la API al reanudar
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/record_time`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        // Detener el cronómetro
        setPauseTimeId(null);
        setElapsedPauseTime(0);
        setShowPauseModal(false);
        setIsPaused(false); // Marcar el estado como reanudado
      }
    } catch (error) {
      console.error("Error al reanudar:", error);
    }
  };

  const resetDocumenter = () => {
    setTimeout(false);
    setResetCounter((prev) => !prev);
  };

  const callTurn = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/turn_scream/${turnAsing.turn_number}`,
        { counter_checkout_id: localStorage.getItem("counterId") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        Swal.fire({
          title: "Turno Voceado",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const reasingTurn = async () => {
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/reasign_turn/${turnAsing.turn_counter_checkouts_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        setTurnAsing({});
        setLoading(true);
        setTimeout(false);
        setResetCounter((prev) => !prev);
        setIsPaused(false);
      } else {
        throw new Error(res);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/turns`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        setData(res.data.turns);
        await callTurn();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  //para mostrar el modal
  const handleOpenIteration = (id) => {
    setSelectedId(id); // Configura el ID dinámico
  };

  //vista mostrador
  const isCounter =
    location.pathname === "/amc-domestico/light-load/approved-registration";

  const getCounters = async () => {
    setIsLoadingCounters(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/select_counter_checkout`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        setCounters(res.data.data.counter_checkouts);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCounters(false);
    }
  };

  const selectCounter = (id) => {
    setIdCounterSelected(id);
  };

  const registerCounert = async () => {
    if (!idCounterSelected) {
      Swal.fire({
        title: "Por favor seleccione un mostrador",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ok",
      });
    } else {
      const body = {
        counter_checkout_id: idCounterSelected,
        user_id: localStorage.getItem("light-load-email"),
      };

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/select_counter_checkout`,
          body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "light-load-token"
              )}`,
            },
          }
        );
        if (res.data.code === 1) {
          setIsCounterSelected(true);
          localStorage.setItem("counterId", idCounterSelected);
        }
      } catch (error) {
        Swal.fire({
          title: "El mostrador ya esta asignado a un usuario",
          icon: "warning",
        });
        await getCounters();
        console.error(error);
      }
    }
  };

  const finishCounter = async () => {
    if (!idCounterSelected) return;

    const body = {
      user_id: localStorage.getItem("light-load-email"),
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/counter/select_counter_finish/${idCounterSelected}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        setOpenDropdown(false);
        setIdCounterSelected("");
        setIsCounterSelected(false);
        localStorage.removeItem("counterId");
        getCounters();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const exitCounter = async () => {
    if (!idCounterSelected) return;

    const result = await Swal.fire({
      title: "¿Quieres salir de este mostrador?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    });

    if (!result.isConfirmed) return;

    await finishCounter();
  };

  const logOut = async () => {
    if (idCounterSelected) {
      await finishCounter(); // Ejecuta directamente la lógica sin preguntar
    }
    localStorage.clear();
    navigate("/amc-domestico/light-load/");
  };

  useEffect(() => {
    getCounters();
  }, []);

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} de ${monthName} ${year}`;
    }

    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }

    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeCounter();
  }, []);

  useEffect(() => {
    if (
      Object.keys(turnAsing).length > 0 &&
      turnAsing.turn_number !== "No hay turnos en espera"
    ) {
      getData();
    }
  }, [turnAsing]);

  useEffect(() => {
    let interval;

    if (showPauseModal) {
      interval = setInterval(() => {
        setElapsedPauseTime((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showPauseModal]);

  useEffect(() => {
    const counterId = localStorage.getItem("counterId");
    if (counterId) {
      setIsCounterSelected(true);
      setIdCounterSelected(counterId);
    }
  }, []);

  return (
    <>
      {isCounterSelected ? (
        <div className="amc-Approved-container">
          <div className="amc-Approved-header">
            <div className="amc-Approved-header-img">
              <div className="logo">
                <img src={amcLogo} alt="Logo" />
              </div>
              <div className="lines_pink">
                <img src={pink_lines} alt="lines" />
              </div>
            </div>
            <div className="amc-Approved-header-hours">
              <div className="hours">
                <span>{currentDate}</span>
                <span>{currentTime}</span>
              </div>
              <div className="user">
                <div className="username">
                  <span>Usuario: {userName}</span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenDropdown}
                  >
                    ▼
                  </span>
                </div>
                {openDropdown && (
                  <div className="dropdownMenu">
                    <ul>
                      {isCounter && (
                        <li>
                          <button
                            type="button"
                            className="exit-btn"
                            onClick={exitCounter}
                          >
                            Salir del mostrador <IoMdExit />
                          </button>
                        </li>
                      )}
                      <li>
                        <button
                          type="button"
                          className="exit-btn"
                          onClick={logOut}
                        >
                          Cerrar sesion <IoMdExit />
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="amc-Approved-body">
            {/* Registros */}
            <div className="amc-Approved-body-content">
              <div className="title">
                <p>Documentación</p>
              </div>
              <div className="search">
                <div className="inputContainer">
                  <span>
                    <VscSearch />
                  </span>
                  <input
                    type="text"
                    placeholder="Buscar por número de turno"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className="records">
                <table className="records-table">
                  <thead>
                    <tr className="table-header">
                      <th className="table-header-cell"># Turno</th>
                      <th className="table-header-cell">
                        Nombre consignatario
                      </th>
                      <th className="table-header-cell"></th>
                      <th className="table-header-cell"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <>
                        <td></td>
                        <td>
                          <div className="loading-spinner">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">
                                Cargando...
                              </span>
                            </Spinner>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                      </>
                    ) : (
                      filteredData
                        .sort((a, b) => {
                          if (a.number === turnAsing.turn_number) return -1;
                          if (b.number === turnAsing.turn_number) return 1;
                          return 0;
                        })
                        .slice(0, 6)
                        .map((turno, index) => (
                          <tr key={turno.id}>
                            <td
                              className={`table-cell ${
                                index === 0 ? "highlight" : ""
                              } first-cell`}
                            >
                              {turno.number}
                            </td>

                            <td
                              className={`table-cell ${
                                index === 0 ? "highlight" : ""
                              } middle-cell`}
                            >
                              {turno.shipments[0].name}
                            </td>

                            <td className="spacing-cell"></td>

                            <td className="info-cell">
                              <div className="info-button-container">
                                <button
                                  className="info-button"
                                  disabled={index !== 0}
                                  style={
                                    index !== 0
                                      ? {
                                          backgroundColor: "#d3d3d3",
                                          cursor: "not-allowed",
                                        }
                                      : {}
                                  }
                                  onClick={() => {
                                    handleOpenIteration(turno.id);
                                  }}
                                >
                                  i
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* Contador */}
            <div className="amc-Approved-body-timer">
              <div className="next-turn">
                <h3>{timeout ? "Turno Asignado" : "Siguiente Turno en ..."}</h3>
              </div>
              <div className="time">
                {timeout ? (
                  <p
                    className="turn-asing"
                    style={
                      turnAsing.turn_number === "No hay turnos en espera"
                        ? { fontSize: "2rem" }
                        : {}
                    }
                  >
                    {turnAsing.turn_number || ""}
                  </p>
                ) : (
                  time > 0 && (
                    <CountdownTimer
                      initialTime={time}
                      setTimeout={setTimeout}
                      isPaused={isPaused}
                      onReset={resetCounter}
                      setTurnAsing={setTurnAsing}
                    />
                  )
                )}
              </div>
              {timeout ? (
                <div className="acttions-timeOut">
                  {turnAsing.turn_number === "No hay turnos en espera" ? (
                    <button className="finished" onClick={resetDocumenter}>
                      Recargar
                    </button>
                  ) : turnAsing.turn_number && turnAsing.turn_number !== "" ? (
                    <>
                      <button
                        className="finished"
                        onClick={() =>
                          handleFinishTurn(turnAsing?.turn_counter_checkouts_id)
                        }
                      >
                        Finalizar Turno
                      </button>

                      <button className="vocear" onClick={callTurn}>
                        Vocear Turno
                      </button>
                      {turnAsing.number_reassign &&
                      turnAsing.number_reassign < 2 ? (
                        <button className="vocear" onClick={reasingTurn}>
                          Reasignar Turno
                        </button>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="actions">
                  <div className="play_pause">
                    <button
                      className="btn-play_pause"
                      onClick={handlePausePlay}
                    >
                      <FaPause className="HiMiniPlayPause" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="amc-Approved-container">
          <div className="amc-Approved-header">
            <div className="amc-Approved-header-img">
              <div className="logo">
                <img src={amcLogo} alt="Logo" />
              </div>
              <div className="lines_pink">
                <img src={pink_lines} alt="lines" />
              </div>
            </div>
            <div className="amc-Approved-header-hours">
              <div className="hours">
                <span>{currentDate}</span>
                <span>{currentTime}</span>
              </div>
              <div className="user">
                <div className="username">
                  <span>Usuario: {userName}</span>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenDropdown}
                  >
                    ▼
                  </span>
                </div>
                {openDropdown && (
                  <div className="dropdownMenu">
                    <ul>
                      <li>
                        <button
                          type="button"
                          className="exit-btn"
                          onClick={logOut}
                        >
                          Cerrar sesion <IoMdExit />
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="amc-Approved-body">
            <div className="amc-Approved-body-counters">
              <div className="title">
                <p>Mostradores Carga Ligera</p>
              </div>
              <div className="counters">
                {isLoadingCounters ? (
                  <div className="loading-spinner">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Cargando...</span>
                    </Spinner>
                  </div>
                ) : counters.length > 0 ? (
                  counters.map((counter, _index) => (
                    <div
                      key={_index}
                      className={`counter ${
                        idCounterSelected === counter.id ? "active" : ""
                      }`}
                      onClick={() => {
                        selectCounter(counter.id);
                      }}
                    >
                      {counter.name.replace(/.*\s(\d+)$/, "$1")}
                    </div>
                  ))
                ) : (
                  <h1>No hay mostradores disponibles</h1>
                )}
              </div>
            </div>

            <div className="amc-Approved-body-btn">
              <div className="counter-btn-container">
                <button className="next-btn" onClick={registerCounert}>
                  Siguiente &#x276f;
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedId && (
        <ModalInformations
          id={selectedId}
          onClose={() => setSelectedId(null)}
          getData={getData}
        />
      )}

      <Modal
        show={showPauseModal}
        onHide={() => {}} // Desactiva la acción de cerrar con "onHide"
        backdrop="static" // Evita que el modal se cierre al hacer clic fuera
        keyboard={false} // Evita que se cierre al presionar "Escape"
        centered
      >
        <Modal.Header
          style={{
            backgroundImage: "none",
            borderBottom: "none",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <p className="countUp-header">Tiempo transcurrido</p>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundImage: "none",
            borderBottom: "none",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <div className="countUp">
            <p>
              {Math.floor(elapsedPauseTime / 60)}:
              {String(elapsedPauseTime % 60).padStart(2, "0")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          className="footer-mod"
          style={{
            backgroundImage: "none",
            borderTop: "none",
          }}
        >
          <Button className="btn-resumen" onClick={handlePlay}>
            Reanudar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ApprovedRegistration;
