import { React, useState, useEffect } from "react";
import Footer from '../../components/footer/Footer'
import ScrollToTop from '../../components/scrollToTop/scrollToTop'
import { Helmet } from 'react-helmet'
import './landing.css';
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Form from 'react-bootstrap/Form';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import pinklines from '../../assets/imgs/landing-page/pink-lines.png'
import zocalo from '../../assets/imgs/landing-page/zocalo.jpg'
import airplane from '../../assets/imgs/landing-page/airplane.jpg'
import capa1 from '../../assets/imgs/landing-page/group4.svg'
import delivery from '../../assets/imgs/landing-page/delivery.jpg'
import northamerica from '../../assets/imgs/landing-page/northamerica.svg'
import southamerica from '../../assets/imgs/landing-page/southamerica.svg'
import europe from '../../assets/imgs/landing-page/europa.svg'
import asia from '../../assets/imgs/landing-page/asia.svg'
import africa from '../../assets/imgs/landing-page/africa.svg'
import australia from '../../assets/imgs/landing-page/australia.svg'
import Marquee from 'react-fast-marquee';

import National from "./services/national";
import International from "./services/international";
import Additional from "./services/additional";
import DestinationAndCoverage from "./destinations-and-coverage/main/main";
import ShippingProcess from "./shipping-process/shipping-process";
import Contact from "./contact/contact";

import amcPlane from "../../assets/imgs/landing-page/aeromexico_new_cover.jpg";
import defaultBanner from "../../assets/imgs/landing-page/banner_prueba.png";

const Landing = () => {

  const [tLanding] = useTranslation("landing")
  const [tContact] = useTranslation("contact");
  const [banners, setBanners] = useState([])

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(true);
  const [successMessage, setSuccessMessage] = useState(true);
  const navigate = useNavigate();
  // Estado para controlar la visibilidad del contenido adicional
  const [selectedService, setSelectedService] = useState(null);

  const [activeButton, setActiveButton] = useState(
    localStorage.getItem("lng") || "es"
  );

  // Función para manejar el clic en el botón
  const handleButtonClick = (service) => {
    setSelectedService(prevService => (prevService === service ? null : service));
  };

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleQuoteSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL_BACKEND}/api/contact/create`,
        {
          first_name: data.name,
          last_name: ".",
          email: data.email,
          phone: data.phone,
          type_shipment: "4",
          reason_contact: "8",
          message: "Hola, me gustaría recibir más información. Me estoy contactando desde la landig",
          status: 2
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: process.env.REACT_APP_PUBLIC_KEY,
          },
        }
      );

      setLoading(true);
      if (response.data.code === 1) {
        navigate(`/${tContact("route.route")}`, {
          state: { statusThanks: "true" },
        });
      }
      else if (response.data.code === 0) {
        navigate(`/${tContact("route.route")}`, {
          state: { statusThanks: "true" },
        });
      }

    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      navigate(`/${tContact("route.route")}`, {
        state: { statusThanks: "false" },
      });
    }
  }

  const validateForm = () => {
    const {
      name,
      email,
      phone,
    } = formData;
    const isNameValid = name !== "";
    const isEmailValid = email !== "";
    const isPhoneValid = phone !== "";
    setIsFormValid(
      isNameValid &&
        isEmailValid &&
        isPhoneValid
    );
  };

  const getBanners = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/api/banners`, {
        headers: {
          authorization: process.env.REACT_APP_PUBLIC_KEY,
        },
      });

      if (res.data.code === 1){
        setBanners(res.data.banners)
        setLoading(false);
      }
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBanners();
    validateForm();
  }, [formData]);

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>Landing</title>
        <meta name='' content='' />
      </Helmet>
      {/*Carrusel de banners principales - desktop*/}
      {/*<div className="banner-slider-desktop d-none">
      <Carousel controls={false} indicators={true} className='bg-landing'>
        {banners
          .sort((a, b) => a.posicion - b.posicion) // Ordenar por la propiedad 'posicion'
          .map((banner, index) => (
            (banner.landing === "2" || banner.landing === "3") && ( // Validar que el landing sea 1 o 3S
              <Carousel.Item key={banner.id} className="carousel-item-container">
                {banner.link !== "" || banner.link_en !== "" ? (
                  <Carousel.Caption className='h-100 d-flex align-items-center'>
                      <div className='row text-start border border-danger'>
                        <div className='col-lg-5 p-3 mt-4 position-relative pe-4 pe-md-5 pe-sm-3 border' 
                          style={{
                            backgroundImage: `url(${activeButton === "es" 
                              ? banner.image
                              : banner.image_en
                            })`,
                            backgroundSize:'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'left center',
                            backgroundColor: window.innerWidth < 768 ?'transparent':'transparent',
                          }}
                        >
                          <p className='fs-3 font-semibold text-transparent'>{tLanding("landing.explore")}</p>
                          <p className='text-transparent'>ssssssssss{tLanding("landing.efficiency")}</p>
                          <Link to={activeButton === "es" ? banner.link : banner.link_en} id='' className="land-z-1">
                            <button className='btn-pink-inverted' >{tLanding("landing.btn-know")}</button>
                          </Link>
                        </div>
                      </div>
                  </Carousel.Caption>
                ) : (
                  <Carousel.Caption className='h-100 d-flex align-items-center'>
                      <div className='row text-start'>
                        <div className='col-lg-8 bg-premium-blue p-3 mt-4 position-relative pe-4 pe-md-5 pe-sm-3' 
                          style={{
                            backgroundImage: `url(${activeButton === "es" ? banner.image : banner.image_en})`,
                            backgroundSize:'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundColor: window.innerWidth < 768 ?'transparent':'#020B41',
                          }}
                        >
                          <p className='fs-3 font-semibold text-transparent'>ssssssssss{tLanding("landing.explore")}</p>
                          <p className='text-transparent'>{tLanding("landing.efficiency")}</p>
                        </div>
                      </div>
                  </Carousel.Caption>
                )}
              </Carousel.Item>
            )
          ))}
        <Carousel.Item className="carousel-item-container">
          <Carousel.Caption className='h-100 d-flex align-items-center'>
              <div className='row text-start'>
                <div className='col-lg-8 bg-premium-blue p-3 mt-4 position-relative pe-4 pe-md-5 pe-sm-3'>
                  <img className='position-absolute end-0 bottom-0 h-100 d-none d-lg-block' src={capa1} alt='pinklines' />
                  <img className="position-absolute end-0 bottom-0 h-100 d-block d-lg-none" src={capa1} alt="pinklines" style={{width:'30px'}} />
                  <p className='fs-3 font-semibold text-white'>{tLanding("landing.explore")}</p>
                  <p className='text-white'>{tLanding("landing.efficiency")}</p>
                  <Link to='' id=''>
                    <button className='btn-pink'>{tLanding("landing.btn-know")}</button>
                  </Link>
                </div>
              </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-container">
          <Carousel.Caption className='h-100 d-flex align-items-center'>
              <div className='row text-start'>
                <div className='col-lg-8 bg-premium-blue p-3 mt-4 position-relative pe-4 pe-md-5 pe-sm-3'>
                  <img className='position-absolute end-0 bottom-0 h-100 d-none d-lg-block' src={capa1} alt='pinklines' />
                  <img className="position-absolute end-0 bottom-0 h-100 d-block d-lg-none" src={capa1} alt="pinklines" style={{width:'30px'}} />
                  <p className='fs-3 font-semibold text-white'>{tLanding("landing.explore")}</p>
                  <p className='text-white'>{tLanding("landing.efficiency")}</p>
                  <Link to='' id=''>
                    <button className='btn-pink'>{tLanding("landing.btn-know")}</button>
                  </Link>
                </div>
              </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-container">
          <Carousel.Caption className='h-100 d-flex align-items-center'>
              <div className='row text-start'>
                <div className='col-lg-8 bg-premium-blue p-3 mt-4 position-relative pe-4 pe-md-5 pe-sm-3'>
                  <img className='position-absolute end-0 bottom-0 h-100 d-none d-lg-block' src={capa1} alt='pinklines' />
                  <img className="position-absolute end-0 bottom-0 h-100 d-block d-lg-none" src={capa1} alt="pinklines" style={{width:'30px'}} />
                  <p className='fs-3 font-semibold text-white'>{tLanding("landing.explore")}</p>
                  <p className='text-white'>{tLanding("landing.efficiency")}</p>
                  <Link to='' id=''>
                    <button className='btn-pink'>{tLanding("landing.btn-know")}</button>
                  </Link>
                </div>
              </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      </div>*/}

      {/*Carrusel de banners principales (usando img en ves de backgroundImage)*/}
      <div className="banner-slider-desktop">
        <Carousel controls={false} indicators={true} className='bg-landing'>
          {banners
            .sort((a, b) => a.posicion - b.posicion) // Ordenar por la propiedad 'posicion'
            .map((banner, index) => (
              (banner.landing === "2" || banner.landing === "3") && ( // Validar que el landing sea 1 o 3S
                <Carousel.Item key={banner.id} className="carousel-item-container">
                  {banner.link !== "" || banner.link_en !== "" ? (
                    <Carousel.Caption className='h-100 d-flex align-items-center'>
                      <div className='row text-start position-relative banner-container-desktop w-100'>
                        <div className='col-sm-6 col-lg-4 banner-img-container'>
                          <img src={defaultBanner} />
                        </div>
                        <Link to={activeButton === "es" ? banner.link : banner.link_en} id='' className="land-z-1">
                          <button className='btn-pink-inverted btn-banner-desktop' >{tLanding("landing.btn-know")}</button>
                        </Link>
                      </div>
                    </Carousel.Caption>
                  ) : (
                    <Carousel.Caption className='h-100 d-flex align-items-center'>
                      <div className='row text-start position-relative banner-container-desktop w-100'>
                        <div className='col-sm-6 col-lg-4 banner-img-container'>
                          <img src={defaultBanner} />
                        </div>
                      </div>
                    </Carousel.Caption>
                  )}
                </Carousel.Item>
              )
            ))
          }
        </Carousel>
      </div>

      {/*Carrusel de banners principales - mobile*/}
      <div className="banner-slider-mobile">
        <Carousel controls={false} indicators={true} className='mobile-banner-carousel'>
        {banners
          .sort((a, b) => a.posicion - b.posicion)
          .map((banner, index) => (
            (banner.landing === "2" || banner.landing === "3") && (
              <Carousel.Item key={banner.id}>
                {banner.link !== "" || banner.link_en !== "" ? (
                <div className="mobile-banner">
                  <img 
                    src={activeButton === "es" ? banner.image : banner.image_en} 
                    alt="Banner"
                    className="mobile-banner-img"
                  />
                  <Link to={activeButton === "es" ? banner.link : banner.link_en} id='' className="land-z-1">
                    <button className='btn-pink-inverted mobile-banner-button'>{tLanding("landing.btn-know")}</button>
                  </Link>
                </div>
                ) : (
                <div className="mobile-banner">
                  <img 
                    src={activeButton === "es" ? banner.image : banner.image_en} 
                    alt="Banner"
                    className="mobile-banner-img"
                  />
                </div>
                )}
              </Carousel.Item>
            )
          ))}
        </Carousel>

         {/* Imagen fija del avion debajo del slider */}
        <div className="mobile-fixed-image">
          <img src={amcPlane} alt="Avión Aeromexico Cargo" />
        </div>
      </div>

      {/*Nuestros Servicios*/}
      <div className='container-fluid'>
        <div className='container py-4 py-md-5'>
          <div className='text-center pb-md-5'>
            <p className='mb-0 mb-md-3 fs-2 font-semibold text-premium-light'>{tLanding("landing.services")}</p>
            {/* <Link to="/" id='' className='d-none d-md-block'>
              <Button
                variant="primary"
                style={{
                  fontFamily: 'Garnett-Regular, sans-serif',
                  fontWeight: 'bold',
                  height: '50px',
                }}>
                  {tLanding("landing.btn-services")}
              </Button>
            </Link> */}
          </div>
          <div className='text-white'>
            <div className="row justify-content-center gx-5">
              <div className='col-12 col-md-3 py-3 service-card d-flex flex-column'>
                <div className='mb-md-5 mb-3'>
                  <img className='img-services img-fluid d-none d-md-block' src={zocalo} alt="Imagen del zocalo" />
                </div>
                
                {/*Diseño de titulo para pantallas grandes*/}
                <p className='text-gris-premium d-none d-md-block title fw-bold'>{tLanding("landing.national")}</p>
                {/*Diseño de titulo para pantallas chicas*/}
                <div className='row align-items-center d-flex d-md-none mb-3'>
                  <div className='col-4 text-start'>
                    <img className='img-services img-fluid' src={zocalo} alt="Imagen del zocalo" />
                  </div>
                  <div className='col-8'>
                    <p className='text-gris-premium font-semibold mt-3'>{tLanding("landing.national")}</p>
                  </div>
                </div>
                <p className='text-gris-premium service-description flex-grow-1'>{tLanding("landing.national-text")}</p>
                <div className="mt-auto">            
                  <button className='btn-pink' onClick={() => handleButtonClick("nacionales")}>
                    {tLanding("landing.btn-know")}
                  </button>
                </div>
                <div className='d-sm-block d-md-none' style={{borderTop: '1px solid #d1d1d1', marginTop: '15px'}}></div>
              </div>
              <div className='d-none d-md-block col-auto align-self-end pb-3'>
                  <img className="img-fluid" src={pinklines} alt="Líneas rosas" style={{width:'28px'}} />
              </div>
              
              <div className='col-12 col-md-3 py-3 service-card d-flex flex-column'>
                <div className='mb-md-5 mb-3'>
                  <img className='img-services img-fluid d-none d-md-block' src={airplane} alt="Imagen de avion" />
                </div>
                {/*Diseño de titulo para pantallas grandes*/}
                <p className='text-gris-premium d-none d-md-block title fw-bold'>{tLanding("landing.international")}</p>
                {/*Diseño de titulo para pantallas chicas*/}
                <div className='row align-items-center d-flex d-md-none mb-3'>
                  <div className='col-4 text-start'>
                    <img className='img-services img-fluid' src={airplane} alt="Imagen de avion" />
                  </div>
                  <div className='col-8'>
                    <p className='text-gris-premium font-semibold mt-3'>{tLanding("landing.international")}</p>
                  </div>
                </div>
                <p className='text-gris-premium service-description'>{tLanding("landing.international-text")}</p>
                <div className="mt-auto">
                  <button className='btn-pink' onClick={() => handleButtonClick("internacionales")}>
                    {tLanding("landing.btn-know")}
                  </button>
                </div>
                <div className='d-sm-block d-md-none' style={{borderTop: '1px solid #d1d1d1', marginTop: '15px'}}></div>
              </div>
              <div className='d-none d-md-block col-auto align-self-end pb-3'>
                  <img className="img-fluid" src={pinklines} alt="Líneas rosas" style={{width:'28px'}} />
              </div>

              <div className='col-12 col-md-3 py-3 service-card d-flex flex-column'>
                <div className='mb-md-5 mb-3'>
                  <img className='img-services img-fluid d-none d-md-block' src={delivery} alt="Imagen de repartidor" />
                </div>
                {/*Diseño de titulo para pantallas grandes*/}
                <p className='text-gris-premium d-none d-md-block title fw-bold'>{tLanding("landing.additional")}</p>
                {/*Diseño de titulo para pantallas chicas*/}
                <div className='row align-items-center d-flex d-md-none mb-3'>
                  <div className='col-4 text-start'>
                    <img className='img-services img-fluid' src={delivery} alt="Imagen de repartidor" />
                  </div>
                  <div className='col-8'>
                    <p className='text-gris-premium font-semibold mt-3'>{tLanding("landing.additional")}</p>
                  </div>
                </div>
                <p className='text-gris-premium service-description'>{tLanding("landing.additional-text")}</p>
                <div className="mt-auto">
                  <button className='btn-pink' onClick={() => handleButtonClick("adicionales")}>
                    {tLanding("landing.btn-know")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Renderiza el componente correspondiente según el botón clickeado */}
      <div className='services'>
        {selectedService === "nacionales" && <National />}
        {selectedService === "internacionales" && <International />}
        {selectedService === "adicionales" && <Additional />}
      </div>

      {/*Destinos y Cobertura*/}
      <DestinationAndCoverage />

      <div className="">
        <ShippingProcess />

        <Contact />
      </div>

      <Footer />
    </>
  )
}

export default Landing