import { useEffect, useState } from "react";
import "./shifts-collection.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { HiOutlineX } from "react-icons/hi";

function ShiftsCollection () {
  const [shiftData, setShiftData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const {
      handleSubmit,
      register,
      reset,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      defaultValues: {
        counter_status: "",
        search_status: "",
        departure_status: "",
        id: "",
      },
    });

  useEffect(() => {
    getShifts();
  }, []);

  const getShifts = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/collection`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setShiftData(response.data.operations);
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al consultar, por favor intentalo de nuevo",
        icon: "error",
      });
    }
  };

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  const handleOpenForm = async (id) => {
    reset({
      counter_status: "",
      search_status: "",
      departure_status: "",
      id: "",
    });
    try {
      setShowModal(true);
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/collection/${id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log(response.data.data.turn);

      reset({
        counter_status: response.data.data.turn.counter_status,
        search_status: response.data.data.turn.search_status,
        departure_status: response.data.data.turn.departure_status,
        id: id,
      });
    } catch (error) {
      console.error(error);
      setShowModal(false);
      Swal.fire({
        title: "Error",
        text: "Error al mostrarla información",
        icon: "error",
      });
    }
  };

  const handleUpdateShiftForm = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_ADMIN}/collection/${data.id}`;
      const body = {
        counter_status: data.counter_status,
        search_status: data.search_status,
        departure_status: data.departure_status,
      };

      const response = await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setShowModal(false);
      Swal.fire({
        title: "Exito",
        text: "Datos actualizados correctamente",
        icon: "success",
      });
      await getShifts();
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: "Error",
        text: "Error al actualizar los datos, por favor verifique los campos",
        icon: "error",
      });
      setShowModal(false);
    }
  };

  const getStatus = (id) => {
    switch (id) {
      case 1:
        return "Etiqueta emitida";
        break;
      case 2:
        return "Pesaje";
        break;
      case 3:
        return "Documentado";
        break;
      case 4:
        return "Finalizado";
        break;
      default:
        return "";
        break;
    }
  };

  const setSearchStatus = (status) => {
    switch (status) {
      case "1":
        return "Buscando"
      case "2":
        return "Encontrado"
      case "3":
        return "Encontrado parcial"
      case "4":
        return "No encontrado"
      default:
        return ""
    }
  }

  const setCounterStatus = (status) => {
    switch (status) {
      case "1":
        return "Aceptado"
      case "0":
        return "No aceptado"
      default:
        return "-----"
    }
  }

  const setDepartureStatus = (status) => {
    switch (status) {
      case "1":
        return "Salida"
      case "2":
        return "Regresado"
      case "3":
        return "Pendiente"
      default:
        return "-----"
    }
  }

  const renderModalForm = () => (
    <>
      <form
        className="form-shift-update"
        onSubmit={handleSubmit(handleUpdateShiftForm)}
      >
        <div className="input-container-shift">
          <label>Status del buscador:</label>
          <select id="counter_status" {...register("counter_status")}>
              <option key="0" value="0">
                No aceptado
              </option>
              <option key="1" value="1">
                Aceptado
              </option>
          </select>
        </div>
        <div className="input-container-shift">
          <label>Status del buscador:</label>
          <select id="search_status" {...register("search_status")}>
              <option key="1" value="1">
                Salidas
              </option>
              <option key="2" value="2">
                Regresado
              </option>
              <option key="3" value="3">
                Encontrado parcial
              </option>
              <option key="4" value="4">
                No encontrado
              </option>
          </select>
        </div>
        <div className="input-container-shift">
          <label>status de salidas:</label>
          <select id="departure_status" {...register("departure_status")}>
              <option key="1" value="1">
                Buscando
              </option>
              <option key="2" value="2">
                Encontrado
              </option>
              <option key="3" value="3">
                Pendiente
              </option>
          </select>
        </div>
        <input
          id="id"
          type="text"
          placeholder=""
          {...register("id")}
          autoComplete="off"
          style={{ display: "none" }}
        />

        <div className="btns-container">
          <button
            className="cancel-btn"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Cancelar
          </button>
          <button className="send-btn" type="submit">
            Enviar
          </button>
        </div>
      </form>
    </>
  );

  return (
    <>
      <div className="table-responsive table-content-shift">
        <table className="table table-preve" id="tableToSearch">
          <thead>
            <tr>
              <th># Turno</th>
              <th>Cliente / Guias</th>
              <th>Fecha de admision</th>
              <th>Usuario creador</th>
              <th>Empleado al que se le asigno turno</th>
              <th>Status buscador</th>
              <th>Fecha estatus buscador</th>
              <th>Status mostrador</th>
              <th>Fecha asignacion status</th>
              <th>Usuario que asigno status</th>
              <th>Estatus de salida</th>
              <th>usuario que asigno status de salida</th>
              <th>fecha de asignacion de status</th>
              <th>Editar</th>
            </tr>
          </thead>

          <tbody>
            {shiftData.map((shift, index) => (
              <tr key={index}>
                <td style={{ minWidth: "100px" }}>{shift.turn_number}</td>
                <td style={{ minWidth: "130px" }}>{shift.customer ? shift.customer : shift.awbs.map((awbs, index) => (<p>{awbs}</p>))}</td>
                <td style={{ minWidth: "100px" }}>{shift.date_create ? shift.date_create : <p className="txt-center">-----</p>}</td>
                <td style={{ minWidth: "150px" }}>
                  {shift.user_create ? shift.user_create : <p className="txt-center">-----</p>}
                </td>
                <td style={{ minWidth: "100px" }}>{shift.employee_name ? shift.employee_name : <p className="txt-center">-----</p>}</td>
                <td style={{ minWidth: "150px" }}>{shift.search_status ? setSearchStatus(shift.search_status) : <p className="txt-center">-----</p>}</td>
                <td style={{ minWidth: "180px" }}>{shift.date_search ? shift.date_search : <p className="txt-center">-----</p>}</td>
                <td style={{ minWidth: "150px" }}>{<p className="txt-center">{setCounterStatus(shift.counter_status)}</p>}</td>
                <td style={{ minWidth: "150px" }}>{shift.date_counter ? shift.date_counter : <p className="txt-center">-----</p>}</td>
                <td style={{ minWidth: "180px" }}>{shift.user_counter ? shift.user_counter : <p className="txt-center">-----</p>}</td>
                <td style={{ minWidth: "150px" }}><p className="txt-center">{setDepartureStatus(shift.departure_status)}</p>
                </td>
                <td style={{ minWidth: "150px" }}>{shift.user_departure ? shift.user_departure : <p className="txt-center">-----</p>}</td>
                <td style={{ minWidth: "200px" }}>{shift.date_departure ? shift.date_departure : <p className="txt-center">-----</p>}</td>
                <td className="btns-container-actions">
                  <button
                    className="btn-action-shift"
                    onClick={() => {
                      handleOpenForm(shift.id);
                    }}
                  >
                    Editar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div
          className="modal-window-content"
          onClick={() => setShowModal(false)}
        >
          <div className="modal-window" onClick={handleModalClick}>
            <div className="header-modal">
              <div></div>
              <div className="title">{"Actualizar"}</div>
              <div className="close-btn" onClick={() => setShowModal(false)}>
                <HiOutlineX />
              </div>
            </div>
            {showModal && renderModalForm()}
          </div>
        </div>
      )}
    </>
  )
}

export default ShiftsCollection;