import "./seeker.css";
import amc_logo from "../../../../../assets/imgs/amc-domestico/logo_AMC.png";
import {
  FaCircleCheck,
  FaCheck,
  FaRegCircleQuestion,
  FaCircleExclamation,
  FaAngleLeft,
  FaClock,
  FaChevronRight,
} from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { Button, Modal } from "react-bootstrap";

function Seeker() {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [turnsAwait, setTurnsAwait] = useState(0);
  const [seekerArr, setSeekerArr] = useState([]);
  const [currentSeeker, setCurrentSeeker] = useState({});
  const [statusModal, setStatusModal] = useState({ status: false, modal: "" });
  const {
    register: registerFormId,
    reset: resetFormId,
    handleSubmit: handleSubmitForm1,
  } = useForm({ mode: "onChange" });
  const {
    register: registerFormJustification,
    reset: resetFormJustification,
    handleSubmit: handleSubmitForm2,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    function getFormattedDate() {
      const days = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      const months = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];

      const now = new Date();
      const dayName = days[now.getDay()];
      const day = now.getDate();
      const monthName = months[now.getMonth()];
      const year = now.getFullYear();

      return `${dayName}, ${day} de ${monthName} ${year}`;
    }

    function getFormattedTime() {
      const now = new Date();
      let hours = now.getHours(); // Mantén las horas tal cual están en formato de 24 horas
      const minutes = now.getMinutes().toString().padStart(2, "0");

      // Devuelve el tiempo en formato 24 horas
      return `${hours}:${minutes}`;
    }

    const interval = setInterval(() => {
      setCurrentDate(getFormattedDate());
      setCurrentTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getTurns();
    getSeekers();
  }, []);

  const getTurns = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/search/turn_await`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
        },
      });
      setTurnsAwait(response.data.data.turns_await);
    } catch (error) {
      console.error("error");
    }
  };

  const getSeekers = async () => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/search`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
        },
      });

      setSeekerArr(response.data.turns);
    } catch (error) {
      console.error("error", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrio un error al mostrar los datos.",
        icon: "error",
      });
    }
  };

  const updateStatus = async (id, status) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/search/update_status/${id}`;
      const body = {
        status: status,
        user_id: localStorage.getItem("collection-token"),
      };
      const response = await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
        },
      });
      await getSeekers();
      await getTurns();
    } catch (error) {
      console.error("error", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrio un error al actualizar el status.",
        icon: "error",
      });
    }
  };

  const getDetails = async (id) => {
    try {
      setStatusModal({ status: true, modal: "information" });

      if (id !== undefined) {
        const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/search/${id}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
          },
        });

        setCurrentSeeker(response.data.data.turn);
      }
    } catch (error) {
      console.error("error", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrio un error al obtener los detalles.",
        icon: "error",
      });
    }
  };

  const setJustificationForm = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/search/description/${currentSeeker}`;
      const body = {
        description: data.justification,
        user_id: localStorage.getItem("collection-token"),
      };
      const response = await axios.put(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
        },
      });

      closeModal();
      resetFormJustification();
      await getTurns();
      await getSeekers();
    } catch (error) {
      console.log("error", error);
    }
  };

  const getJustificationInfo = async (id) => {
    try {
      setStatusModal({ status: true, modal: "justification" });
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/search/description/${id}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
        },
      });

      setCurrentSeeker({
        description: response.data.data.turn_collection.description,
      });
    } catch (error) {
      console.error("error", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrio un error al obtener los detalles.",
        icon: "error",
      });
    }
  };

  const setJustificationInfo = async (id) => {
    try {
      setStatusModal({ status: true, modal: "setJustification" });
      setCurrentSeeker(id);
    } catch (error) {
      console.error("error", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrio un error al agregar la información",
        icon: "error",
      });
    }
  };

  const setBg = (bg, status) => {
    if (bg === "status") {
      switch (status) {
        case "1":
          return "seek-bg-blue";
        case "2":
          return "seek-bg-green";
        case "3":
          return "seek-bg-orange";
        case "4":
          return "seek-bg-red";
        default:
          return "seek-bg-blue";
          break;
      }
    }
  };

  const closeModal = () => {
    setStatusModal({ status: false, modal: "" });
    setCurrentSeeker({});
    resetFormJustification();
    resetFormId();
  };

  const seekerSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_COLLECTION}/search/turn_assign/${data.id_employee}`;

      const body = {
        user_id: localStorage.getItem("collection-email"),
      };

      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("collection-token")}`,
        },
      });

      if(response.data.message === "Numero de empleado no existe") {
        Swal.fire({
          title: "Error",
          text: "El número de empleado no existe",
          icon: "info",
        });
        resetFormId();
        return;
      }
      if (response.data.message === "No es posible asignar un turno al empleado") {
        Swal.fire({
          title: "Error",
          text: "No es posible asignar un turno al empleado",
          icon: "info",
        });
        resetFormId();
        return;
      }

      setCurrentSeeker(response.data.data);
      await getDetails();
      await getTurns();
      resetFormId();
    } catch (error) {
      if (
        error.response.data.message === "Error al obtener el siguiente turno"
      ) {
        Swal.fire({
          title: "Error",
          text: "No hay turnos por asignar",
          icon: "info",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Ocurrio un error al asignar turno",
          icon: "error",
        });
      }
      resetFormId();
    }
  };

  const renderForm = () => (
    <form
      className="seeker-form"
      autoComplete="off"
      onSubmit={handleSubmitForm1(seekerSubmit)}
    >
      <div className="light-form-group input-seeker">
        <input
          id="id_employee"
          type="text"
          placeholder=" "
          className="formInput"
          {...registerFormId("id_employee", {
            required: true,
          })}
        />
        <label htmlFor="id_employee" className="formLabel">
          Id de empleado <span className="text-alert">*</span>
        </label>
      </div>

      <button type="submit">
        Asignar turno <FaChevronRight />
      </button>
    </form>
  );

  const updateAll = async () => {
    setSeekerArr([]);
    await getSeekers();
    await getTurns();
  }

  const renderInformationModal = () => {
    return (
      <Modal
        show={statusModal.status && statusModal.modal === "information"}
        onHide={closeModal}
        className="modal-container"
      >
        <Modal.Body>
          <div className="modal-icon">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M75 150C84.8491 150 94.6018 148.06 103.701 144.291C112.801 140.522 121.069 134.997 128.033 128.033C134.997 121.069 140.522 112.801 144.291 103.701C148.06 94.6018 150 84.8491 150 75C150 65.1509 148.06 55.3982 144.291 46.2987C140.522 37.1993 134.997 28.9314 128.033 21.967C121.069 15.0026 112.801 9.47814 103.701 5.70903C94.6018 1.93993 84.8491 -1.46764e-07 75 0C55.1088 2.96403e-07 36.0322 7.90176 21.967 21.967C7.90176 36.0322 0 55.1088 0 75C0 94.8912 7.90176 113.968 21.967 128.033C36.0322 142.098 55.1088 150 75 150ZM73.0667 105.333L114.733 55.3333L101.933 44.6667L66.1 87.6583L47.5583 69.1083L35.775 80.8917L60.775 105.892L67.225 112.342L73.0667 105.333Z"
                fill="#66C87B"
              />
            </svg>
          </div>
          <p className="modal-text-seek">
            El{" "}
            <span className="seek-color-blue">
              turno {currentSeeker.turn_number}
            </span>{" "}
            ha sido asignado correctamente al empleado{" "}
            <span className="seek-color-blue">
              {currentSeeker.employee_name}
            </span>
          </p>

          <div className="guide-container">
            <h3 className="seek-color-blue-bold">
              {currentSeeker.customer_name}
            </h3>
            {currentSeeker.awb &&
              currentSeeker.awb.map((guide, index) => (
                <p className="guide-number" key={index}>
                  {guide}
                </p>
              ))}
            <Button className="btn-confirm-seek" onClick={() => {closeModal(); updateAll()}}>
              Continuar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderJustificationInfoModal = () => {
    return (
      <Modal
        show={statusModal.status && statusModal.modal === "justification"}
        onHide={closeModal}
        className="modal-container"
      >
        <Modal.Body>
          <div className="modal-icon">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M75 0C116.423 0 150 33.5775 150 75C150 116.423 116.423 150 75 150C33.5775 150 0 116.423 0 75C0 33.5775 33.5775 0 75 0ZM75 105C73.0109 105 71.1032 105.79 69.6967 107.197C68.2902 108.603 67.5 110.511 67.5 112.5C67.5 114.489 68.2902 116.397 69.6967 117.803C71.1032 119.21 73.0109 120 75 120C76.9891 120 78.8968 119.21 80.3033 117.803C81.7098 116.397 82.5 114.489 82.5 112.5C82.5 110.511 81.7098 108.603 80.3033 107.197C78.8968 105.79 76.9891 105 75 105ZM75 33.75C67.7894 33.75 60.8742 36.6144 55.7755 41.713C50.6769 46.8117 47.8125 53.7269 47.8125 60.9375C47.8125 62.9266 48.6027 64.8343 50.0092 66.2408C51.4157 67.6473 53.3234 68.4375 55.3125 68.4375C57.3016 68.4375 59.2093 67.6473 60.6158 66.2408C62.0223 64.8343 62.8125 62.9266 62.8125 60.9375C62.815 58.7253 63.4195 56.5554 64.5614 54.6607C65.7032 52.7659 67.3392 51.2176 69.294 50.1819C71.2488 49.1462 73.4487 48.662 75.6577 48.7814C77.8667 48.9008 80.0015 49.6192 81.8333 50.8596C83.6651 52.0999 85.1248 53.8155 86.0557 55.8223C86.9867 57.8291 87.3539 60.0514 87.118 62.251C86.882 64.4506 86.0518 66.5445 84.7164 68.3082C83.381 70.0719 81.5907 71.4389 79.5375 72.2625C74.4675 74.2875 67.5 79.4775 67.5 88.125V90C67.5 91.9891 68.2902 93.8968 69.6967 95.3033C71.1032 96.7098 73.0109 97.5 75 97.5C76.9891 97.5 78.8968 96.7098 80.3033 95.3033C81.7098 93.8968 82.5 91.9891 82.5 90C82.5 88.17 82.875 87.255 84.4575 86.475L85.11 86.175C90.9661 83.8192 95.8203 79.4996 98.8405 73.9569C101.861 68.4141 102.859 61.9934 101.663 55.7954C100.468 49.5974 97.1541 44.0082 92.2893 39.986C87.4246 35.9638 81.3122 33.7592 75 33.75Z"
                fill="#035CF7"
              />
            </svg>
          </div>
          <h3 className="modal-text" style={{ color: "#222a59" }}>
            ¿Por qué no se ha encontrado/se ha encontrado parcialmente el turno?
          </h3>

          <div className="guide-container">
            <p className="guide-number">{currentSeeker.description}</p>
            <Button className="btn-confirm-seek" onClick={closeModal}>
              Cerrar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const renderSetJustificationInfoModal = () => {
    return (
      <Modal
        show={statusModal.status && statusModal.modal === "setJustification"}
        onHide={closeModal}
        className="modal-container"
      >
        <Modal.Body>
          <div className="modal-icon">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M75 0C116.423 0 150 33.5775 150 75C150 116.423 116.423 150 75 150C33.5775 150 0 116.423 0 75C0 33.5775 33.5775 0 75 0ZM75 105C73.0109 105 71.1032 105.79 69.6967 107.197C68.2902 108.603 67.5 110.511 67.5 112.5C67.5 114.489 68.2902 116.397 69.6967 117.803C71.1032 119.21 73.0109 120 75 120C76.9891 120 78.8968 119.21 80.3033 117.803C81.7098 116.397 82.5 114.489 82.5 112.5C82.5 110.511 81.7098 108.603 80.3033 107.197C78.8968 105.79 76.9891 105 75 105ZM75 33.75C67.7894 33.75 60.8742 36.6144 55.7755 41.713C50.6769 46.8117 47.8125 53.7269 47.8125 60.9375C47.8125 62.9266 48.6027 64.8343 50.0092 66.2408C51.4157 67.6473 53.3234 68.4375 55.3125 68.4375C57.3016 68.4375 59.2093 67.6473 60.6158 66.2408C62.0223 64.8343 62.8125 62.9266 62.8125 60.9375C62.815 58.7253 63.4195 56.5554 64.5614 54.6607C65.7032 52.7659 67.3392 51.2176 69.294 50.1819C71.2488 49.1462 73.4487 48.662 75.6577 48.7814C77.8667 48.9008 80.0015 49.6192 81.8333 50.8596C83.6651 52.0999 85.1248 53.8155 86.0557 55.8223C86.9867 57.8291 87.3539 60.0514 87.118 62.251C86.882 64.4506 86.0518 66.5445 84.7164 68.3082C83.381 70.0719 81.5907 71.4389 79.5375 72.2625C74.4675 74.2875 67.5 79.4775 67.5 88.125V90C67.5 91.9891 68.2902 93.8968 69.6967 95.3033C71.1032 96.7098 73.0109 97.5 75 97.5C76.9891 97.5 78.8968 96.7098 80.3033 95.3033C81.7098 93.8968 82.5 91.9891 82.5 90C82.5 88.17 82.875 87.255 84.4575 86.475L85.11 86.175C90.9661 83.8192 95.8203 79.4996 98.8405 73.9569C101.861 68.4141 102.859 61.9934 101.663 55.7954C100.468 49.5974 97.1541 44.0082 92.2893 39.986C87.4246 35.9638 81.3122 33.7592 75 33.75Z"
                fill="#035CF7"
              />
            </svg>
          </div>
          <h3 className="modal-text" style={{ color: "#222a59" }}>
            ¿Por qué no se ha encontrado/se ha encontrado parcialmente el turno?
          </h3>

          <div className="guide-container">
            <form
              className="setjustification-form"
              autoComplete="off"
              onSubmit={handleSubmitForm2(setJustificationForm)}
            >
              <div className="light-form-group">
                <textarea
                  id="justification"
                  placeholder=" "
                  className="formInput"
                  {...registerFormJustification("justification", {
                    required: true,
                  })}
                ></textarea>
                <label htmlFor="justification" className="formLabel">
                  Justificación <span className="text-alert">*</span>
                </label>
              </div>
              <Button className="btn-confirm-seek" type="submit">
                Guardar
              </Button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <div className="seeker-container">
        <div className="seeker-header">
          <img src={amc_logo} alt="Aeromexico" />
        </div>

        <div className="info-content">
          <p>{currentDate}</p>
          <p>{currentTime}</p>
          <div className="return-content">
            <FaAngleLeft />
          </div>
        </div>

        <div className="seeker-body">
          <h3>Asignación de turno</h3>

          <div className="turns-content">
            <div className="wait-turn">
              <FaClock />
              <p>Turnos en espera</p>
              <div className="turn-number">{turnsAwait}</div>
            </div>

            {renderForm()}
          </div>

          {seekerArr.length !== 0 ? (
            <table className="table-format">
              <thead>
                <tr>
                  <th>Turno</th>
                  <th>Nombre de empleado/# de empleado</th>
                  <th className="text-center">Estatus</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {seekerArr.map((seeker, index) => (
                  <tr key={index}>
                    <td className="borderStyle">{seeker.turn_number}</td>
                    <td className="borderStyle">
                      {seeker.employee_name} {seeker.turn} (
                      {seeker.employee_number})
                    </td>
                    <td className="borderLastStyle text-center">
                      <select
                        className={`select-seeker ${setBg(
                          "status",
                          seeker.status
                        )}`}
                        defaultValue={seeker.status}
                        onChange={(e) =>
                          updateStatus(seeker.turn_employee_id, e.target.value)
                        }
                      >
                        <option value={"1"}>Buscando</option>
                        <option value={"2"}>Encontrado</option>
                        <option value={"3"}>Encontrado parcial</option>
                        <option value={"4"}>No encontrado</option>
                      </select>
                    </td>
                    <td className="text-center actionTdSeek">
                      <div
                        className="box-container seek-bg-gray"
                        onClick={() => getDetails(seeker.turn_employee_id)}
                      >
                        <FaCircleExclamation />
                      </div>
                      {((seeker.status === "3" &&
                        seeker.description === false) ||
                        (seeker.status === "4" &&
                          seeker.description === false)) && (
                        <div
                          className={`box-container ${
                            seeker.description
                              ? "seek-bg-green"
                              : "seek-bg-yellow"
                          }`}
                          onClick={() =>
                            setJustificationInfo(seeker.turn_employee_id)
                          }
                        >
                          <FaRegCircleQuestion />
                        </div>
                      )}
                      {((seeker.status === "4" &&
                        seeker.description === true) ||
                        (seeker.status === "3" &&
                          seeker.description === true)) && (
                        <div
                          className="box-container seek-bg-green"
                          onClick={() =>
                            getJustificationInfo(seeker.turn_employee_id)
                          }
                        >
                          <FaRegCircleQuestion />
                        </div>
                      )}
                      {(seeker.status === "1" || seeker.status === "2") && (
                        <div className="box-container-none seek-bg-white">
                          {/* <FaRegCircleQuestion /> */}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="not-turn-register-text">
              <p>Sin turnos registrados</p>
            </div>
          )}
        </div>
      </div>

      {/* Information Modal */}
      {renderInformationModal()}

      {/* Justification Modal */}
      {renderJustificationInfoModal()}

      {/* Set Information Modal */}
      {renderSetJustificationInfoModal()}
    </>
  );
}

export default Seeker;
