import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { GoCheckCircleFill, GoXCircleFill } from "react-icons/go";
import { Modal, Button, Table, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { IoReload } from "react-icons/io5";

import Header from "../../components/header/Header";
import Swal from "sweetalert2";

const Card = ({ piezas, nombre, ubicacion, embarque, turno, handleShow }) => {
  return (
    <div className="turn-card" onClick={handleShow}>
      <h1>{ubicacion}</h1>
      <h3>{nombre}</h3>
      <p># Embarque</p>
      <p className="text-bold">{embarque}</p>
      <p>No. Piezas</p>
      <span className="text-bold">{piezas}</span>
    </div>
  );
};

const Weigher = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTurn, setSelectedTurn] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showCancel, setShowCancel] = useState({
    status_cancel: false,
    status_button: "",
  });
  const justificationRef = useRef(null);
  const [showErrorJustification, setShowErrorTextArea] = useState(false);
  const [reloadStatus, setReloadStatus] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/weighing`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );
      setData(res.data.data.shipments);
      res.data.data.shipments.length > 0
        ? setReloadStatus(true)
        : setReloadStatus(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/weighing/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );
      if (res.data.code === 1) {
        setSelectedTurn(res.data.data.shipment);
        reset();
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error fetching turn data", error);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedTurn(null);
    setShowCancel({ status_cancel: false, status_button: "" });
    setShowErrorTextArea(false);
    reset();
  };

  const handleConfirm = async (data) => {
    const body = {
      id: data.id,
      quantity_id: data.quantityID,
      weighing_description: data.weighing_description,
      user_id: localStorage.getItem("light-load-email"),
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/weighing/update`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        }
      );

      if (res.data.code === 1) {
        handleClose();
        getData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleJustificationClick = async () => {
    if (justificationRef.current.value === "") {
      setShowErrorTextArea(true);
      return;
    } else {
      try {
        setShowErrorTextArea(false);
        const url = `${process.env.REACT_APP_URL_DOMESTIC_BACKEND_LIGHT_LOAD}/weighing/cancel`;
        const body = {
          id: selectedTurn.id,
          description: justificationRef.current.value,
          user_id: localStorage.getItem("light-load-email"),
        };
        const response = await axios.put(url, body, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("light-load-token")}`,
          },
        });

        handleClose();
        await getData();
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Ocurrio un error al enviar la justificación",
          icon: "error",
        });
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let interval;
    if (!reloadStatus) {
      interval = setInterval(getData, 30000);
    }

    return () => clearInterval(interval);
  }, [reloadStatus]);

  return (
    <>
      <div className="amc-weigher-container">
        <Header />
        <div className="amc-weigher-content">
          <div className="amc-domestico-weigher-content-content">
            <div className="amc-domestico-weigher-content-content-title">
              <div className="weiguer-title">
                <h2>Pesaje</h2>
                {data.length > 0 && (
                  <button onClick={getData}>
                    <IoReload /> Actualizar
                  </button>
                )}
              </div>
            </div>
            <div className="amc-domestico-weigher-content-content-body">
              {loading ? (
                <div className="loading-spinner">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                </div>
              ) : (
                <div className="amc-domestico-weigher-content-content-body-turn">
                  {data.length > 0 ? (
                    data
                      .slice(0, 8)
                      .map((item, index) => (
                        <Card
                          key={index}
                          embarque={item.tag_number}
                          turno={item.turn_number}
                          piezas={item.pieces}
                          nombre={item.consignee}
                          ubicacion={item.destination}
                          handleShow={() => handleShow(item.id)}
                        />
                      ))
                  ) : (
                    <div className="no-found">No hay registros</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {selectedTurn && (
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          dialogClassName="custom-modal-width"
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: "#020c41",
              color: "#FFFFFF",
              backgroundImage: "none",
              textAlign: "center",
            }}
          >
            <Modal.Title>Información</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit(handleConfirm)}>
            <Modal.Body>
              <Table bordered style={{ textAlign: "center" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      # de turno
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.turn_number}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "5px",
                      }}
                    >
                      # de ticket
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.tag_number}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#00154F",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Quantity ID
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        style={{ border: "none" }}
                        {...register("quantityID", {
                          required: "Debes poner un Quantity ID",
                          pattern: {
                            value: /^\d{8,12}$/,
                            message:
                              "El Quantity ID debe tener entre 8 y 12 dígitos y no puede contener letras ni caracteres especiales",
                          },
                        })}
                      />
                      <Form.Control
                        type="hidden"
                        style={{ border: "none" }}
                        value={selectedTurn.id}
                        {...register("id", {})}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Nombre consignatario
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.name}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      No. de piezas
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.pieces}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Destino
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.destination}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Fecha
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.date.split(" ")[0]}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#a3a3a3",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Hora
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        type="text"
                        defaultValue={selectedTurn.date.split(" ")[1]}
                        readOnly
                        style={{ border: "none" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#00154F",
                        color: "#FFFFFF",
                        padding: "20px",
                      }}
                    >
                      Comentarios Pesaje
                    </td>
                    <td colSpan="3">
                      <Form.Control
                        as="textarea"
                        name="weighing_description"
                        id="weighing_description"
                        rows={3}
                        style={{ border: "none" }}
                        {...register("weighing_description", {})}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              {errors.quantityID && (
                <span
                  style={{
                    color: "red",
                    fontSize: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {errors.quantityID.message}
                </span>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div
                className={`content-btns-input ${
                  showCancel.status_cancel ? "active" : ""
                }`}
              >
                <div className="btns-decision">
                  <button
                    type="button"
                    className="cancel-btn"
                    onClick={() =>
                      setShowCancel({
                        status_cancel: true,
                        status_button: "cancel",
                      })
                    }
                  >
                    Cancelar <GoXCircleFill />
                  </button>
                  <button
                    type="button"
                    className="acept-btn"
                    onClick={() =>
                      setShowCancel({
                        status_cancel: false,
                        status_button: "accept",
                      })
                    }
                  >
                    Aceptar <GoCheckCircleFill />
                  </button>
                </div>

                <p className="cancel-text">
                  ¿por que deseas cancelar?{" "}
                  <span className="text-alert">*</span>
                </p>
                <textarea
                  ref={justificationRef}
                  placeholder="Escribe tu justificación"
                ></textarea>
                {showErrorJustification && (
                  <p
                    className="text-alert"
                    style={{
                      fontSize: ".8rem",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Por favor ingresa una justificacion
                  </p>
                )}
              </div>
              {(showCancel.status_button === "" ||
                showCancel.status_button === "accept") && (
                <Button
                  type="submit"
                  disabled={
                    showCancel.status_button === "accept" ? false : true
                  }
                  style={{
                    backgroundColor: "#035cf7",
                    border: "none",
                    width: "100%",
                    height: "50px",
                    fontWeight: "bold",
                  }}
                >
                  Confirmar
                </Button>
              )}
              {showCancel.status_button === "cancel" && (
                <Button
                  type="button"
                  disabled={
                    showCancel.status_button === "cancel" ? false : true
                  }
                  style={{
                    backgroundColor: "#035cf7",
                    border: "none",
                    width: "100%",
                    height: "50px",
                    fontWeight: "bold",
                  }}
                  onClick={handleJustificationClick}
                >
                  Confirmar
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default Weigher;
